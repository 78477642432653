<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("My_clients")}}</h5>
    </div>
    <div v-if="loading" style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
      <div class="spinner-border" :style="skin.loader" role="status">
        <span class="visually-hidden">{{translate("loading")}}</span>
      </div>
    </div>
    <div v-if="clients.length>0" class="my-2 table-responsive">
      <table class="table" :style="skin.my_account.close_btn">
        <thead>
          <tr :style="skin.my_account.main_menu_background">
            <th scope="col">ID</th>
            <th scope="col">{{translate("name")}}</th>
            <th scope="col">{{translate("current_balance")}}</th>
            <th scope="col">{{translate("balance_gain")}}</th>
            <th scope="col">{{translate("last_entry")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in clients" :key="client.id" :style="skin.my_account.header"  @click="show_user_records(client)" style="cursor:pointer;">
            <td>{{ client.id }}</td>
            <td>{{ client.nombre }}</td>
            <td>{{ client.capital.toLocaleString('de-DE', {maximumFractionDigits:2}) }}&nbsp;{{ user.user_data.data.currency }}</td>
            <td>{{ client.disponible.toLocaleString('de-DE', {maximumFractionDigits:2}) }}&nbsp;{{ user.user_data.data.currency }}</td>
            <td>{{ format_date(client.lastlogin) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:"my_clients",
    props:["skin","language","translation"],
    data(){
      return{
        loading:false,
        clients:[]
      }
    },
    methods:{
      async loadClients(){
        this.loading = true
        let data = {
          "command":"clientes",
          "params":{}
          }

        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.clients = response.data.clientes
        }
        this.loading = false;
      },
      format_date: function (ts) {
        let date = new Date(ts * 1000);
        var formated =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          date.getHours() +
          ":" +
          ("0" + date.getMinutes()).slice(-2) +
          ":" +
          ("0" + date.getSeconds()).slice(-2);
        return formated;
      },
      show_user_records(data){
        data.nav_bar=false;
        this.$root.$emit('show_user_record',data);
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    },
    created(){
      this.loadClients();
    }
}
</script>

<style>

</style>