<template>
<div v-if="events.length>0">
    <div class="p-2" :style="skin.prematch_lobby.bar">{{translate("highlights")}}</div>
    <div v-if="menu_visible" :style="skin.prematch_lobby_main.bar">
       <ul class="nav p-1" :style="nav_active">
            <li v-for="deporte in deportes.main"
                v-bind:key="deporte.id"
                :id="deporte.id+'external'"
                style="cursor:pointer"
                class="nav-item nav-external" :class="deporte.id == selected_sport ? 'nav_active': ''"
                @click="select_sport(deporte.id)">
                <span class="m-2">{{ deporte.name }}</span>
            </li>
        </ul> 
    </div>
    <div>
        <div v-if="screen_width>skin.break_point">
            <div v-if="selected_sport==1">
                <div class="px-3">
                    <Futbol
                        :Skin="skin"
                        :translation="translation"
                        :language="language"
                    ></Futbol>
                </div>
            </div>
            <div v-else>
                <div class="px-3">
                    <Cricket
                        :Skin="skin"
                        :translation="translation"
                        :language="language"
                    ></Cricket>
                </div>
            </div>
        </div>
        <encuentro v-for="game in events"
          v-bind:game="game"
          v-bind:sport="selected_sport"
          v-bind:Skin="skin"
          v-bind:key="game.id"
          v-bind:language="language"
          v-bind:translation="translation"
        />
    </div>
</div>
</template>

<script>
import encuentro from '../main_events_prematch2/Encuentro.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
export default {
    name:"main_external",
    props:['skin','translation','language','menu_visible'],
    components:{
        encuentro,
        Futbol,
        Cricket,
        Rugby,
        Tenis,
        Balonmano
    },
    data(){
        return{
            selected_sport:0,
            screen_width:0,
            events_limit:12,
        }
    },
    computed:{
        deportes(){
            var list={
                main:[],
                other:[]
            };
            let data = this.$store.getters['getExternaEvents'];
                Object.keys(data).forEach(sport=>{
                    list.main.push({
                        name:data[sport].name,
                        id:data[sport].id
                    })
                });
            return list;
        },
        events(){
            let events = [];
            let ligas = [];
            let count = 0;
            let now = Math.floor(Date.now()/1000);
            let data = this.$store.getters['getExternaEvents'];
            if(!this.selected_sport){
                this.selected_sport = parseInt(Object.keys(data)[0]);
            }
            Object.keys(data).forEach(sport=>{
                if(data[sport].id == this.selected_sport){
                    Object.keys(data[sport].region).forEach(pais=>{
                    if(data[sport].region[pais]){
                        if(data[sport].region[pais].competition){
                            Object.keys(data[sport].region[pais].competition).forEach(liga=>{
                                data[sport].region[pais].competition[liga].sport = data[sport].id;
                                data[sport].region[pais].competition[liga].sport_alias = data[sport].name;
                                data[sport].region[pais].competition[liga].country = data[sport].region[pais].name;
                                data[sport].region[pais].competition[liga].country_alias = data[sport].region[pais].alias;
                                data[sport].region[pais].competition[liga].country_id = data[sport].region[pais].id;
                                ligas.push(data[sport].region[pais].competition[liga]);
                            });
                        }
                    }
                });
                }
                
            }); 
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                if((liga ? (liga.game ? true : false) : false)){
                    Object.keys(liga.game).forEach(element=>{
                        if(liga.game[element]){
                            if(liga.game[element] && liga.game[element].info && !liga.game[element].favorite && (liga.game[element].start_ts - now > 0)){
                                if(liga.game[element].market){
                                    liga.game[element]['sport'] = this.$props.deporte;
                                    if(count<this.events_limit && Object.keys(liga.game[element].market).length>0 && !liga.game[element].is_blocked){
                                        liga.game[element].liga = liga.name;
                                        liga.game[element].liga_id = liga.id;
                                        liga.game[element].sport = liga.sport;
                                        liga.game[element].country = liga.country;
                                        liga.game[element].country_id = liga.country_id;
                                        liga.game[element].country_alias = liga.country_alias;
                                        liga.game[element].sport_alias = liga.sport_alias;
                                        events.push(liga.game[element]);
                                        count++;
                                    }
                                }
                            }  
                        } 
                    });
                }
            });
            return events; 
        },
        nav_active(){
            return {
                '--nav-active-color':this.$props.skin.prematch_lobby_main.nav_active_color,
                '--nav-active-bar':this.$props.skin.prematch_lobby_main.nav_active_bar
            };
        },
    },
    methods:{
        select_sport(id){
            let items = document.getElementsByClassName("nav-external");
            [...items].forEach(item=>{
                item.classList.remove('nav_active');
            });
            if(document.getElementById(id+'external')){
                 document.getElementById(id+'external').classList.add('nav_active');
            }
            this.selected_sport = id;
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
        more_events(){
            this.events_limit *= this.events_limit * 2 > 100 ? 1 : 2;
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    },
    mounted() {
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
    created(){
        this.sizeControl();
    }
}
</script>

<style scoped>
  .nav_active{
      color:var(--nav-active-color);
      border-bottom: var(--nav-active-bar);
  }
</style>