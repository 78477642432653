<template>
    <div>
        <div class="p-2 mb-2" :style="skin.my_account.menu_item_border">
            <h5>{{translate("bonus")}}</h5>
        </div>
        <div v-if="loading" style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
            <div class="spinner-border" :style="skin.loader" role="status">
                <span class="visually-hidden">{{translate("loading")}}</span>
            </div>
        </div>
        <div v-for="(bono, index) in bonus" :key="index" class="card text-dark bg-light mb-3 shadow shadow-sm" style="">
          <div class="accordion" :id="'bono-'+index">
            <div class="accordion-item">
              <h2 class="accordion-header" :id="'heading'+index">
                <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+index" aria-expanded="false" :aria-controls="'collapseOne'+index">
                  {{ translate(bonus_type(bono.tipo)) }} ( {{ translate('total_value') }} {{ bono.base }} {{ bono.moneda }} ) - {{ translate(bonus_status(bono.estado)) }}
                </div>
              </h2>
              <div :id="'collapseOne'+index" class="accordion-collapse collapse p-2 py-3" :aria-labelledby="'heading'+index" :data-bs-parent="'#bono-'+index">
                <div class="row">
                    <div class="col-lg-9 col-sm-12" style="font-size: small;">
                        <div v-if="bono.estado==3">
                            <span>{{ translate('staked') }} {{ bono.valor_actual }} {{ bono.moneda }}</span>
                            <span class="float-end">{{ translate('remaining') }} {{ bono.tope - bono.valor_actual }} {{ bono.moneda }}</span>
                            <div class="progress">
                                <div class="progress-bar bg-success" role="progressbar" :style="'width:'+bono.porcentaje_actual+'%'" :aria-valuenow="bono.porcentaje_actual" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="min-width: 70px;">{{ translate('bonus_id') }}</th>
                                        <th scope="col" style="min-width: 70px;">{{ translate('time_to_bet') }}</th>
                                        <th scope="col" style="min-width: 70px;">{{ translate('bonus_bet_factor') }}</th>
                                        <th scope="col" style="min-width: 70px;">{{ translate('bonus_status') }}</th>
                                        <th scope="col" style="min-width: 300px;">{{ translate('bonus_description') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{{ bono.unico }}</td>
                                        <td scope="col">{{ format_date(bono.fecha_fin) }}</td>
                                        <td scope="col">{{ 'x'+bono.rollovers }}</td>
                                        <td scope="col">{{ translate(bonus_status(bono.estado)) }}</td>
                                        <td scope="col">{{ bono.descripcion }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-12" style="font-size: small;">
                        <span>{{ translate('bonus_start_date') }}</span><br>
                        <span style="color:blue;">{{ bono.fecha_inicio?.replace("T"," ") }}</span><br>
                        <span>{{ translate('bonus_activated_date') }}</span><br>
                        <span style="color:green;">{{ bono.fecha_activacion?.replace("T"," ") }}</span><br>
                        <span>{{ translate('bonus_finish_date') }}</span><br>
                        <span style="color:red;">{{ bono.fecha_fin?.replace("T"," ") }}</span>
                    </div>
                    <div class="col-lg-1 col-sm-12" style="font-size: small;">
                      <button v-if="bono.estado==1" @click="activate_bonus(bono.id)" class="btn btn-success">Ativar</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
      name:"bonus",
      props:["skin","language","translation"],
      data(){
        return{
          loading:false,
          bonus:[]
        }
      },
      methods:{
        async activate_bonus(id){
          let data = {
            "command":"acept_bonus",
            "params":{"id":id}
          }
          const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
          if(response.data.status == 'success'){
            this.loadBonus();
          }
          else{
            let modal = document.getElementById('bookingModalBtn');
            modal.click();
            this.$root.$emit('newAlert',{
              label: 'Error',
              message: response.data.message,
              barCode: ''
            });
          }
        },
        bonus_type(type){
            let bonus = {
                "1":"register_bonus",
                "2":"recharge_bonus"
            }
            return bonus[type];
        },
        bonus_status(status){
            let states = {
                "1":"bonus_pending_activation",
                "2":"pending_bonus",
                "3":"bonus_activated",
                "4":"bonus_finished",
                "0":"bonus_cancelled",
            }
            return states[status];
        },
        async loadBonus(){
          this.loading = true
          let data = {
            "command":"get_bonus",
            "params":{}
            }
  
          const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
          if(response.data.status == 'success'){
            this.bonus = response.data.data
          }
          this.loading = false;
        },
        format_date: function (date) {
            const date1 = new Date(date);
            const date2 = new Date();
            const diffTimeMiliseconds =Math.abs(date1 - date2);
            const diffTimeMinutes =Math.ceil(diffTimeMiliseconds/60000);
            const diffTimeHours =Math.ceil(diffTimeMinutes/60);
            const diffDays = Math.ceil(diffTimeHours / 24);
            if(diffDays<1){
                if(diffTimeHours<1){
                    return diffTimeMinutes +" "+this.translate('minutes')
                }
                return diffTimeHours +" "+this.translate('hours')
            }
            return diffDays +" "+this.translate('days')
        },
        translate(text) {
          if(text){
            if (this.$props.translation[text]) {
              return this.$props.translation[text][this.$props.language];
            }
            else{
              var new_text_serched = text.replace(/ /g, "_");
              if (this.$props.translation[new_text_serched]) {
                return this.$props.translation[new_text_serched][this.$props.language];
              }
            }
            return text;
          }
        },
      },
      computed:{
        user(){
          return this.$store.getters['getUserData'];
        },
      },
      created(){
        this.loadBonus();
      }
  }
  </script>
  
  <style>
  
  </style>