<template>
    <div class="m-1 mb-2" :style="skin.main_leagues.body" style="overflow:hidden">
        <div class="p-2" :style="skin.main_leagues.header">{{translate("main_leagues")}}</div>
        <div
            v-for="liga in ligas"
            v-bind:key="liga.id"
            class="p-2"
            style="cursor:pointer"
            @click="select_liga(liga)"
        >
            <span class="rounded-circle me-2" :class="'phoca-flag ' + liga.country_code" style="height:20px;width:20px"></span>
            <span>{{ liga.name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name:"main_ligas",
    props:["skin","translation","language"],
    data(){
        return{
            ligas_id:[746,1863,897,773,829,15,199,163,1217,133,125,1
            ]
        }
    },
    methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        select_liga(data){
            let ligas_array = this.$store.getters['prematch/getLigasArray2'];
            this.$store.dispatch('setLobbyView','prematch_ligas');
            this.$root.$emit('show_prematch_ligas',1);
            let datos = {
            pais:{name_es:data.country_name_es,name_en:data.country_name_en},
            liga:data,
            deporte:{name_es:"Fútbol",name_en:"Soccer",id:1},
            pais_code:data.country_code
            };
            let payload = {"type":2,"datos":datos}
            let check = true;
            Object.keys(ligas_array).forEach(liga=>{
                if(liga.liga.id == data.id){
                    check = false;
                }
            })
            if(check){
                this.$store.dispatch('prematch/add_liga', payload);
            }
        } 
    },
    computed:{
        ligas(){
            var list=[];
            let data = this.$store.getters['prematch/getPrematchData'][0];
            if(data){
                Object.keys(data.countries).forEach(country => {
                    var check = false;
                    if (data.countries[country].leagues.length > 0) {
                        check = true; 
                    }
                    if(check){
                        Object.keys(data.countries[country].leagues).forEach(liga=>{
                            if(this.ligas_id.includes(data.countries[country].leagues[liga].id)){
                                data.countries[country].leagues[liga].country_code = data.countries[country].code;
                                data.countries[country].leagues[liga].country_name_es = data.countries[country].name_es;
                                data.countries[country].leagues[liga].country_name_en = data.countries[country].name_en;
                                list.push(data.countries[country].leagues[liga]);
                            }
                        })
                    }
                });
            }
            return list;
        },
    }
}
</script>

<style>

</style>