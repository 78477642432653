<template>
  <th class="py-2" :style="active_odd">
    <div class="py-2 pb-2 px-0 pe-0 text-center"
        :style="Skin.prematch_preview.odd"
        :class="(active_state?' hover':'')"
        :id="logro.id+'button'"
        style="cursor:pointer;"
        @mouseover="active_state?'':(logro.id ? hover(logro.id+'button'):'')"
        @mouseleave="active_state?'':(logro.id ? hover2(logro.id+'button'):'')"
    >
        <span 
          v-if="rate.toFixed(2) != 1.00 || !rate">
          {{ rate_conversion(rate) }}
        </span>
        <span v-else style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
    </div>
  </th>
</template>

<script>
export default {
  name: 'odd',
  props:["Skin","rate","logro","active_state"],
  data(){
    return {
  
    }
  },
  computed:{
    active_odd(){
      return {
        "--background":this.$props.Skin.prematch_preview.odd_hover_fondo,
        "--color":this.$props.Skin.prematch_preview.odd_hover_color,
      }
    },
    rate_type(){
      return this.$store.getters['getRateType'];
    }
  },
  methods:{
    hover(id){
            document.getElementById(id).classList.add('hover');
        },
    hover2(id){
        document.getElementById(id).classList.remove('hover');
    },
    rate_conversion(rate){
      if(this.rate_type == 'americano'){
        let value = parseFloat(rate);
        if(value >= 2){
            let result = (value-1)*100;
            return '+'+(Math.round(result));
        }
        else{
            let result = (-100)/(value-1);
            return Math.round(result);
        }
      }
      return rate.toFixed(2)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hover {
      background-color: var(--background) !important;
      color:var(--color) !important;
  }
</style>