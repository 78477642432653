<template>
  <div :style="skin2.my_account.mi_componente" >
    
    <!-- Barra de navegación con filtros -->
    <nav class="navbar navbar-dark bg-dark">
      <div class="container-fluid">
        <form @submit.prevent="getGames" class="row gx-3 align-items-center mt-2 mb-2">
          <div class="col-auto">
            <label class="visually-hidden" for="deporteSelect">Deporte</label>
            <select class="form-select" id="deporteSelect" v-model="seleccionDeporte" @change="filtrarRegiones">
              <option selected>Deporte</option>
              <option v-for="(deporte, idx) in sports_prematch" :key="deporte.id"
                :value="{ id: deporte.id, nombre: deporte.alias }"
                :class="{ 'opcion-seleccionada': deporte.alias === seleccionDeporte }">
                {{ deporte.alias }}
              </option>
            </select>
          </div>
    
          <div class="col-auto">
            <label class="visually-hidden" for="regionSelect">Región</label>
            <select v-if="Object.keys(regionesFiltradas).length > 0" class="form-select" v-model="seleccionCompetencia" @change="Competicion">
              <option selected disabled>Seleccione una región</option>
              <option v-for="(item, idx) in datosTransformados" :key="idx" :value="item.label">
                {{ item.label }}
              </option>
            </select>
          </div>
    
          <div class="col-auto">
            <label class="visually-hidden" for="startInput">Start</label>
            <div class="input-group">
              <span class="input-group-text">Start</span>
              <input id="startInput" type="date" class="form-control" v-model="fechaInicial" :min="minDate" />
            </div>
          </div>
    
          <div class="col-auto">
            <label class="visually-hidden" for="endInput">End</label>
            <div class="input-group">
              <span class="input-group-text">End</span>
              <input id="endInput" type="date" class="form-control" v-model="fechaFinal" :min="minDate" :max="maxDate" />
            </div>
          </div>
    
          <div class="col-auto">
            <button type="submit" class="btn btn-outline-light ml-1">Submit</button>
          </div>
        </form>
      </div>
    </nav>

    <!-- Acordeón para mostrar detalles de los juegos -->
    <div class="accordion accordion-flush bg-dark" id="accordionFlushExample" style="overflow-y: auto; max-height: 600px; background-color: #333333;">
      <div v-for="(game, index) in sortedGames" :key="game.game_id" class="accordion-item" style="background-color: #333333;">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#flush-collapse' + index"
            aria-expanded="false"
            :aria-controls="'flush-collapse' + index"
            @click="expandirAcordeon(index, game.game_id)"
            style="background-color: #000000;color:azure; border"
          >
            {{ game.teams }}
          </button>
        </h2>
        <div :id="'flush-collapse' + index" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" >
          <div class="accordion-body">
            <span style="color:rgb(255, 253, 253)">Ver Estadísticas del Game: </span>
            <span style="cursor:pointer;color:blue;text-decoration: underline;" @click="show_game_data(game.game_id)" data-bs-toggle="modal" data-bs-target="#gameDataModal">{{ game.game_id }}</span><br><br>
            <div v-if="loading && selectedGameId === game.game_id">Cargando...</div>
            <div v-else style="overflow-y: auto; max-height: 300px;">
              <table class="table table-bordered table-striped table-dark">
                <thead class="table-light">
                  <tr>
                    <th>Market</th>
                    <th>Selection</th>
                    <th>Cuota</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in AllOds" :key="doc._id">
                    <td>{{ doc.market_name }}</td>
                    <td>
                      {{ doc.selection.name }}
                      <span v-if="doc.selection.base">&nbsp;-&nbsp;{{ doc.selection.base }}</span>
                    </td>
                    <td>{{ doc.selection.price }}</td>
                    <td :class="getStatusClass(doc.status)">{{ getStatusLabel(doc.status) }}</td>
                  </tr>
                </tbody>
              </table>              
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <!-- Modal game Data -->
    <div class="modal fade" id="gameDataModal" tabindex="-1" aria-labelledby="gamDataModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg custom-modal">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-4" id="gameDataModalLabel" style="color:black">Datos del juego</h5>
            <button class="btn btn-sm btn-primary mx-2 mb-1 me-2" @click="changeSource('flashscore')" >FlashScore</button>
            <button class="btn btn-sm btn-primary mb-1 me-2" @click="changeSource('betconstruct')">Betconstruct</button>
            <button v-if="target_game_data.url && !target_game_data.loading && game_source=='flashscore'" class="btn btn-sm btn-primary mb-1" @click="openFlashscoreOnTab(target_game_data.url)">Abrir en otra ventana</button>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div id="gameData-modal" class="modal-body">
            <div v-if="target_game_data.url && !target_game_data.loading && game_source=='flashscore'" style="width: 100%;height:90vh;" class="text-center ">
                <iframe id="flashscore_stats" :src="target_game_data.url" frameborder="0" style="width: 100%; height: 100%; position: absolute; top: 1px; left: 1px;" class="mr-2"></iframe>
            </div>
            <div v-else>  
              <div v-if="target_game_data.data.info && !target_game_data.loading && game_source=='betconstruct' ? ('error' in target_game_data.data ? false : true) : false">
                <div class="d-flex p-2" style="background-color:grey">
                    <div v-if="target_game_data.data.info" class="d-flex" style="width:50%">
                        <shirt v-bind:color="'#'+(target_game_data.data.info.shirt1_color == '000000' ? 'ccc' : target_game_data.data.info.shirt1_color)"/>
                        <span class="mx-1" style="color:white">{{ target_game_data.data.team1_name }}</span>
                    </div>
                    <div v-if="target_game_data.data.info" style="width:50%">
                        <span class="float-end"><shirt v-bind:color="'#'+(target_game_data.data.info.shirt2_color == '000000' ? 'f00' : target_game_data.data.info.shirt2_color)"/></span>
                        <span class="float-end me-1" style="color:white">{{ target_game_data.data.team2_name }}</span>
                    </div>
                </div>
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">Marcador</th>
                      <td>{{ target_game_data.data.info.score1 || '0' }} - {{ target_game_data.data.info.score2 || '0' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fecha/Hora de Inicio</th>
                      <td>{{ formatDate(target_game_data.data.start_ts*1000) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Local</th>
                      <td>{{ target_game_data.data.team1_name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Visitante</th>
                      <td>{{ target_game_data.data.team2_name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Tiempo de juego</th>
                      <td>{{ target_game_data.data.info.current_game_state || '' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Minutos de juego</th>
                      <td>{{ target_game_data.data.info.current_game_time || '0' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Minutos adicionales</th>
                      <td>{{ target_game_data.data.info.match_add_minutes || '0' }}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <thead class="bg-dark" style="color:white">
                    <th></th>
                    <th style="font-weight:bold">{{ target_game_data.data.team1_name }}</th>
                    <th style="font-weight:bold">{{ target_game_data.data.team2_name }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="(stat,idx) in target_game_data.data.stats" :key="idx">
                      <th scope="row">{{ idx }}</th>
                      <td>{{ stat.team1_value }}</td>
                      <td>{{ stat.team2_value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else-if="!target_game_data.loading" class="text-center">
                <span>No hay datos de este evento o aun no ha empezado.</span>
              </div>
              <div v-else class="text-center">
                <div class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import shirt from '../../prematch2/shirt.vue';
export default {
  name:"main_results",
  props:['skin','language','translation'],
  components:{
        shirt
  },
  data() {
    const today = new Date().toISOString().split('T')[0];  // Obtener la fecha actual
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 15);  // Restar 15 días a la fecha actual
    const maxDate = new Date();
    return {
      // sortedGames: [], // Lista ordenada de juegos
      filtroNombre: '', // Buscador por texto
      today,
      minDate: minDate.toISOString().split('T')[0],
      maxDate: maxDate.toISOString().split('T')[0],
      fechaInicial: today,
      fechaFinal: minDate,
      // Datos de ejemplo (pueden provenir de una API, Vuex, etc.)
      items: [
        { id: 1, nombre: 'Elemento 1' },
        { id: 2, nombre: 'Elemento 2' },
        // Agrega más elementos según tus necesidades
      ],
      target_game_data:{data:{},loading:false},
      seleccionDeporte: {
        id: null,
        name: null
      },
      game_source:"fashscore",
      regionesFiltradas: {},
      seleccionCompetencia: {},
      competenciasFiltradas: {},
      filters: { sport: '', league: '',startDate:'',endDate:'' },
      collapsedGames: {},
      collapsedRows: [],
      selectedGameDetails: null,
      oddsFiltered: [],
      datosTransformados: [],
      docs: [],
      game_ids : [],
      selectedGameId: null, // ID del juego seleccionado
      loading: false, // Estado de carga
      Odds:{},
      skin2: {
        "my_account": {
          "header": "background:#1b1b1b;", 
          "close_btn": "color:white;",
          "header_nav": "background:#1b1b1b;",
          "header_nav_active": "background:#2D49A0;color:white;",
          "header_nav_text2": "color:#ffd029;",
          "modal_background": "background:black;",
          "main_background": "background:#5a5956;border-radius: 5px 5px 5px 5px;",
          "content_background": "background:#1b1b1b;",
          "nav_active_color": "#BB0510",
          "nav_active_bar": "solid 3px #ffd029",
          "main_menu_background": "background:#ffd029;color:black;",
          "menu_item_border": "border-bottom:2px solid #1b1b1b;",
          "modal_myclients": "black",
          "register_client": true,
          "my_clients": true,
          "recharge": true,
          "user_recharge": {
            "enabled": false,
            "payment_gateways": [],
            "extras": {
              "cupon": false
            }
          },
          "request_payment": true,
          "request_payment_target": {
            "enabled": false,
            "types": [
              "Bancolombia",
              "Nequi",
              "Daviplata"
            ]
          },
          'mi_componente': "background-color: #333;color: #fffff;padding: 20px;ml-5;mr-5;mt-2;style=width: 100% heigh=100%;",
          "table_results_text": "color: white;",
          "withdraw": true,
          "movements": true,
          "reports": true,
          "my_records": true
        }
      },
      items: [
        { name: 'Elemento 1', showDetails: false },
        { name: 'Elemento 2', showDetails: false },
        // Agrega más elementos según sea necesario
      ]
    };
  },
  computed: {
    // filteredDocs() {
    //   // Utiliza los filtros para filtrar los documentos
    //   return this.docs.filter(doc => {
    //     // Verifica si el filtro de deporte está vacío o coincide con el deporte del documento
    //     const sportFilterPass = this.filters.sport === '' || doc.sport === this.filters.sport;

    //     // Verifica si el filtro de competición está vacío o coincide con la competición del documento
    //     const competitionFilterPass = this.filters.competition === '' || doc.league === this.filters.competition;

    //     // Retorna true solo si ambos filtros pasan
    //     return sportFilterPass && competitionFilterPass;
    //   });
    // },
    // Filtrar items basados en los filtros aplicados
    sports_prematch() {
      return this.$store.getters['prematch_2/getSports'];
    },
    sortedGames() {
      return this.Games.slice().sort((a, b) => b.end_time - a.end_time);
    },
    filteredItems() {
      return this.items.filter(item => {
        return (
          item.nombre.toLowerCase().includes(this.filtroNombre.toLowerCase()) &&
          this.cumpleFiltroFecha(item)
          // Agrega más lógica de filtrado según tus necesidades
        );
      });
    },
    AllOds() {
      console.log( this.$store.getters['getAllOdds'])
      return this.$store.getters['getAllOdds']
    },
    oddsLoaded() {
      return this.$store.getters['getOddsLoaded']
    },
    Games() {
      return this.$store.state.games_loaded ? this.$store.state.games : []; 
   }
  },
  methods: {
    translate(text){
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    transform_data(datosOriginales) {
      Object.keys(datosOriginales).forEach((regionId) => {
        const region = datosOriginales[regionId];
        if(region.competition){
            Object.keys(region.competition).forEach((compId) => {
            const comp = region.competition[compId];
            this.datosTransformados.push({
              id: this.datosTransformados.length,
              label: `${region.alias} - ${comp.name}`,
            });
          });
        }
        
      });
    },
    async expandirAcordeon(index, gameId) {
      // Establecer el estado de carga y el juego seleccionado
      this.loading = true;
      this.selectedGameId = gameId;
      await this.$store.dispatch("getAllOdds", gameId);
      try {
        // Hacer la petición con dispatch de manera asíncrona
        this.Odds = this.AllOds
        this.Odds = this.filteredDocs(gameId);
        ////console.log('Odds obtenidas:', this.Odds);
      } catch (error) {
        console.error('Error al obtener las odds:', error);
        // Manejar el error según tus necesidades
      } finally {
        // Establecer el estado de carga como falso
        this.loading = false;
      }
    },
    filteredDocs(gameId) {
  // Filtra los documentos con status diferente de 0 para el juego seleccionado
      return this.Odds.filter((doc) => {
        const isValidGame = doc.game_id === gameId && doc.status !== 0;

        if (isValidGame) {  
          // Convierte las fechas en formato ISO a objetos Date
          const startDate = new Date(this.fechaInicial);
          const endDate = new Date(this.fechaFinal);

          // Convierte las fechas de los documentos en objetos Date
          const docStartDate = new Date(doc.start_time);
          const docEndDate = new Date(doc.end_time);
          // ////console.log(docStartDate,docEndDate)

          // Compara las fechas
          return docStartDate >= startDate && docEndDate <= endDate;
        }

        return false;
      });
    },
     openFlashscoreOnTab(url){
      window.open(url, '_blank').focus();
    },
    changeSource(source){
      this.game_source = source;
    },
    formatDate(unix_timestamp){
      var date = new Date(unix_timestamp);
      var formated =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          date.getHours() +
          ":" +
          ("0" + date.getMinutes()).slice(-2) +
          ":" +
          ("0" + date.getSeconds()).slice(-2);
      return formated;
    }, 
    async  show_game_data(game_id){
      this.target_game_data['data'] = {};
      this.target_game_data['url']="";
      this.target_game_data['loading'] = true;
      let target = null;
      //llamar a flashscore
      try {
        let bc_data = await axios.get("https://jbets.online:3013/api/get_bc_games?page=1&game_id="+game_id);
        if(bc_data.data.docs.length>0?(bc_data.data.docs[0].flashscore_id!=""?true:false):false){
          this.target_game_data['url'] = `https://www.flashscore.co/partido/${bc_data.data.docs[0].flashscore_id}/#/resumen-del-partido/resumen-del-partido`;
          this.target_game_data['loading'] = false;
          target = "flashscore";
        }
        // let userData = store.getters["getUserData"];
        let config = {
            Authorization:
              'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmUyZTFjYmFhOWMxMjc5ZjRkODZlYWUiLCJuYW1lIjoiQWxlamFuZHJvIiwiZW1haWwiOiJscGVkcmVyb3NAdW5hbC5lZHUuY28iLCJyb2xlIjoidXNlciIsImlhdCI6MTY5OTU3MTgzMX0.ajmUubpcwEJiSsC3N6ImRM_nAlydhKZ_hG60UDdPJek',
          };
        const response = await axios.get(
          "https://jbets.online/api/get_event/game_id/"+game_id,
          config
        );
        this.target_game_data['data'] = response.data;
        this.target_game_data['loading'] = false;
        this.game_source = target ?? "betconstruct";
      } catch (error) {
        ////console.log(error);
      }
    },

    getStatusLabel(status) {
      // Función para obtener etiquetas de estado según el valor de status
      switch (status) {
        case 1:
          return "Ganado";
        case 2:
          return "Perdido";
        case 3:
          return "Devuelto";
        default:
          return "Desconocido";
      }
    },
    getStatusClass(status) {
      // Función para obtener clases de estilo según el valor de status
      switch (status) {
        case 1:
          return "status-won";
        case 2:
          return "status-lost";
        case 3:
          return "status-returned";
        default:
          return "";
      }
    },


    Competicion() {
      ////console.log(this.seleccionDeporte, this.seleccionCompetencia)
      this.filters.sport = this.seleccionDeporte.nombre
      this.filters.league = this.seleccionCompetencia.split(" - ")[1];
      ////console.log(this.seleccionDeporte, this.seleccionCompetencia)
      ////console.log(this.filters)
    },

    filtrarRegiones() {
      this.filters.sport = ''
      this.filters.league = ''
      this.datosTransformados = []
      this.regionesFiltradas = {}
      // Filtra las regiones basadas en el deporte seleccionado
      const deporteSeleccionado = this.sports_prematch[this.seleccionDeporte.id];

      this.regionesFiltradas = deporteSeleccionado ? deporteSeleccionado.region : {};
      this.transform_data(this.regionesFiltradas)

      ////console.log(deporteSeleccionado, Object.keys(this.regionesFiltradas).length)
    },
    getOdds() {
      this.oddsfiltered = this.AllOds.docs.filter(doc => {
        // Verifica si el filtro de deporte está vacío o coincide con el deporte del documento
        const sportFilterPass = this.filters.sport === '' || doc.sport === this.filters.sport;

        // Verifica si el filtro de competición está vacío o coincide con la competición del documento
        const competitionFilterPass = this.filters.competition === '' || doc.league === this.filters.competition;

        // Retorna true solo si ambos filtros pasan
        return sportFilterPass && competitionFilterPass;
      });
      // this.seleccionDeporte.name = this.AllOds[this.seleccionDeporte.id].alias
      ////console.log(this.sports_prematch, this.AllOds)
      ////console.log(this.oddsfiltered)
    },

    getGames() {
      this.filters.startDate = this.fechaFinal
      this.filters.endDate = this.fechaInicial
      //console.log(this.filters)
      // this.$store.commit("createGameIds", this.Games.map((game) => game.game_id));
      // this.get_Ids()+
      
      if (new Date(this.fechaInicial) < new Date(this.fechaFinal)) {
        // Mostrar un mensaje de error o realizar alguna acción adecuada
        alert(translate('start_end_date'));
        return;
      }
      if (this.filters.sport=='' || this.filters.league=='') {
        // Mostrar un mensaje de error o realizar alguna acción adecuada
        alert('Select Sport and League.');
        return;
      }
      this.$store.dispatch("getGames", this.filters);
      // this.filters = { sport: '', league: '',startDate:'',endDate:'' }
      //console.log(this.filters)

    },
    // get_Ids(){
    //   this.game_ids = this.$store.state.gameIds
    //   ////console.log(this.$store.state.gameIds)
    // },

    // Función para verificar si el elemento cumple con el filtro de fecha
    cumpleFiltroFecha(item) {
      if (!this.fechaInicial && !this.fechaFinal) {
        // Si no hay fechas seleccionadas, siempre cumple con el filtro
        return true;
      }

      const fechaItem = new Date(item.fecha); // Ajusta esto según la propiedad de fecha de tu objeto
      const fechaInicial = this.fechaInicial ? new Date(this.fechaInicial) : null;
      const fechaFinal = this.fechaFinal ? new Date(this.fechaFinal) : null;

      if (fechaInicial && fechaItem < fechaInicial) {
        return false;
      }

      if (fechaFinal && fechaItem > fechaFinal) {
        return false;
      }

      return true;
    },
    // Función para realizar la búsqueda
    realizarBusqueda() {
      // Aquí puedes realizar acciones adicionales si es necesario
    },
  },
  mounted() {
    // this.$store.dispatch("getAllOdds");
    ////console.log(this.sports_prematch)
  }
};
</script>
  <!-- primero que se realice labúsqueda -->
  
<style scoped>
/* Puedes agregar estilos adicionales aquí si es necesario */
.scroll-container {
  display: flex;
  overflow-x: auto; /* Permite desplazarse horizontalmente si hay demasiada información */
}

.odds-info-item {
  flex: 0 0 auto; /* Evita que los elementos se estiren para ajustarse al contenedor */
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 10px;
}

.market-name {
  margin-bottom: 5px;
  font-weight: bold;
}

.status {
  margin-top: 5px;
  font-weight: bold;
}

.status-won {
  color: #28a745; /* Verde para estado "Ganado" */
}

.status-lost {
  color: #dc3545; /* Rojo para estado "Perdido" */
}

.status-returned {
  color: #ffc107; /* Amarillo para estado "Devuelto" */
} 
</style>
  