<template>
  <div class="d-flex p-0" :style="skin.navigation_bar.frame">
        <div @click="liga" style="cursor:pointer;" :style="skin.navigation_bar.fondo" class="py-2 pb-2 px-3 pe-3">
            <span :style="skin.navigation_bar.arrow"><i class="fas fa-arrow-left"></i></span>
        </div>
        <div @click="start" class="py-2 pb-2 px-3 pe-0" style="cursor:pointer;">
            <span :style="skin.navigation_bar.text">{{ translate('home') }}</span>&nbsp;&nbsp; 
        </div>
        <div 
            v-for="(link,index) in navigation"
            v-bind:key="index"
            class="py-2 pb-2 px-0 pe-1"
        >
            <span :style="skin.navigation_bar.text"><i class="fas fa-caret-right"></i></span>&nbsp;&nbsp; 
            <span :style="skin.navigation_bar.text">{{ link.name }}</span>
        </div>
  </div>
</template>

<script>
export default {
    name: "navigation_bar",
    props:["skin","translation","language","navigation"],
    data(){
        return {

        }
    },
    methods:{
        start(){
            this.$store.dispatch('setLobbyView','main');
            let data = this.$store.getters['prematch_2/getLigasArray'];
            data.forEach(liga => {
                this.$root.$emit('uncheck_liga_prematch2',liga.league);
            });
            let check_countries = document.getElementsByClassName('check_country_prematch2');
            [...check_countries].forEach(item=>{
                if(item.checked){
                    item.checked = false;
                }
            });
            this.$store.dispatch('prematch_2/remove_ligas');
            this.$store.dispatch('prematch_2/unsuscribe_all_events');
        },
        liga(){
            let league = this.$store.getters['prematch_2/getLigasArray'];

            // console.log(league,this.$store.state.lobby_view)
            if(league.length != 0 && this.$store.state.lobby_view=='prematch2_evento'){
                let data = league[0]
                this.$store.dispatch('setLobbyView','prematch2_ligas');
                this.check=!this.check;
                let payload = {
                    league:data.league,
                    country:data.country,
                    league_name:data.league_name,
                    country_name:data.country,
                    sport_id:1,
                    sport_name:this.translate(data.sport_name)
                };
                // console.log(data,payload)
                this.$store.dispatch('prematch_2/add_remove_liga',payload);
            }
            else{
                this.$store.dispatch('setLobbyView','main');
                let data = this.$store.getters['prematch_2/getLigasArray'];
                data.forEach(liga => {
                    this.$root.$emit('uncheck_liga_prematch2',liga.league);
                });
                let check_countries = document.getElementsByClassName('check_country_prematch2');
                [...check_countries].forEach(item=>{
                    if(item.checked){
                        item.checked = false;
                    }
                });
                this.$store.dispatch('prematch_2/remove_ligas');
                this.$store.dispatch('prematch_2/unsuscribe_all_events');
            }
           
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    }
}
</script>

<style>

</style>