<template>
  <div style="font-size:small" class="mb-1" :style="Skin.qbet.bet_text">
    <div :style="Skin.qbet.bet_header" class="p-1">
        <i v-if="odd_data.alert" class="fas fa-exclamation-triangle" data-bs-toggle="tooltip" data-bs-placement="top" title="Este logro no se puede combinar">&nbsp;</i>
        <span v-if="odd_data.is_blocked || odd_data.actual_price=='1.00'">
          <i class="fas fa-lock" data-bs-toggle="tooltip" data-bs-placement="top" title="Logro Bloqueado">&nbsp;</i>
        </span>
        <span @click="delete_odd(odd_data.event_id)" style="cursor:pointer" class="float-end"><i class="fas fa-times"></i></span>
        <span style="">{{ odd_data.home }}</span>
        <span v-if="odd_data.away" style=""> -VS- {{ odd_data.away }}</span>
    </div>
    <div :style="Skin.qbet.bet_body" class="p-1">
        <span style="">{{ odd_data.category }}</span>
        <span v-if="odd_data.market_name=='Pronostico'" style="color:yellow"> - {{translate("forescasts")}}</span>
        <span v-if="odd_data.market_name=='Teaser'" style="color:yellow"> {{ ' - Teaser '+odd_data.teaser.value+' puntos' }}</span>
        <span><br>{{ translate('bet_to') }} {{ odd_data.market_name!='Teaser' ? odd_data.selection : odd_data.selection.split('(')[0] + (odd_data.odd_type=='Over' ? odd_data.teaser.base - odd_data.teaser.value : odd_data.teaser.base + odd_data.teaser.value) }}</span><br>
        <span v-if="odd_data.market_name!='Teaser'" :style="Skin.qbet.bet_text" style="font-size:16px">{{ translate('rate') }} </span>
        <span v-if="odd_data.market_name!='Teaser'" class="float-end" :style="Skin.qbet.bet_text2">
          <span v-if="odd_data.is_blocked || odd_data.actual_price=='1.00'">
            <i class="fas fa-lock" data-bs-toggle="tooltip" data-bs-placement="top" title="Logro Bloqueado">&nbsp;</i>
          </span>
          <span v-else>{{ rate_conversion(odd_data.actual_price) }}</span>
        </span>
        <span v-if="odd_data.live" style="color:red" class="float-end">
          <span class="text-decoration-line-through">{{ odd_data.actual_price != odd_data.init_price && !(odd_data.is_blocked || odd_data.actual_price=='1.00') ? rate_conversion(odd_data.init_price) : "" }}</span>
          &nbsp;&nbsp;&nbsp;
        </span>
        <div v-if="type=='simple'" class="my-2">
          <div v-if="Skin.qBet_config.risk_win" class="p-2">
            <div class="input-group">
              <input @input="calculateWin('risk'+odd_data.event_id,'win'+odd_data.event_id,odd_data.actual_price)" :id="'risk'+odd_data.event_id" type="number" placeholder="0" class="form-control form-control-sm" aria-label="Recipient's username" aria-describedby="basic-addon2">
              <span class="input-group-text" id="basic-addon2" :style="Skin.qbet.risk_win_fondo" style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{translate("risk")}}</span>
            </div>
            <div class="input-group">
              <input @input="calculateRisk('win'+odd_data.event_id,'risk'+odd_data.event_id,odd_data.actual_price)" :id="'win'+odd_data.event_id" type="number" placeholder="0" class="form-control form-control-sm" aria-label="Recipient's username" aria-describedby="basic-addon3">
              <span class="input-group-text" id="basic-addon3" :style="Skin.qbet.risk_win_fondo" style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{translate("gain")}}</span>
            </div>
          </div>
          <div v-else>
            <input v-model="total_bet" class="form-control form-control-sm border" type="number" :placeholder="translate('enter_bet')" style="font-size:small"/>
            <div class="my-1 px-2">
              <div v-if="vat > 0">
                  <span :style="Skin.qbet.text">{{ translate('A ganar:') }}</span>
                  <span class="float-end" :style="Skin.qbet.text">{{ sanitized(total_bet * odd_data.actual_price > max_win ? max_win : (parseFloat(total_bet * odd_data.actual_price).toFixed(Skin.qBet_config.decimalsQty)),user.user_data.data ? user.user_data.data.currency : '') }}</span><br>
                  <span :style="Skin.qbet.text">{{ translate('Impuesto SRI') + ' (' + (vat * 100).toFixed(0) + '%):' }}</span>
                  <span class="float-end" :data-rate="total_rate" :style="Skin.qbet.text">{{ sanitized((((total_bet * odd_data.actual_price > max_win ? max_win : (parseFloat(total_bet * odd_data.actual_price).toFixed(Skin.qBet_config.decimalsQty))) - total_bet) * vat).toFixed(2),user.user_data.data ? user.user_data.data.currency : '') }}</span><br>
                  <span :style="Skin.qbet.text">{{ translate('overall_gain') }}</span>
                  <span class="float-end" :style="Skin.qbet.text">{{ sanitized((total_bet * odd_data.actual_price > max_win ? max_win : (parseFloat(total_bet * odd_data.actual_price).toFixed(Skin.qBet_config.decimalsQty))) - ((total_bet * odd_data.actual_price > max_win ? max_win : (parseFloat(total_bet * odd_data.actual_price).toFixed(Skin.qBet_config.decimalsQty))) - total_bet) * vat,user.user_data.data ? user.user_data.data.currency : '') }}</span>
                </div>
                <div v-else>
                  <span :style="Skin.qbet.text">{{ translate('gain') }}</span>
                  <span class="float-end py-1" :style="Skin.qbet.text">{{ sanitized(total_bet * odd_data.actual_price > max_win ? max_win : (parseFloat(total_bet * odd_data.actual_price).toFixed(Skin.qBet_config.decimalsQty)),user.user_data.data ? user.user_data.data.currency : '') }}</span>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bet',
  props: ['odd_data','Skin','language','translation','type','bet_currency'],
  data(){
    return {
      total_bet:""
    }
  }, 
  methods:{
    sanitized(val,currency){
      // Combinar la parte entera formateada con la parte decimal
      if (typeof val === 'string') {
        val = parseFloat(val);
      }
      const formattedValue = val.toLocaleString("en-US");
      if(this.$props.Skin.conversion ? (this.$props.Skin.conversion.enabled ? true : false) : false){
        if(currency=='BETC'){
          return formattedValue+' '+this.$props.bet_currency;
        }
        return formattedValue+(currency!=''?' '+currency:'');
      }
      return formattedValue+(currency!=''?' '+currency:'');
    },
    total_bet_sanitized(val,currency){
      if(this.$props.Skin.conversion ? (this.$props.Skin.conversion.enabled ? true : false) : false){
        if(currency=='BETC' && this.$props.bet_currency=='USDT'){
          let tasa = this.user.user_data.data.tasa??1;
          return parseFloat(val/tasa).toFixed(4);
        }
        else if(currency=='BETC' && this.bet_currency!='BETC'&&this.bet_currency!='USDT'){
          let tasa_fiat = this.user.user_data.data.tasa_fiat??1;
          let tasa_betc = this.user.user_data.data.tasa??1;
          return parseFloat((val/tasa_fiat)/tasa_betc).toFixed(4);
        }
        return parseFloat(val).toFixed(this.$props.Skin.qBet_config.decimalsQty)
      }
      return parseFloat(val).toFixed(this.$props.Skin.qBet_config.decimalsQty)
    },
    delete_odd:function(odd_id){
      if(this.$props.odd_data.islive){
        this.$store.dispatch('live/unsuscribe_odd',odd_id);
      }
      else{
        this.$store.dispatch('deleteBet', odd_id);
      }
    },
    translate(text){
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    rate_conversion(rate){
      if(this.rate_type == 'americano'){
        let value = parseFloat(rate);
        if(value >= 2){
            let result = (value-1)*100;
            return '+'+(Math.round(result));
        }
        else{
            let result = (-100)/(value-1);
            return Math.round(result);
        }
      }
      return parseFloat(rate).toFixed(2)
    },
    calculateWin(source_id,target_id,rate){
      if(document.getElementById(source_id)){
        let val = document.getElementById(source_id).value;
        let win = ((rate * val) - val).toFixed(this.$props.Skin.qBet_config.decimalsQty);
        document.getElementById(target_id).value = val != '' ? win : val;
        this.total_bet = val;
        let win_inputs = document.getElementsByClassName('win');
        let totalWin = 0;
        [...win_inputs].forEach(element => {
          totalWin += parseFloat(element.value==""?0:element.value);
        }); 
        this.sistema_total_win = totalWin.toFixed(this.$props.Skin.qBet_config.decimalsQty);
        let risk_inputs = document.getElementsByClassName('risk');
        let totalRisk = 0;
        [...risk_inputs].forEach(element => {
          totalRisk += parseFloat(element.value==""?0:element.value * element.dataset.qty);
        });
        this.sistema_total_risk = totalRisk;
      }
    },
    calculateRisk(source_id,target_id,rate){
      let val = document.getElementById(source_id).value;
      let risk = (val/(rate-1)).toFixed(this.$props.Skin.qBet_config.decimalsQty);
      document.getElementById(target_id).value = val != '' ? risk : val;
      this.total_bet = risk;
      let win_inputs = document.getElementsByClassName('win');
      let totalWin = 0;
      [...win_inputs].forEach(element => {
        totalWin += parseFloat(element.value==""?0:element.value);
      }); 
      this.sistema_total_win = totalWin;
      let risk_inputs = document.getElementsByClassName('risk');
      let totalRisk = 0;
      [...risk_inputs].forEach(element => {
        totalRisk += parseFloat(element.value==""?0:element.value*element.dataset.qty);
      });
      this.sistema_total_risk = totalRisk;
    }
  },
  computed:{
    vat(){
      return this.$store.getters['getConfig'].SkinConfig.main.vat ?? 0
    },
    max_win(){
      return parseFloat(this.$store.getters['getUserData'].user_data.data ? this.$store.getters['getUserData'].user_data.data.max_win_amount : 100000000);
    },
    rate_type(){
      return this.$store.getters['getRateType'];
    },
    user(){
      return this.$store.getters['getUserData'];
    },
  },
  watch:{
    total_bet(newVal){
        var currency = this.user.user_data.data ? this.user.user_data.data.currency : '';
        this.$emit('simple_bet', {
          id:this.$props.odd_data.event_id, 
          val:this.total_bet_sanitized(newVal,currency),
          valFiat:this.$props.bet_currency!='BETC'&&currency=="BETC"?newVal:0,
          rate:this.$props.odd_data.actual_price
        });
    },
    type(newVal){
      if(newVal !== 'simple'){
        this.total_bet = "";
      }
    }
  },
  destroyed(){
    this.$emit('delete_simple_bet', this.$props.odd_data.event_id);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>
