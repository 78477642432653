<template>
<div>
    <div :style="Skin.sport_table.fondo" class="row text-center">
        <div class="col col-lg-4 col-sm-5 py-1" style="width:34%">
        </div>
        <div class="col col-lg-4 col-sm-6 py-1" style="width:30%">
            <span :style="Skin.sport_table.text">1X2</span>
        </div>
        <div class="col col-3 py-1 d-none d-lg-block" style="width:30%">
            <span :style="Skin.sport_table.text">Total</span>
        </div>
        <div class="col col-1 p-0" style="max-width:6%">
        </div>
    </div>
    <div class="row text-center" :style="Skin.sport_table.fondo">
        <div class="col col-lg-4 col-sm-5" style="width:34%">
        </div>
        <div class="col col-lg-4 col-sm-6" style="width:30%">
        <table style="width:100%">
            <tr>
            <th><span :style="Skin.sport_table.text">1</span></th>
            <th><span :style="Skin.sport_table.text">X</span></th>
            <th><span :style="Skin.sport_table.text">2</span></th>
            </tr>
        </table>
        </div>
        <div class="col col-4 d-none d-lg-block p-0" style="width:30%">
        <table style="width:100%">
            <tr>
            <th><span :style="Skin.sport_table.text">{{translate("goals")}}</span></th>
            <th><span :style="Skin.sport_table.text">{{translate("more_than")}}</span></th>
            <th><span :style="Skin.sport_table.text">{{translate("less_than")}}</span></th>
            </tr>
        </table>
        </div>
        <div class="col col-1 p-0" style="max-width:6%">
        
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'futbol',
  props: ["Skin","translation","language"],
  data(){
    return {
        
    }
  },
  methods:{
    translate(text) {
        if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
        }
        return text;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
