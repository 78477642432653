<template>
  <div>
    <div class="px-0">
      <div v-if="sport==1" class="table-responsive">
        <div>
          <div class="px-1">
            <div class="accordion" :id="'matchPrematch-'+game.id"> 
              <div class="accordion-item border border-secondary p-0" :style="Skin.prematch_preview.fondo">
                <div class="accordion-header px-1 pe-1" :id="'heading-'+game.id" >
                  <div v-if="screen_width>Skin.break_point" class="d-flex" :style="Skin.prematch_preview.fondo">
                    <div  class="col col-lg-4 col-sm-5 p-1" style="line-height:15px;width:34%">
                      <div @click="select_event" style="cursor:pointer">
                          <span :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span :style="Skin.prematch_preview.text_liga">{{ game.liga }} </span>
                          <span :style="Skin.prematch_preview.text_country">- {{ game.country }} </span> <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:15px;height:15px"></span><br>
                          <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team1_id+'.png'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="team" width="18">&nbsp; <span :style="Skin.prematch_preview.text_team">{{ game.team1_name}}</span><br>
                          <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team2_id+'.png'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="team" width="18">&nbsp; <span :style="Skin.prematch_preview.text_team">{{ game.team2_name}}</span>
                        </div>
                    </div>
                    <div class="col col-lg-4 col-sm-6 p-0" style="width:30%">
                      <table v-if="game.team2_name" style="width:95%" class="mx-auto my-2">
                        <tr>
                          <Odd
                            v-if="P1XP2.P1 ? (P1XP2.P1.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.P1))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.P1"
                            v-bind:active_state="selected_odds[P1XP2.P1.id]"
                            @click.native="
                              validate_odd(P1XP2.P1) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.P1,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.P1.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="P1XP2.X ? (P1XP2.X.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.X))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.X"
                            v-bind:active_state="selected_odds[P1XP2.X.id]"
                            @click.native="
                              validate_odd(P1XP2.X) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.X,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.X.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="P1XP2.P2 ? (P1XP2.P2.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.P2))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.P2"
                            v-bind:active_state="selected_odds[P1XP2.P2.id]"
                            @click.native="
                              validate_odd(P1XP2.P2) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.P2,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.P2.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                    <div class="col col-3 p-0 d-none d-lg-block" style="width:30%">
                      <table v-if="game.team2_name" style="width:95%" class="mx-auto my-2">
                        <tr>
                          <th class="text-center p-0 text-dark" style="font-size:small">
                            <div
                            class="text-center px-1 pe-1"
                            :style="Skin.prematch_preview.text_liga"
                            >
                              2.5
                            </div>
                          </th>
                          <Odd
                            v-if="OverUnder.Over"
                            v-bind:key="OverUnder.Over.id+'content'"
                            v-bind:rate="parseFloat(validate_odd(OverUnder.Over))"
                            v-bind:Skin="Skin"
                            v-bind:logro="OverUnder.Over"
                            v-bind:active_state="selected_odds[OverUnder.Over.id]"
                            @click.native="
                              validate_odd(OverUnder.Over) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                OverUnder.Over,
                                translate('total_goals'),
                                'OverUnder',
                                OverUnder.Over.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'OverUnder',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="OverUnder.Under"
                            v-bind:key="OverUnder.Under.id+'content'"
                            v-bind:rate="parseFloat(validate_odd(OverUnder.Under))"
                            v-bind:Skin="Skin"
                            v-bind:logro="OverUnder.Under"
                            v-bind:active_state="selected_odds[OverUnder.Under.id]"
                            @click.native="
                              validate_odd(OverUnder.Under) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                OverUnder.Under,
                                translate('total_goals'),
                                'OverUnder',
                                OverUnder.Under.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'OverUnder',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd+'min-width:30px;'"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                    <div class="col col-1 px-0 py-1 text-center" style="max-width:6%">
                      <div v-if="hasStats(game.id)" @click="showStadisticas(game)" style="cursor: pointer;">
                          <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                        </div>
                        <div v-else style="width: 14px;height: 14px;"></div>
                      <div @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                          <span v-if="!game.mode" :style="Skin.prematch_preview.show_all"><i class="fas fa-plus-square"></i></span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                      <div style="display:flex;width:100%;position:relative;" class="p-1">
                          <span class="me-1" :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:20px;height:20px"></span>
                          <span class="long_text2" :style="Skin.prematch_preview.text_liga">{{ game.liga }} - {{ game.country }}</span>
                          <div>
                            <div style="padding: 10px 0px;" @click="showStadisticas(game)">
                              <div v-if="hasStats(game.id)" style="cursor:pointer;position:absolute;top:5px;right:5px;cursor: pointer;" class="text1 collapsed m-0">
                                <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                              </div>
                              <div v-else style="width: 14px;height: 14px;"></div>
                            </div>
                            <div v-if="!game.mode" @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer;position:absolute;top:40px;right:5px;" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                              <span :style="Skin.prematch_preview.show_all"><i class="fas fa-angle-down float-end"></i></span>
                          </div>
                          </div>
                      </div>
                      <div @click="select_event" style="cursor:pointer" class="px-1">
                            <div style="display:flex;width:100%">
                                <div style="width:72%">
                                  <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team1_id+'.png??v=0.77896426980335'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="team" width="18">&nbsp;
                                  <span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team1_name }}</span></div>
                            </div>
                            <div style="display:flex;width:100%">
                                <div style="width:72%">
                                  <img v-if="game.team2_name" :src="'https://statistics.betconstruct.com/images/e/m/0/'+game.team2_id+'.png??v=0.77896426980335'" onerror="this.src='https://resources.jbets.online/logos/empty_escudo.png'" alt="team" width="18">&nbsp;
                                  <span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team2_name }}</span></div>
                            </div> 
                      </div>
                      <div v-if="game.team2_name">
                          <div class="p-0" style="display:flex;width:100%" :style="hoverCss">
                              <div 
                                  v-if="P1XP2.P1 ? (P1XP2.P1.price ? true : false) : false"
                                  :id="P1XP2.P1.id" 
                                  :class="selected_odds[P1XP2.P1.id] ? 'hover' : ''"
                                  @mouseover="selected_odds[P1XP2.P1.id] ? '' : hover1(P1XP2.P1.id)"
                                  @mouseleave="selected_odds[P1XP2.P1.id] ? '' : hover2(P1XP2.P1.id)" 
                                  class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                                  @click="
                                      validate_odd(P1XP2.P1) == '1.00' ? '' :
                                      odd_selected(
                                        game.id,
                                        P1XP2.P1,
                                        translate('match_result'),
                                        'P1XP2',
                                        P1XP2.P1.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'P1XP2',
                                      )
                                  "
                              >
                                  <span style="font-size:14px">{{ P1XP2.P1.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P1.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              <div 
                              v-if="P1XP2.X ? (P1XP2.X.price ? true : false) : false"
                              :id="P1XP2.X.id" 
                              :class="selected_odds[P1XP2.X.id] ? 'hover' : ''"
                              @mouseover="selected_odds[P1XP2.X.id] ? '' : hover1(P1XP2.X.id)"
                              @mouseleave="selected_odds[P1XP2.X.id] ? '' : hover2(P1XP2.X.id)" 
                              class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                              @click="
                                  validate_odd(P1XP2.X) == '1.00' ? '' :
                                  odd_selected(
                                  game.id,
                                  P1XP2.X,
                                  translate('match_result'),
                                  'P1XP2',
                                  P1XP2.X.market_id,
                                  game.team1_name,
                                  game.team2_name,
                                  game.start_ts,
                                  'P1XP2',
                                  )
                              "
                              >
                                  <span style="font-size:14px">{{ P1XP2.X.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.X.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              <div
                              v-if="P1XP2.P2 ? (P1XP2.P2.price ? true : false) : false" 
                              :id="P1XP2.P2.id" 
                              :class="selected_odds[P1XP2.P2.id] ? 'hover' : ''"
                              @mouseover="selected_odds[P1XP2.P2.id] ? '' : hover1(P1XP2.P2.id)"
                              @mouseleave="selected_odds[P1XP2.P2.id] ? '' : hover2(P1XP2.P2.id)" 
                              class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                              @click="
                                  validate_odd(P1XP2.P2) == '1.00' ? '' :
                                  odd_selected(
                                  game.id,
                                  P1XP2.P2,
                                  translate('match_result'),
                                  'P1XP2',
                                  P1XP2.P2.market_id,
                                  game.team1_name,
                                  game.team2_name,
                                  game.start_ts,
                                  'P1XP2',
                                  )
                              "
                              >
                                  <span style="font-size:14px">{{ P1XP2.P2.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P2.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              
                          </div>
                      </div>
                  </div>
                </div>
                <div
                  :id="'collapse-' + game.id"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  :data-bs-parent="'#matchPrematch-' + game.id"
                >
                  <all-odds
                      v-if="Object.keys(market).length>0"
                      v-bind:Skin="Skin"
                      v-bind:language="language"
                      v-bind:translation="translation"
                      v-bind:game="market"
                      v-bind:league="game.liga"
                  >
                  </all-odds>
                  <div v-else :style="screen_width<Skin.break_point?'':'width:100%; height:90vh'" class="text-center py-5">
                      <div class="spinner-border" :style="Skin.loader" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="sport==76 || sport==29 || sport==37">
        <div>
          <div class="px-1">
            <div class="accordion" :id="'matchPrematch-'+game.id"> 
              <div class="accordion-item border border-secondary p-0" :style="Skin.prematch_preview.fondo">
                <div class="accordion-header px-1 pe-1" :id="'heading-'+game.id" >
                  <div v-if="screen_width>Skin.break_point" class="d-flex" :style="Skin.prematch_preview.fondo" style="width:100%;">
                    <div  class="col col-lg-8 col-sm-6 p-1" style="line-height:15px;">
                      <div @click="select_event" style="cursor:pointer">
                          <span :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:15px;height:15px"></span>
                          <span :style="Skin.prematch_preview.text_liga">{{ game.liga }} </span>
                          <span :style="Skin.prematch_preview.text_country">- {{ game.country }} </span><br>
                          <span :style="Skin.prematch_preview.text_team">{{ game.team1_name}} </span><br>
                          <span :style="Skin.prematch_preview.text_team">{{ game.team2_name}} </span>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-sm-5 p-0" style="">
                      <table v-if="game.team2_name" style="width:95%" class="mx-auto my-2">
                        <tr>
                          <Odd
                            v-if="P1XP2.P1 ? (P1XP2.P1.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.P1))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.P1"
                            v-bind:active_state="selected_odds[P1XP2.P1.id]"
                            @click.native="
                              validate_odd(P1XP2.P1) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.P1,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.P1.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="P1XP2.X ? (P1XP2.X.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.X))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.X"
                            v-bind:active_state="selected_odds[P1XP2.X.id]"
                            @click.native="
                              validate_odd(P1XP2.X) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.X,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.X.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          <Odd
                            v-if="P1XP2.P2 ? (P1XP2.P2.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1XP2.P2))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1XP2.P2"
                            v-bind:active_state="selected_odds[P1XP2.P2.id]"
                            @click.native="
                              validate_odd(P1XP2.P2) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1XP2.P2,
                                translate('match_result'),
                                'P1XP2',
                                P1XP2.P2.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1XP2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </div>
                    <div class="col col-1 px-0 py-1 text-center" style="">
                        <div v-if="hasStats(game.id)" style="cursor: pointer;">
                          <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                        </div>
                        <div v-else style="width: 14px;height: 14px;"></div>
                        <div @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                          <span v-if="!game.mode" :style="Skin.prematch_preview.show_all"><i class="fas fa-plus-square"></i></span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="display:flex;width:100%;position:relative;" class="p-1">
                          <span class="me-1" :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:20px;height:20px"></span>
                          <span class="long_text2" :style="Skin.prematch_preview.text_liga">{{ game.liga }} - {{ game.country }}</span>
                          <div>
                            <div style="padding: 10px 0px;" @click="showStadisticas(game)">
                              <div v-if="hasStats(game.id)" style="cursor:pointer;position:absolute;top:5px;right:5px;cursor: pointer;" class="text1 collapsed m-0">
                                <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                              </div>
                              <div v-else style="width: 14px;height: 14px;"></div>
                            </div>
                            <div v-if="!game.mode" @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer;position:absolute;top:40px;right:5px;" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                              <span :style="Skin.prematch_preview.show_all"><i class="fas fa-angle-down float-end"></i></span>
                          </div>
                          </div>
                      </div>
                      <div @click="select_event" style="cursor:pointer" class="px-1">
                            <div style="display:flex;width:100%">
                                <div style="width:72%"><span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team1_name }}</span></div>
                            </div>
                            <div style="display:flex;width:100%">
                                <div style="width:72%"><span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team2_name }}</span></div>
                            </div> 
                      </div>
                      <div v-if="game.team2_name">
                          <div class="p-0" style="display:flex;width:100%" :style="hoverCss">
                              <div 
                                  v-if="P1XP2.P1 ? (P1XP2.P1.price ? true : false) : false"
                                  :id="P1XP2.P1.id" 
                                  :class="selected_odds[P1XP2.P1.id] ? 'hover' : ''"
                                  @mouseover="selected_odds[P1XP2.P1.id] ? '' : hover1(P1XP2.P1.id)"
                                  @mouseleave="selected_odds[P1XP2.P1.id] ? '' : hover2(P1XP2.P1.id)" 
                                  class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                                  @click="
                                      validate_odd(P1XP2.P1) == '1.00' ? '' :
                                      odd_selected(
                                        game.id,
                                        P1XP2.P1,
                                        translate('match_result'),
                                        'P1XP2',
                                        P1XP2.P1.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'P1XP2',
                                      )
                                  "
                              >
                                  <span style="font-size:14px">{{ P1XP2.P1.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P1.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              <div 
                              v-if="P1XP2.X ? (P1XP2.X.price ? true : false) : false"
                              :id="P1XP2.X.id" 
                              :class="selected_odds[P1XP2.X.id] ? 'hover' : ''"
                              @mouseover="selected_odds[P1XP2.X.id] ? '' : hover1(P1XP2.X.id)"
                              @mouseleave="selected_odds[P1XP2.X.id] ? '' : hover2(P1XP2.X.id)" 
                              class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                              @click="
                                  validate_odd(P1XP2.X) == '1.00' ? '' :
                                  odd_selected(
                                  game.id,
                                  P1XP2.X,
                                  translate('match_result'),
                                  'P1XP2',
                                  P1XP2.X.market_id,
                                  game.team1_name,
                                  game.team2_name,
                                  game.start_ts,
                                  'P1XP2',
                                  )
                              "
                              >
                                  <span style="font-size:14px">{{ P1XP2.X.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.X.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              <div
                              v-if="P1XP2.P2 ? (P1XP2.P2.price ? true : false) : false" 
                              :id="P1XP2.P2.id" 
                              :class="selected_odds[P1XP2.P2.id] ? 'hover' : ''"
                              @mouseover="selected_odds[P1XP2.P2.id] ? '' : hover1(P1XP2.P2.id)"
                              @mouseleave="selected_odds[P1XP2.P2.id] ? '' : hover2(P1XP2.P2.id)" 
                              class="p-2 m-1" style="width:33%" :style="Skin.prematch_preview.odd"
                              @click="
                                  validate_odd(P1XP2.P2) == '1.00' ? '' :
                                  odd_selected(
                                  game.id,
                                  P1XP2.P2,
                                  translate('match_result'),
                                  'P1XP2',
                                  P1XP2.P2.market_id,
                                  game.team1_name,
                                  game.team2_name,
                                  game.start_ts,
                                  'P1XP2',
                                  )
                              "
                              >
                                  <span style="font-size:14px">{{ P1XP2.P2.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1XP2.P2.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:33%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div
                  :id="'collapse-' + game.id"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  :data-bs-parent="'#matchPrematch-' + game.id"
                >
                  <all-odds
                      v-if="Object.keys(market).length>0"
                      v-bind:Skin="Skin"
                      v-bind:language="language"
                      v-bind:translation="translation"
                      v-bind:game="market"
                      v-bind:league="game.liga"
                  >
                  </all-odds>
                  <div v-else :style="screen_width<450?'':'width:100%; height:90vh'" class="text-center py-5">
                      <div class="spinner-border" :style="Skin.loader" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="table-responsive">
        <div class="px-1">
          <div>
              <div class="accordion" :id="'matchPrematch-'+game.id">
                <div class="accordion-item border border-secondary" :style="Skin.prematch_preview.fondo">
                  <div class="accordion-header px-1 pe-1<" :id="'heading-'+game.id" >
                    <div v-if="screen_width>Skin.break_point" class="d-flex" :style="Skin.prematch_preview.fondo">
                      <div class="col col-lg-8 col-sm-6 p-1" style="line-height:15px">
                        <div @click="select_event" style="cursor:pointer">
                            <span :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                            <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:15px;height:15px"></span>
                            <span :style="Skin.prematch_preview.text_liga">{{ game.liga }} </span> - 
                            <span :style="Skin.prematch_preview.text_country">{{ game.country }} </span><br>
                            <span :style="Skin.prematch_preview.text_team">{{ game.team1_name}} </span><br>
                            <span :style="Skin.prematch_preview.text_team">{{ game.team2_name}} </span>
                        </div>
                      </div>
                      <div class="col col-lg-3 col-sm-5 p-0" style="min-width: 10.58%">
                        <table v-if="game.team2_name" style="width:95%" class="mx-auto my-2">
                          <tr>
                            <Odd
                            v-if="P1P2.P1 ? (P1P2.P1.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1P2.P1))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1P2.P1"
                            v-bind:active_state="selected_odds[P1P2.P1.id]"
                            @click.native="
                              validate_odd(P1P2.P1) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1P2.P1,
                                translate('match_winner'),
                                'P1P2',
                                P1P2.P1.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1P2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                            <Odd
                            v-if="P1P2.P2 ? (P1P2.P2.price ? true : false) : false"
                            v-bind:rate="parseFloat(validate_odd(P1P2.P2))"
                            v-bind:Skin="Skin"
                            v-bind:logro="P1P2.P2"
                            v-bind:active_state="selected_odds[P1P2.P2.id]"
                            @click.native="
                              validate_odd(P1P2.P2) == '1.00' ? '' :
                              odd_selected(
                                game.id,
                                P1P2.P2,
                                translate('match_winner'),
                                'P1P2',
                                P1P2.P2.market_id,
                                game.team1_name,
                                game.team2_name,
                                game.start_ts,
                                'P1P2',
                              )
                            "
                          >
                          </Odd>
                          <th v-else class="py-2">
                            <div
                                class="py-2 pb-2 px-0 pe-0 text-center" :style="Skin.prematch_preview.odd"
                            >
                                <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                            </div>
                          </th>
                          </tr>
                        </table>
                      </div>
                      <div class="col col-1 px-0 py-1 text-center" style="max-width:6%">
                        <div v-if="hasStats(game.id)" @click="showStadisticas(game)" style="cursor: pointer;">
                          <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                        </div>
                        <div v-else style="width: 14px;height: 14px;"></div>
                        <div v-if="!game.mode" @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                            <span :style="Skin.prematch_preview.show_all"><i class="fas fa-plus-square"></i></span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="display:flex;width:100%;position:relative;" class="p-1">
                          <span class="me-1" :style="Skin.prematch_preview.text_date">{{ format_date(game.start_ts) }}</span><br>
                          <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag" style="max-width:20px;height:20px"></span>
                          <span class="long_text2" :style="Skin.prematch_preview.text_liga">{{ game.liga }} - {{ game.country }}</span>
                          <div>
                            <div style="padding: 10px 0px;" @click="showStadisticas(game)">
                              <div v-if="hasStats(game.id)" style="cursor:pointer;position:absolute;top:5px;right:5px;cursor: pointer;" class="text1 collapsed m-0">
                                <svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffffde" d="M3 17a1 1 0 102 0H3zM5 3a1 1 0 00-2 0h2zm4.009 14a1 1 0 102 0h-2zm2-10a1 1 0 10-2 0h2zM15 17a1 1 0 102 0h-2zm2-7a1 1 0 10-2 0h2zM5 17V3H3v14h2zm6.009 0V7h-2v10h2zM17 17v-7h-2v7h2z"></path> </g></svg>
                              </div>
                              <div v-else style="width: 14px;height: 14px;"></div>
                            </div>
                            <div v-if="!game.mode" @click="suscribePremathEvent(game.id)" class="text1 collapsed m-0" style="cursor:pointer;position:absolute;top:40px;right:5px;" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+game.id" aria-expanded="false" :aria-controls="'collapse-'+game.id">
                              <span :style="Skin.prematch_preview.show_all"><i class="fas fa-angle-down float-end"></i></span>
                          </div>
                          </div>
                      </div>
                      <div @click="select_event" style="cursor:pointer" class="p-0 px-1">
                            <div style="display:flex;width:100%">
                                <div style="width:72%"><span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team1_name }}</span></div>
                            </div>
                            <div style="display:flex;width:100%">
                                <div style="width:72%"><span :style="Skin.prematch_preview.text_team" style="font-size:14px" class="long_text1">{{ game.team2_name }}</span></div>
                            </div> 
                      </div>
                      <div :style="hoverCss" v-if="game.team2_name">
                          <div class="p-0" style="display:flex;width:100%">
                              <div 
                                  v-if="P1P2.P1 ? (P1P2.P1.price ? true : false) : false"
                                  :id="P1P2.P1.id" 
                                  :class="selected_odds[P1P2.P1.id] ? 'hover' : ''"
                                  @mouseover="selected_odds[P1P2.P1.id] ? '' : hover1(P1P2.P1.id)"
                                  @mouseleave="selected_odds[P1P2.P1.id] ? '' : hover2(P1P2.P1.id)" 
                                  class="p-2 m-1" style="width:50%" :style="Skin.prematch_preview.odd"
                                  @click="
                                      validate_odd(P1P2.P1) == '1.00' ? '' :
                                      odd_selected(
                                        game.id,
                                        P1P2.P1,
                                        translate('match_winner'),
                                        'P1P2',
                                        P1P2.P1.market_id,
                                        game.team1_name,
                                        game.team2_name,
                                        game.start_ts,
                                        'P1P2',
                                      )
                                  "
                              >
                                  <span style="font-size:14px">{{ P1P2.P1.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1P2.P1.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:50%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                              <div
                              v-if="P1P2.P2 ? (P1P2.P2.price ? true : false) : false" 
                              :id="P1P2.P2.id" 
                              :class="selected_odds[P1P2.P2.id] ? 'hover' : ''"
                              @mouseover="selected_odds[P1P2.P2.id] ? '' : hover1(P1P2.P2.id)"
                              @mouseleave="selected_odds[P1P2.P2.id] ? '' : hover2(P1P2.P2.id)" 
                              class="p-2 m-1" style="width:50%" :style="Skin.prematch_preview.odd"
                              @click="
                                  validate_odd(P1P2.P2) == '1.00' ? '' :
                                  odd_selected(
                                  game.id,
                                  P1P2.P2,
                                  translate('match_winner'),
                                  'P1P2',
                                  P1P2.P2.market_id,
                                  game.team1_name,
                                  game.team2_name,
                                  game.start_ts,
                                  'P1P2',
                                  )
                              "
                              >
                                  <span style="font-size:14px">{{ P1P2.P2.type }}</span>
                                  <span style="font-size:14px;float:right">{{ rate_conversion(P1P2.P2.price) }}</span>
                              </div>
                              <div v-else
                                  class="p-2 m-1 text-center" style="width:50%" :style="Skin.prematch_preview.odd"
                              >
                                  <span style="font-size:xx-small"><i  class="fas fa-lock"></i></span>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div
                  :id="'collapse-' + game.id"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  :data-bs-parent="'#matchPrematch-' + game.id"
                >
                  <all-odds
                      v-if="Object.keys(market).length>0"
                      v-bind:Skin="Skin"
                      v-bind:language="language"
                      v-bind:translation="translation"
                      v-bind:game="market"
                      v-bind:league="game.liga"
                  >
                  </all-odds>
                  <div v-else :style="screen_width<Skin.break_point?'':'width:100%; height:90vh'" class="text-center py-5">
                      <div class="spinner-border" :style="Skin.loader" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                  </div>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Odd from './odd.vue';
import AllOdds from './all_odds.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
const flags = require('../../../../assets/countries.json');
export default {
  name: "encuentro_prematch2",
  components: {
    Odd,
    Futbol,
    Cricket,
    AllOdds,
    Rugby,
    Tenis,
    Balonmano
  },
  data() {
    return {
      allOddsStatus:"close",
      meta_data: [],
      data_loaded: false,
      search_target:this.$props.search,
      winner_title:{
        3:"to_win_the_match",
        9:"to_win_fight",
        14:"to_win_match",
        15:"match_winner",
      },
      screen_width:0,
    };
  },
  props: ["game", "Skin", "language", "translation","sport"],
  computed: {
    dataStatsEvents() {
      return this.$store.getters['getGamesStats'];
    },
    selected_odds(){
        let odds = {};
        let data = this.$store.getters['getBets'];
        Object.keys(data).forEach(bet => {
          if(data[bet]){
            odds[bet] = true;
          }
        });
        return odds;
      },
    country_flag() {
          let flag = ''
          flags.countries.forEach(country => {
              if(country.name_es == this.$props.game.country_alias || country.name_en == this.$props.game.country_alias){
                flag = country.code.toLowerCase();
              }
          });
          return flag;
      },
    P1XP2(){
        let data = this.$props.game.market;
        let cuotas = {};
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
            if("type" in data[type]){
                if(data[type].type == "P1XP2" && data[type].event){
                    Object.keys(data[type].event).forEach(odd=>{
                      if("id" in data[type].event[odd] && "type" in data[type].event[odd] && "price" in data[type].event[odd]){
                        data[type].event[odd].market_id = type;
                        cuotas[data[type].event[odd].type] = data[type].event[odd]
                      }
                    });
                }   
            }
          }
        });
        return cuotas;
    },
    P1P2(){
        let data = this.$props.game.market;
        let cuotas = {};
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
            if("type" in data[type]){
              if(data[type].type == "P1P2" && data[type].event){
                Object.keys(data[type].event).forEach(odd=>{
                  if( data[type].event[odd].id && (data[type].event[odd].type || data[type].event[odd].name) && data[type].event[odd].price){
                    data[type].event[odd].market_id = type;
                    let odd_name = !data[type].event[odd].type ? (data[type].event[odd].name === 'W1' ? 'P1' : 'P2') : data[type].event[odd].type
                    cuotas[odd_name] = data[type].event[odd]
                  }
                });
              }   
            }
          }
        })
        return cuotas;
    },
    OverUnder(){
        let data = this.$props.game.market;
        let cuotas = {};
        Object.keys(data).forEach(type=>{
          if(data[type] && !isNaN(type)){
            if("type" in data[type]){
                if(data[type].type == "OverUnder" && data[type].event){
                    Object.keys(data[type].event).forEach(odd=>{
                        if( data[type].event[odd].id && data[type].event[odd].type){
                          data[type].event[odd].market_id = type;
                          cuotas[data[type].event[odd].type] = data[type].event[odd]
                        }
                    });
                }   
            }
          }
        })
        return cuotas;
    },
    market(){
      let data = this.$store.getters['prematch_2/getSuscribeEventsMarket'];
      if(data[this.$props.game.id] != null){
        let game = data[this.$props.game.id].data.data.sport[this.$props.sport].region[this.$props.game.country_id].competition[this.$props.game.liga_id].game[this.$props.game.id];
        return game;
      }
      return {};
    },
    hoverCss(){
      return {
        '--fondoH':this.$props.Skin.prematch_all_odds.hover_fondo,
        '--textH':this.$props.Skin.prematch_all_odds.hover_text,
      }
    },
    rate_type(){
      return this.$store.getters['getRateType'];
    },
    factorAjuste(){
      return this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
    },
    jbets_modified_odds(){
      return this.$store.getters['getJbetsModifieOdds'];
    }
  },
  methods: {
    hasStats(gamId) {
    return this.dataStatsEvents.some(event => event.betcons_game_id == gamId);
  },
    showStadisticas(game) {
      this.$store.dispatch('dataStatsModalAct', game);
    },
    suscribePremathEvent(id){
      this.$store.dispatch('prematch_2/unsuscribe_all_events');
      if(this.allOddsStatus="close"){
        this.$store.dispatch('prematch_2/suscribe_event',id);
        this.allOddsStatus = "open";
      }
      else{
        this.allOddsStatus = "close";
      }
    },
    format_date: function (ts) {
      let date = new Date(ts * 1000);
      var formated =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return formated;
    },
    safe_date(date){
      var parsed_array_1 = date.split(" ");
      var parsed_array_2 = parsed_array_1[0].split("-");
      var date_str = parsed_array_2[1]+"/"+parsed_array_2[2]+"/"+parsed_array_2[0]+" "+parsed_array_1[1];
      return date_str;
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    odd_header(header){
      if(header){
        if(header.includes("W1")){
          header = header.replace("W1",this.$props.game.team1_name);
        }
        if(header.includes("Equipo 1")){
          header = header.replace("Equipo 1",this.$props.game.team1_name);
        }
        if(header.includes("W2")){
          header = header.replace("W2",this.$props.game.team2_name);
        }
        if(header.includes("Equipo 2")){
          header = header.replace("Equipo 2",this.$props.game.team2_name);
        }
        if(header.includes(" X")){
          header = header.replace("X"," Empate");
        }
        return header;
      }
      return "error";
    },
    validate_odd(odd){
      if(odd){
        if(odd.price){
          let price = odd.price*this.factorAjuste<1?1.00:odd.price*this.factorAjuste;
          let jbets_modified_odd = this.jbets_modified_odds.filter(odd_modified=>odd_modified.event_id == odd.id);
          if(jbets_modified_odd.length==1){
            price = jbets_modified_odd[0].blocked?1.00:price*jbets_modified_odd[0].factor<1?1.00:price*jbets_modified_odd[0].factor;
          }
          return (price).toFixed(2)
        }
        else{
          return null; 
        }
      }
    },
    odd_selected: function (
      event_id,
      logro,
      categoria,
      market_type,
      market_id,
      home,
      away,
      event_date,
      market
    ) {
      this.$root.$emit('bet_loading');
      if (this.selected_odds[logro.id]) {
        this.$store.dispatch('live/unsuscribe_odd',logro.id);
        return;
      }
      const UTC = new Date().toISOString();
      const currentTime = Math.round(new Date(UTC).getTime() / 1000);
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      let timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
      let price = (logro.price*this.factorAjuste<1?1.00:logro.price*this.factorAjuste);
      let jbets_modified_odd = this.jbets_modified_odds.filter(odd_modified=>odd_modified.event_id == logro.id);
      if(jbets_modified_odd.length==1){
        price = jbets_modified_odd[0].blocked?1.00:price*jbets_modified_odd[0].factor<1?1.00:price*jbets_modified_odd[0].factor;
      }
      var odd_data = {
          "provider":"mode" in this.$props.game ? "client" : "betconstruct",
          "mode":"mode" in this.$props.game ? "external" : "socket",
          "game_id": event_id, //id del evento ok
          "event_id": logro.id,//id de la apuesta ok
          "price": price.toFixed(2), //cuota ok
          "reference_price":price.toFixed(2), //cuota ok
          "equipoa": home, //ok
          "equipob": away, //ok
          "market_id":market_id,// ID del marcado
          "referencia":market_type, //referencia de la apuesta
          "seleccion": this.odd_header(logro.name)+(logro.base ? ' ('+(logro.base)+')' : ""), //logro ok
          "seleccion_ref":logro,
          "start_ts": event_date, // fecha encuentro ok
          "islive": false, //ok
          "alias":this.$props.game.sport_alias, //ok
          "timestamp": currentTime, // fecha apuesta ok
          "blocked": Boolean(this.$props.game.is_blocked), //ok
          "blocked_reason":"",
          "deltaCombinada":1,
          "extra_data":{
            "apuesta": this.translate(categoria).toUpperCase(), //tipo apuesta ok
            "market": market, //nombre del mercado
            "pais": this.$props.game.country, //ok
            "liga": this.$props.game.liga,//ok
            "deporte": this.$props.game.sport_alias,//ok
            "format": this.rate_type == 'americano' ? 'american' : 'decimal',
            "current_state": this.$props.game.info.current_game_state, //ok
            "lang": this.$props.Skin.languages[this.$props.language].code,//ok
            "timezone": timezoneFormated,//ok
            "geo":{},
            "score":{
                "team1":null,
                "team2":null,
            },
            "text_info":"",
            "detallesItem":""
          }
      };
      this.$store.dispatch('live/suscribe_odd', odd_data);
    },
    game_state(state,sport){
      switch (sport) {
          case 1: //Futbol
              if(state == "set1"){
                  return "1a Parte"
              }
              else if(state == "set2"){
                  return "2a Parte"
              }
              else if(state == "set3"){
                  return "Tiempo Extra 1a Parte"
              }
              else if(state == "set4"){
                  return "Tiempo Extra 2a Parte"
              }
              else if(state == "Half Time"){
                  return "Medio Tiempo"
              }
              else if(state == "notstarted"){
                  return "Por comenzar"
              }
              break;
          case 3: //Baloncesto
              if(state == "set1"){
                  return "1er Cuarto"
              }
              else if(state == "set2"){
                  return "2do Cuarto"
              }
              else if(state == "set3"){
                  return "3er Cuarto"
              }
              else if(state == "set4"){
                  return "4to Cuarto"
              }
              else if(state == "timeout"){
                  return "Timeout"
              }
              else if(state == "notstarted"){
                  return "Por comenzar"
              }
              break;
          default:
          break;
      }
    },
    select_event(){
      this.$store.dispatch('setLobbyView','prematch2_evento');
      this.$root.$emit('show_prematch2_event',this.$props.game);
      this.$store.dispatch('prematch_2/unsuscribe_all_events');
      if(!("mode" in this.$props.game)){
        this.$store.dispatch('prematch_2/suscribe_event', this.$props.game.id);
      }
    },
    sizeControl(){
        this.screen_width=window.innerWidth;
    },
    hover1(id){
        document.getElementById(id).classList.add('hover');
    },
    hover2(id){
        document.getElementById(id).classList.remove('hover');
    },
    rate_conversion(rate){
      if(this.rate_type == 'americano'){
        let value = parseFloat(rate*this.factorAjuste<1?1.00:rate*this.factorAjuste);
        if(value >= 2){
            let result = (value-1)*100;
            return '+'+(Math.round(result));
        }
        else{
            let result = (-100)/(value-1);
            return Math.round(result);
        }
      }
      return (rate*this.factorAjuste<1?1.00:rate*this.factorAjuste).toFixed(2)
    }
  },  
  mounted() {
    window.addEventListener('resize', ()=>{this.sizeControl();});
  },
  created(){
     this.sizeControl();
  }
};
</script>
<style scoped>
  .hover {
    background-color: var(--fondoH) !important;
    color:var(--textH) !important;
  }
  .long_text2 {
    display: -webkit-box;
    max-width: 190px;
    height: 16px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
