<template>
    <div>
      <div class="p-2" :style="skin.my_account.menu_item_border">
        <h5>{{translate("Recharge")}}</h5>
      </div>
      <div v-if="response.result=='Ok'" class="alert alert-success  fade show" role="alert">
        {{ response.msg }}
      </div>
      <div v-if="response.result=='error'" class="alert alert-danger  fade show" role="alert">
        {{ response.msg }}
      </div>
      <div class="row p-2" v-if="!skin.my_account.user_recharge.custom_text.enabled">
          <form @submit.prevent="confirmRecharge()" class="col-12 col-lg-6 col-sm col-md">
            <div class="py-2">
              <label for="amount">{{translate("deposit_amount")}}</label>
              <input v-model="amount" type="number" step="1" class="form-control" placeholder="0" id="amount" required>
            </div>
            <div v-if="skin.my_account.user_recharge.payment_gateways.length > 1" class="py-2">
              <label for="gateway">{{translate("payment_gateway")}}</label>
              <select v-model="payment_gateway" class="form-select" id="gateway">
                <option value="select" disabled>{{translate("select_gateway")}}</option>
                <option v-for="(gateway, index) in skin.my_account.user_recharge.payment_gateways" :key="index" :value="gateway">{{ gateway.toUpperCase() }}</option>
              </select>
            </div>
            <div class="py-4">
              <button type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="recharge_inProcess">{{translate("confirm")}}</button>
            </div>
          </form>
      </div>
      <div v-else>
        <p v-html="skin.my_account.user_recharge.custom_text.text"></p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
      name:"user_recharge",
      props:["skin","language","translation"],
      data(){
        return{
          recharge_inProcess:false,
          amount:0,
          payment_gateway:"select",
          response:{result:"",msg:""}
        }
      },
      methods:{
        async confirmRecharge(){
            try {
              if(this.user.user_data.data.min_recharge?(this.amount<this.user.user_data.data.min_recharge?true:false):this.amount<10?true:false){
                  this.response.result="error";
                  this.response.msg=this.translate("min_recharge")+" "+(this.user.user_data.data.min_recharge??10)+" "+this.user.user_data.data.currency;
                  return;
              }
              this.recharge_inProcess = true;
              let provider = this.payment_gateway!="select"?this.payment_gateway:this.$props.skin.my_account.user_recharge.payment_gateways[0];
              let type = provider=="asaas"||provider=="2gobank"?"PIX":"";
              let data = {
                  "amount":this.amount,
                  "currency":this.user.user_data.data.currency,
                  "type":type,
                  "provider":provider.toUpperCase()
              }
              const response = await axios.post(this.user.domain+'/orden/create?access_token='+this.user.token+'&site='+this.user.site,data);
              if(response.data.status == 1){
                if(response.data.url.type=="modal"){
                  let modal = document.getElementById('bookingModalBtn');
                  modal.click();
                  this.$root.$emit('newAlert',{
                    label: this.translate("Recharge")+" "+this.amount+" "+this.user.user_data.data.currency,
                    message: '<img src="'+response.data.url.link+'" alt="qrCode" style="width:100%">'+
                  '<div><p style="font-size:small;">'+this.translate("pix_disclaimers")+'</p></div>',
                    barCode: ''
                  });
                  this.response.result="Ok";
                  this.response.msg=response.data.message;
                  this.amount="";
                }
                else if(response.data.url.type=="tab"){
                  this.response.result="Ok";
                  this.response.msg=response.data.message;
                  this.amount="";
                  window.open(response.data.url.link, '_blank').focus();
                }
              }
              else if(response.data.status == 0){
                  this.response.result="error";
                  this.response.msg=response.data.message;
              }
              this.recharge_inProcess = false;
            } catch (error) {
              this.recharge_inProcess = false;
              this.response.result="error";
              this.response.msg=error.message;
            }
        },
        translate(text) {
          if(text){
            if (this.$props.translation[text]) {
              return this.$props.translation[text][this.$props.language];
            }
            else{
              var new_text_serched = text.replace(/ /g, "_");
              if (this.$props.translation[new_text_serched]) {
                return this.$props.translation[new_text_serched][this.$props.language];
              }
            }
            return text;
          }
        },
      },
      computed:{
        user(){
          return this.$store.getters['getUserData'];
        },
      },
      watch:{
        amount(newVal){
            if(newVal!=""){
              this.response ={result:"",msg:""};
              this.amount = Math.round(newVal);
            }
        }
    },
  }
  </script>
  
  <style>
  
  </style>