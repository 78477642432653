<template>
  <div class="px-2" :style="Skin.menu_prematch.league_fondo+'cursor:pointer'" v-on:click="check_liga()">
    <div style="display:flex;width:100%">
      &nbsp;&nbsp;<span v-if="check" :id="liga" :class="Skin.menu_prematch.league_text" ><i class="fas fa-check"></i>&nbsp;</span>
      <span class="long_text" :style="Skin.menu_prematch.league_text">{{ liga.name  }}</span>
    </div>
    
  </div>
</template>
<script>
export default{
  name:"eventoLigaLive",
  data(){
    return {
      check:false,
    }
  },
  props:['liga','pais','deporte','Skin'],
  methods:{
    check_liga:function(){
      this.$store.dispatch('setLobbyView','prematch2_ligas');
      this.check=!this.check;
      let payload = {
        league:this.$props.liga.id,
        country:this.$props.liga.country_id,
        league_name:this.$props.liga.name,
        country_name:this.$props.liga.country,
        sport_id:this.$props.deporte.id,
        sport_name:this.$props.deporte.name
      };
      this.$store.dispatch('prematch_2/add_remove_liga',payload);
    },
  },
  mounted(){
    this.$root.$on('uncheck_liga_prematch2',(liga) => {
      if(this.$props.liga.id == liga){
        this.check=false;
      }
    });
  }
}
</script>
<style scoped>
  .long_text {
      display: -webkit-box;
      max-width: 250px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
</style>
