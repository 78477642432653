<template>
  <div class="m-1">
        <div>
            <navigation-bar
                v-bind:skin="skin"
                v-bind:translation="translation"
                v-bind:language="language"
                v-bind:navigation="navigation"
            ></navigation-bar>
        </div>
        <div class="my-1">
            <div :style="skin.live_league.header1" class="m-1 p-2">
                <span :style="skin.live_league.header1_text">{{ translate('match_fees') }}</span>
            </div>
        </div>
        <div 
            v-for="(liga, index) in ligas"
            v-bind:key="index"
        >  
            <div @click="hide_show_liga(liga.id)" style="cursor:pointer;" :style="skin.live_league.header2" class="m-1 p-1">
                    <span class="rounded-circle me-1" :class="'phoca-flag ' + country_flag(liga.country)" style="max-width:20px;height:20px"></span>
                    <span :style="skin.live_league.header2_text" class="mx-1">{{ liga.name }}</span>, 
                    <span :style="skin.live_league.header2_text" class="mx-1">{{ liga.country }}</span>
                    <span :style="skin.live_league.header2_text" class="mx-1 float-end"><i ref="arrow" :class="collapsed_leagues.includes(liga.id) ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i></span>
            </div>
            <div v-if="!collapsed_leagues.includes(liga.id)">
                <div v-if="liga.sport==1">
                    <div class="px-3">
                        <Futbol
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Futbol>
                    </div>
                </div>
                <div v-else>
                    <div class="px-3">
                        <Cricket
                            :Skin="skin"
                            :translation="translation"
                            :language="language"
                        ></Cricket>
                    </div>
                </div>
                <div class="mx-1">
                    <encuentro v-for="game in liga.events"
                        v-bind:game="game"
                        v-bind:sport="liga.sport"
                        v-bind:Skin="skin"
                        v-bind:key="game.id"
                        v-bind:language="language"
                        v-bind:translation="translation"
                    />
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import navigationBar from './navigation/navigation_bar.vue';
import encuentro from '../main_lobby/main_events_live/Encuentro.vue';
import Futbol from '../main_lobby/sport_tables/futbol.vue';
import Cricket from '../main_lobby/sport_tables/cricket.vue';
import Rugby from '../main_lobby/sport_tables/rugby.vue';
import Tenis from '../main_lobby/sport_tables/tenis.vue';
import Balonmano from '../main_lobby/sport_tables/balonmano.vue';
const flags = require('../../../assets/countries.json');
export default {
    name:"ligas_live",
    props:["skin","translation","language"],
    components:{
        navigationBar,
        encuentro,
        Futbol,
        Cricket,
        Rugby,
        Tenis,
        Balonmano
    }, 
    data(){
        return{
            collapsed_leagues:[]
        }
    },
    computed:{
        ligas_array(){
            let data = this.$store.getters['live/getLigasArray'];
            if([...data].length<1){
                this.$store.dispatch('setLobbyView','main');
            }
            return data;
        },
        ligas(){
            let ligas = [];
            let data = this.$store.getters['live/getSports'];
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegions;
            let words_banned = this.$store.getters['getConfig'].SkinConfig.main.hideWords;
            let ligas_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitions;
            this.ligas_array.forEach(target_league=>{
                Object.keys(data).forEach(sport=>{
                    Object.keys(data[sport].region).forEach(pais=>{
                        if(data[sport].region[pais] ? (target_league.country == data[sport].region[pais].id ? true : false) : false){
                            if(!regions_banned.includes(data[sport].region[pais].alias)){
                                if(data[sport].region[pais].competition){
                                    Object.keys(data[sport].region[pais].competition).forEach(liga=>{
                                        if(target_league.league == data[sport].region[pais].competition[liga].id){
                                            data[sport].region[pais].competition[liga].sport = data[sport].id;
                                            data[sport].region[pais].competition[liga].country = data[sport].region[pais].name;
                                            data[sport].region[pais].competition[liga].events = [];
                                            ligas.push(data[sport].region[pais].competition[liga]);
                                        }
                                    });
                                }
                            }
                        }
                    });
                });
            })
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                if((liga ? (liga.game ? true : false) : false)){
                    Object.keys(liga.game).forEach(element=>{
                        if(liga.game[element]){
                            if(liga.game[element] && liga.game[element].info && !liga.game[element].favorite){
                                let banned = false;
                                words_banned.forEach(word => {
                                    if(liga.game[element].team1_name){
                                        if(liga.game[element].team1_name.includes(word)){
                                            banned = true;
                                        }
                                    }
                                    if(liga.game[element].team2_name){
                                        if(liga.game[element].team2_name.includes(word)){
                                            banned = true;
                                        }
                                    }            
                                });
                                ligas_banned.forEach(word => {
                                    if(liga.name){
                                        if(liga.name.includes(word)){
                                            banned = true;
                                        }
                                    }           
                                });
                                if(!banned && liga.game[element].market){
                                    liga.game[element]['sport'] = this.$props.deporte;
                                    if(Object.keys(liga.game[element].market).length>0 && liga.game[element].info.current_game_state != "notstarted"){
                                        liga.game[element].liga = liga.name;
                                        liga.game[element].sport = 1;
                                        liga.game[element].country = liga.country;
                                        liga.events.push(liga.game[element]);
                                    }
                                }
                            }  
                        } 
                    });
                }
            });
            return ligas; 
        },
        navigation(){
            let links = [];
            let sports = {};
            let countries = {};
            let leagues = {};
            let data = this.$store.getters['live/getLigasArray'];
            data.forEach(liga => {
                sports[liga.sport_id] = liga.sport_name.toUpperCase();
                countries[liga.country] = liga.country_name.toUpperCase();
                leagues[liga.league] = liga.league_name.toUpperCase();
            });
            if(Object.keys(leagues).length == 1 && Object.keys(sports).length == 1 && Object.keys(countries).length == 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:this.translate("competitions"),
                    link:""
                });
                links.push({
                    name:countries[Object.keys(countries)[0]],
                    link:""
                });
                links.push({
                    name:leagues[Object.keys(leagues)[0]],
                    link:""
                });
            }
            else if(Object.keys(sports).length > 1){
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
            }
            else if(Object.keys(countries).length > 1 && Object.keys(countries).length < 4 && Object.keys(sports).length == 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
                let countries_str = []
                Object.keys(countries).forEach(country => {
                    countries_str.push(countries[country]);
                });
                links.push({
                    name:countries_str.join(" - "),
                    link:""
                });
            }
            else if(Object.keys(countries).length == 1 && Object.keys(sports).length == 1 && Object.keys(leagues).length > 1){
                links.push({
                    name:sports[Object.keys(sports)[0]],
                    link:""
                });
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
                links.push({
                    name:countries[Object.keys(countries)[0]],
                    link:""
                });
            }
            else{
                links.push({
                    name:"COMPETICIONES",
                    link:""
                });
            }
            return links;
        }
    },
    methods:{
        translate(text){
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        country_flag(name) {
            let flag = []
            flags.countries.forEach(country => {
                if(country.name_es == name){
                    flag.push(country.code.toLowerCase());
                }
            });
            return flag;
        },
        hide_show_liga(liga){
            if(!this.collapsed_leagues.includes(liga)){
                this.collapsed_leagues.push(liga);
            }
            else{
                this.collapsed_leagues.splice(this.collapsed_leagues.indexOf(liga),1);
            }
        }
    },
}
</script>

<style>

</style>