import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/front_ecuabets/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: main
  },
  {
    path: '/live_ecuabets',
    name: 'LiveMainEcuabets',
    component: ()=> import(/* webpackChunkName:"live" */'../views/front_ecuabets/Live.vue')
  },
  {
    path: '/live_ecuabets',
    name: 'Live_ecuabets',
    component: ()=> import(/* webpackChunkName:"live" */'../views/front_ecuabets/Live.vue')
  },
  {
    path: '/main_ecuabets',
    name: 'MainEcuabets',
    component: ()=> import(/* webpackChunkName:"live" */'../views/front_ecuabets/Main.vue')
  },
  {
    path: '/main_ecuabets',
    name: 'Main_ecuabets',
    component: ()=> import(/* webpackChunkName:"live" */'../views/front_ecuabets/Main.vue')
  },
]

const router = new VueRouter({
  routes,
})

export default router
