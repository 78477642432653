<template>
<div>
    <div v-if="menu_visible" :style="skin.live_lobby_main.bar">
       <ul class="nav p-1" :style="nav_active">
            <li v-for="deporte in deportes.main"
                v-bind:key="deporte.id"
                :id="deporte.id+'live'"
                style="cursor:pointer"
                class="nav-item nav-live" :class="deporte.id == 1 ? 'nav_active': ''"
                @click="select_sport(deporte.id)">
                <span class="m-2">{{ deporte.name }}</span>
            </li>
            <li class="nav-item dropdown">
                <span class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">{{translate("others")}}</span>
                <ul class="dropdown-menu" :style="skin.live_lobby_main.drop_menu">
                    <li v-for="deporte in deportes.other"
                        v-bind:key="deporte.id"
                        @click="select_sport(deporte.id)"
                        :id="deporte.id+'live'"
                        style="cursor:pointer"
                        class="nav-item nav-live">
                        <span :style="skin.text1" class="m-2">{{ deporte.name }}</span>
                    </li>
                </ul>
            </li>
        </ul> 
    </div>
    <div>
        <div v-if="screen_width>skin.break_point">
            <div v-if="selected_sport==1">
                <div class="px-3">
                    <Futbol
                        :Skin="skin"
                        :translation="translation"
                        :language="language"
                    ></Futbol>
                </div>
            </div>
            <div v-else>
                <div class="px-3">
                    <Cricket
                        :Skin="skin"
                        :translation="translation"
                        :language="language"
                    ></Cricket>
                </div>
            </div>
        </div>
        <div class="mx-1">
            <encuentro v-for="game in events"
                v-bind:game="game"
                v-bind:sport="selected_sport"
                v-bind:Skin="skin"
                v-bind:key="game.id"
                v-bind:language="language"
                v-bind:translation="translation"
            />
        </div>
        <div class="text-center p-3">
            <router-link
                :to="this.$root.user_token == '' ? { name: 'LiveMainEcuabets',query: { token: this.$root.user_token+this.$root.site_id}} : { name: 'Live_ecuabets' ,  query: { token: this.$root.user_token+this.$root.site_id} }"
                >
                <span :style="skin.live_lobby_main.more_events" style="cursor:pointer" class="p-2">{{translate("see_live_events")}}</span>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
import encuentro from '../main_events_live/Encuentro.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
export default {
    name:"main_live",
    props:['skin','translation','language','menu_visible'],
    components:{
        encuentro,
        Futbol,
        Cricket,
        Rugby,
        Tenis,
        Balonmano
    },
    data(){
        return{
            selected_sport:1,
            screen_width:0,
        }
    },
    computed:{
        deportes(){
        var main = [1,11,2,3,4];
        var list={
            main:[],
            other:[]
        };
        let data = this.$store.getters['live/getSports'];
            Object.keys(data).forEach(sport=>{
                if(main.includes(data[sport].id)){
                    list.main.push({
                        name:data[sport].name,
                        id:data[sport].id
                    })
                }
                else{
                    list.other.push({
                        name:data[sport].name,
                        id:data[sport].id
                    })
                }
            });
        return list;
        },
        events(){
            let events = [];
            let ligas = [];
            let count = 0;
            let data = this.$store.getters['live/getSports'];
            let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegions;
            let words_banned = this.$store.getters['getConfig'].SkinConfig.main.hideWords;
            let ligas_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitions;
            Object.keys(data).forEach(sport=>{
                if(data[sport].id == this.selected_sport){
                    Object.keys(data[sport].region).forEach(pais=>{
                    if(data[sport].region[pais]){
                        if(!regions_banned.includes(data[sport].region[pais].alias)){
                            if(data[sport].region[pais].competition){
                                Object.keys(data[sport].region[pais].competition).forEach(liga=>{
                                    data[sport].region[pais].competition[liga].sport = data[sport].id;
                                    data[sport].region[pais].competition[liga].sport_alias = data[sport].name;
                                    data[sport].region[pais].competition[liga].country = data[sport].region[pais].name;
                                    data[sport].region[pais].competition[liga].country_alias = data[sport].region[pais].alias;
                                    ligas.push(data[sport].region[pais].competition[liga]);
                                });
                            }
                        }
                    }
                });
                }
                
            });
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                if((liga ? (liga.game ? true : false) : false)){
                    Object.keys(liga.game).forEach(element=>{
                        if(liga.game[element]){
                            if(liga.game[element] && liga.game[element].info && !liga.game[element].favorite){
                                let banned = false;
                                words_banned.forEach(word => {
                                    if(liga.game[element].team1_name){
                                        if(liga.game[element].team1_name.includes(word)){
                                            banned = true;
                                        }
                                    }
                                    if(liga.game[element].team2_name){
                                        if(liga.game[element].team2_name.includes(word)){
                                            banned = true;
                                        }
                                    }            
                                });
                                ligas_banned.forEach(word => {
                                    if(liga.name){
                                        if(liga.name.includes(word)){
                                            banned = true;
                                        }
                                    }           
                                });
                                if(!banned && liga.game[element].market){
                                    liga.game[element]['sport'] = this.$props.deporte;
                                    if(count<11 && Object.keys(liga.game[element].market).length>0 && liga.game[element].info.current_game_state != "notstarted" && !liga.game[element].is_blocked){
                                        liga.game[element].liga = liga.name;
                                        liga.game[element].sport = liga.sport;
                                        liga.game[element].country = liga.country;
                                        liga.game[element].country_alias = liga.country_alias;
                                        liga.game[element].sport_alias = liga.sport_alias;
                                        events.push(liga.game[element]);
                                        count++;
                                    }
                                }
                            }  
                        } 
                    });
                }
            });
            return events; 
        },
        nav_active(){
            return {
                '--nav-active-color':this.$props.skin.live_lobby_main.nav_active_color,
                '--nav-active-bar':this.$props.skin.live_lobby_main.nav_active_bar
            };
        }
    },
    methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        select_sport(id){
            let items = document.getElementsByClassName("nav-live");
            [...items].forEach(item=>{
                item.classList.remove('nav_active');
            });
            if(document.getElementById(id+'live')){
                 document.getElementById(id+'live').classList.add('nav_active');
            }
            this.selected_sport = id;
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
    created(){
        this.sizeControl();
    }
}
</script>

<style scoped>
  .nav_active{
      color:var(--nav-active-color);
      border-bottom: var(--nav-active-bar);
  }
</style>