<template>
    <div>
      <div class="modal fade" id="registerModalToggle" aria-hidden="true" aria-labelledby="registerModalToggleLabel" tabindex="-1" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" :style="skin.my_account.main_background">
                  <div class="modal-header" style="border-bottom: none;">
                      <button :style="skin.my_account.close_btn" style="background: none;" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                  </div>
                  <div class="modal-body">
                      <div class="text-center p-2">
                          <img v-if="skin.header_desktop.logo != ''" :src="skin.header_desktop.logo" :width="skin.header_desktop.login_logo_width" alt=""><br><br>
                          <span>{{translate('new_user')}}</span><br>
                          <span>{{translate('register_easy')}}</span><br><br>
                          <form @submit.prevent="nextStep()">
                              <div class="mb-3">
                                  <label for="registerEmail">{{translate('email')}}</label>
                                  <input v-model="email" type="text" id="registerEmail" class="form-control" :placeholder="translate('enter_email')" aria-label="registerEmail" aria-describedby="registerEmail" required>
                              </div>
                              <div class="mb-3">
                                  <label for="RegisterPassword">{{translate('password')}}</label>
                                  <input v-model="password" id="RegisterPassword" type="password" class="form-control" :placeholder="translate('enter_password')" aria-label="RegisterPassword" aria-describedby="RegisterPassword" required>
                              </div>
                              <button type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{translate('confirm')}}</button>
                              <button data-bs-target="#registerModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal" id="nextForm" style="display:none;"></button>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal fade" id="registerModalToggle2" aria-hidden="true" aria-labelledby="registerModalToggleLabel2" tabindex="-1" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" :style="skin.my_account.main_background">
                  <div class="modal-header" style="border-bottom: none;">
                      <h5>{{translate('end_registration')}}</h5>
                      <button :style="skin.my_account.close_btn" style="background: none;" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                  </div>
                  <div class="modal-body">
                      <div>
                          <div v-if="status.result!='OK'" class="alert alert-danger" role="alert">
                              {{ status.msg }}
                          </div>
                          <form @submit.prevent="register()">
                              <div v-if="skin.register_fields.idn" class="mb-3">
                                  <label for="registerId">{{translate('idn')}}</label>&nbsp;
                                  <span v-if="cpf_loading" class="spinner-border" :style="skin.loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </span>
                                  <span v-if="skin.register_fields.cpf_verification && cpf_message!=''" v-html="cpf_message" style="color: #842029;background-color: #f8d7da;border-color: #f5c2c7;border: 1px solid transparent;border-radius: 0.25rem;" class="px-2"></span>
                                  <input v-model="idn" @change="verify_cpf()" type="number" id="registerId" class="form-control" placeholder="Ingrese aquí" aria-label="registerId" aria-describedby="registerId" required>
                              </div>
                              <div v-if="skin.register_fields.name" class="mb-3">
                                  <label for="registerFirstName">{{translate('name')}}</label>
                                  <input v-model="first_name" type="text" id="registerFirstName" class="form-control" :placeholder="translate('enter_here')" aria-label="registerFirstName" aria-describedby="registerFirstName" :readonly="skin.register_fields.cpf_verification" required>
                              </div>
                              <div v-if="skin.register_fields.last_name" class="mb-3">
                                  <label for="registerLastName">{{translate('last_name')}}</label>
                                  <input v-model="last_name" type="text" id="registerLastName" class="form-control" :placeholder="translate('enter_here')" aria-label="registerLastName" aria-describedby="registerLastName" required>
                              </div>
                              <div v-if="skin.register_fields.birthday" class="mb-3">
                                  <label for="registerBirthday">{{translate('birthday')}}</label>
                                  <input v-model="birthday" type="text" id="registerBirthday" class="form-control" placeholder="Dia/Mes/Año" aria-label="registerBirthday" aria-describedby="registerBirthday" readonly>
                              </div>
                              <div v-if="skin.register_fields.cpf_verification" class="mb-3">
                                  <label for="birthday">{{translate('birthday')}}</label>
                                  <input v-model="birthday" type="text" id="birthday" class="form-control" placeholder="Dia/Mês/Ano" aria-label="birthday" aria-describedby="birthday" readonly>
                              </div>
                              <div v-if="skin.register_fields.country" class="mb-3">
                                  <label for="registerCountry">{{translate('country')}}</label>
                                  <select class="form-control" name="country" v-model="country_code" id="registerCountry" required>
                                      <option v-for="(country,index) in countries()" :key="index" :value="country.code">{{ language=="es"?country.name_es : country.name_en }}</option>
                                  </select>
                              </div>
                              <div v-if="skin.register_fields.address" class="mb-3">
                                  <label for="registerAddress">{{translate('address')}}</label>
                                  <input v-model="address" type="text" id="registerAddress" class="form-control" :placeholder="translate('enter_here')" aria-label="registerAddress" aria-describedby="registerAddress" required>
                              </div>
                              <div v-if="skin.register_fields.currency" class="mb-3">
                                  <label for="registerCurrency">{{translate('currency')}}</label>
                                  <select class="form-control" name="currency" v-model="currency_name" id="registerCurrency" required>
                                      <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                                  </select>
                              </div>
                              <div v-if="skin.register_fields.phone_number" class="mb-3">
                                  <label for="registerCurrency">{{translate('phone')}}</label>&nbsp;&nbsp;<span v-if="!phone_code_checked" style="color: #842029;background-color: #f8d7da;border-color: #f5c2c7;border: 1px solid transparent;border-radius: 0.25rem;" class="px-2">{{translate('incorrect_code')}}</span>
                                  <div class="d-flex">
                                      <div class="input-group me-1" style="width:40%">
                                          <span class="input-group-text" id="basic-addon1">+</span>
                                          <input :style="!phone_code_checked?'border-color:red;':''" type="number" v-model="phone_code" class="form-control" :placeholder="translate('country_code')" required :disabled="skin.register_fields.default_phone_code!=0">
                                      </div>
                                      <div style="width:60%;">
                                          <input v-model="phone" type="number" class="form-control" :placeholder="translate('enter_number')" required>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-check mb-3">
                                  <input class="form-check-input" type="checkbox" value="" v-model="agreement">
                                  <label class="form-check-label" for="flexCheckDefault">
                                      {{translate('aggrement')}} <span @click="terms()" class="link-primary">{{ translate('see_aggrement') }}</span>
                                  </label>
                              </div>
                              <div class="text-center">
                                <span v-if="register_inProcess" class="spinner-border" :style="skin.loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                              </div>
                              <button type="submit" :style="skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="register_inProcess">{{translate('confirm')}}</button>
                              <button :style="skin.qbet.booking_btn" class="btn btn-primary" style="width:100%;" data-bs-target="#registerModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">{{translate('back')}}</button>
                          </form>
                          <button data-bs-target="#registerModalToggle3" data-bs-toggle="modal" data-bs-dismiss="modal" style="display:none;"  id="registerSuccessfull"></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal fade" id="registerModalToggle3" aria-hidden="true" aria-labelledby="registerModalToggleLabel3" tabindex="-1" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" :style="skin.my_account.main_background">
                  <div class="modal-header" style="border-bottom: none;">
                      <button :style="skin.my_account.close_btn" style="background: none;" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                  </div>
                  <div class="modal-body">
                      <div class="text-center p-2">
                          <span style="font-size:64px;color:green;"><i class="fas fa-check-circle"></i></span><br>
                          <span>{{translate('register_success')}}</span><br>
                          <span>{{translate('can_login')}}</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <button data-bs-target="#phoneVericationModal" data-bs-toggle="modal" data-bs-dismiss="modal" style="display:none;"  id="phoneVericationModalBtn"></button>
      <div class="modal fade" id="phoneVericationModal" aria-hidden="true" aria-labelledby="phoneVericationModal" tabindex="-1" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" :style="skin.my_account.main_background">
                  <div class="modal-header" style="border-bottom: none;">
                        {{translate('phone_verification')}}
                      <button :style="skin.my_account.close_btn" style="background: none;" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                  </div>
                  <div class="modal-body">
                        <div class="text-center">
                            <span v-if="phone_verification.loading" class="spinner-border" :style="skin.loader" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </span>
                            <div v-else :class="'alert alert-'+phone_verification.message.type" role="alert">
                                {{ phone_verification.message.message }}
                            </div>
                        </div>
                        <form v-if="!phone_verification.code_checked" class="text-center p-2" @submit.prevent="verify_phone()">
                            <input v-model="phone_verification.code" type="number" step="1" required><br><br>
                            <button type="submit" :style="skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{translate('confirm')}}</button>
                        </form>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const countries = require('../../../assets/countries.json');
  export default {
      name:"register",
      props:['skin','translation','language'],
      data(){
          return {
              selected_country:"",
              email:"",
              password:"",
              first_name:"",
              last_name:"",
              idn:"",
              cpf_message:"",
              cpf_verified:true,
              cpf_loading:false,
              birthday:"",
              address:"",
              gender:"",
              country_code:"",
              phone_code:"",
              phone_code_checked:true,
              phone_verification:{
                code_checked:false,
                code:"",
                otp_id:"",
                loading:false,
                message:{
                    type:"",
                    message:""
                }
              },
              phone:"",
              currency_name:"",
              status:{result:"OK",msg:""},
              agreement:false,
              register_inProcess:false
          }
      },
      computed:{
          user(){
            let data = this.$store.getters['getUserData'];
            return data;
          },
          currencies(){
              return this.$store.getters['getConfig'].SkinConfig.main.availableCurrencies;
          }
      },
      methods:{
        terms(){
            let url = "";
            let lang = this.$props.language=='es'?'spa':(this.$props.language=='en'?'eng':(this.$props.language=='pt'?'pt-br':'spa'));
            if(this.user.prefix=="external"){
                url = "https://docs.sistemasw3.com/terms/"+lang+"/?site="+this.user.site.toUpperCase()+"&link="+this.user.site;
            }
            else{
                let domain_formated = this.user.domain.split('//')[1];
                let domain_name1 = domain_formated.split('.')[1].toUpperCase();
                let domain_name2 = domain_formated.split('.')[1]+'.'+domain_formated.split('.')[2];
                url = "https://docs.sistemasw3.com/terms/"+lang+"/?site="+domain_name1+"&link="+domain_name2;
            }
            
            window.open(url, '_blank').focus();
        },
          async register(){
              this.register_inProcess = true;
              let data = {
                  "user_info":{
                      "site_id":this.$store.getters['getConfig'].main.site_id,
                      "language":this.$props.skin.languages[this.$props.language].code,
                      "lang_code":this.$props.language,
                      "ignore_username":"1",
                      "first_name":this.first_name,
                      "last_name":this.last_name,
                      "address":this.address,
                      "doc_number":this.idn.replace(".",""),
                      "birthday":this.birthday,
                      "gender":"M",
                      "password":this.password,
                      "email":this.email,
                      "country_code":this.country_code.toUpperCase(),
                      "phone_code":this.phone_code,
                      "phone":this.phone.replace(".",""),
                      "currency_name":this.currency_name
                  }
              }
              if(this.agreement && this.cpf_verified){
                if(this.$props.skin.register_fields.phone_verification && !this.phone_verification.code_checked){
                    let text_phone_length_check = this.phone.toString().length < this.$props.skin.register_fields.phone_min_length ? false : true;
                    if(text_phone_length_check){
                        this.send_phone_code(1);
                    }
                    else{
                        this.status.result = "failed";
                        this.status.msg = this.translate("phone_min_length_warning") + " " + this.$props.skin.register_fields.phone_min_length;
                        this.register_inProcess = false;
                        return;
                    }
                }
                else{
                    const response_register = await axios.post(this.user.domain+'/swarm/remote/registro',data);
                    if(response_register.data.result == 'OK'){
                        document.getElementById('registerSuccessfull').click();
                        this.register_inProcess = false;
                    }
                    else{
                        this.status.result = response_register.data.result
                        this.status.msg = response_register.data.msg
                        this.register_inProcess = false;
                    }
                }
              }
              else{
                this.register_inProcess = false;
              }
          },
          async verify_cpf(){
            if(this.$props.skin.register_fields.cpf_verification && this.idn!=""){
                this.cpf_loading=true;
                const response = await axios.get('https://admin.eujogo.bet/deposit/bs2/checkcpf?cpf='+this.idn);
                if(response.data.data.nascimento){
                    const date = response.data.data.nascimento;
                    this.first_name = response.data.data.nome;
                    this.birthday=date.slice(0,2)+"/"+date.slice(2,4)+"/"+date.slice(4,8);
                    this.cpf_verified = true;
                    this.cpf_loading=false;
                    this.cpf_message = "";
                }
                else{
                    this.cpf_message = response.data.data.mensagem;
                    this.birthday="";
                    this.cpf_verified = false;
                    this.cpf_loading=false;
                }
            }
          },
          async send_phone_code(flag){
            if(flag==2){
                this.phone_verification.loading = true;
                let data_phone = {"command":"verify_phone","params":{"phone":this.user.user_data.data.phone.replace("+","").substring(2),"code":this.user.user_data.data.phone.replace("+","").slice(0, 2)}}
                const response_phone_verification = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data_phone);
                document.getElementById('phoneVericationModalBtn').click();
                this.phone_verification.message.message = response_phone_verification.data.message;
                this.phone_verification.message.type = response_phone_verification.data.success?"success":"danger";
                this.phone_verification.loading = false;
                return "success";
            }
            else{
                this.phone_verification.loading = true;
                let phone_data = {
                    "phone_code":this.phone_code,
                    "phone":this.phone.replace(".",""),
                    "lang":this.$props.language,
                    "provider":this.$props.skin.register_fields.phone_verification_provider??'sw3'
                }
                let phone_response = await axios.post("https://jbets.online:3012/api/generate_otp",phone_data);
                if(phone_response.data.otp_id && (phone_response.data.status? (phone_response.data.status == "OPEN"? true:false):false)){
                    this.phone_verification.otp_id = phone_response.data.otp_id;
                    document.getElementById('phoneVericationModalBtn').click();
                    this.phone_verification.message.message = phone_response.data.message;
                    this.phone_verification.message.type = "success";
                    this.phone_verification.loading = false;
                    this.register_inProcess = false;
                    return "success";
                }
                this.register_inProcess = false;
                return "failed";
            }
          },
          async verify_phone(){
            if(this.phone_verification.otp_id==""){
                this.phone_verification.loading = true;
                let data_phone = {"command":"check_verify_phone","params":{"code":this.phone_verification.code}}
                const response_phone_verification = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data_phone);
                if(response_phone_verification.data.success){
                    this.phone_verification.message.message = response_phone_verification.data.message;
                    this.phone_verification.message.type = "success";
                    this.phone_verification.code_checked = true;
                    this.phone_verification.loading = false;
                }
                else{
                    this.phone_verification.message.message = response_phone_verification.data.message;
                    this.phone_verification.message.type = "danger";
                    this.phone_verification.code_checked = false;
                    this.phone_verification.loading = false;
                }
            }
            else{
                this.phone_verification.loading = true;
                let data_phone = {
                    "otp_id": this.phone_verification.otp_id,
                    "code": this.phone_verification.code,
                    "lang":this.$props.language,
                    "phone":this.phone.replace(".",""),
                    "provider":this.$props.skin.register_fields.phone_verification_provider??'sw3'
                }
                let phone_response = await axios.post("https://jbets.online:3012/api/verify_otp",data_phone);
                if(phone_response.data.status){
                    this.phone_verification.message.message = phone_response.data.message;
                    this.phone_verification.message.type = "success";
                    this.phone_verification.code_checked = true;
                    this.phone_verification.loading = false;
                    this.phone_verification.otp_id="";
                    this.register();
                }
                else{
                    this.phone_verification.message.message = phone_response.data.message;
                    this.phone_verification.message.type = "danger";
                    this.phone_verification.code_checked = false;
                    this.phone_verification.loading = false;
                }
            }
          },
          nextStep(){
              document.getElementById('nextForm').click();
          },
          countries(){
              let country_list = [];
              countries.countries.forEach(country => {
                  country_list.push(country);
              })
              return country_list.sort((a, b) => (a[this.$props.language=="es"?"name_es":"name_en"] > b[this.$props.language=="es"?"name_es":"name_en"]) ? 1 : -1)
          },
          translate(text) {
              if(text){
                  if (this.$props.translation[text]) {
                      return this.$props.translation[text][this.$props.language];
                  }
                  else{
                      var new_text_serched = text.replace(/ /g, "_");
                      if (this.$props.translation[new_text_serched]) {
                          return this.$props.translation[new_text_serched][this.$props.language];
                      }
                  }
                  return text;
              }
          },   
      },
      watch:{
          phone_code(newVal){
              let code = "+"+newVal;
              let checkCode = false;
              this.countries().forEach(country=>{
                  if(code == country.dial_code){
                      checkCode = true;
                  }
              })
              this.phone_code_checked = checkCode;
          }
      },
      mounted(){
        if(this.$props.skin.register_fields.default_country!=""){
            this.country_code=this.$props.skin.register_fields.default_country;
        }
        if(this.$props.skin.register_fields.default_phone_code!=0){
            this.phone_code=this.$props.skin.register_fields.default_phone_code;
        }
        if(this.$props.skin.register_fields.default_currency!=""){
            this.currency_name=this.$props.skin.register_fields.default_currency;
        }
        this.$root.$on('send_phone_code', () => {
            this.send_phone_code(2);
        });
      }
  }
  </script>
  
  <style>
  
  </style>