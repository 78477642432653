<template>
  <div class="d-flex p-0" :style="skin.navigation_bar.frame">
        <div v-if="skin.prematch" @click="start" style="cursor:pointer;" :style="skin.navigation_bar.fondo" class="py-2 pb-2 px-3 pe-3">
            <router-link :to="this.$root.user_token == '' ? { name: 'MainEcuabets',query: { token: this.$root.user_token+this.$root.site_id}} : { name: 'Main_ecuabets' ,  query: { token: this.$root.user_token+this.$root.site_id} }"><span :style="skin.navigation_bar.arrow"><i class="fas fa-arrow-left"></i></span></router-link>
        </div>
        <div @click="start" class="py-2 pb-2 px-3 pe-0">
            <router-link v-if="skin.prematch!=null?skin.prematch:true" :to="this.$root.user_token == '' ? { name: 'MainEcuabets',query: { token: this.$root.user_token+this.$root.site_id}} : { name: 'Main_ecuabets' ,  query: { token: this.$root.user_token+this.$root.site_id} }"><span :style="skin.navigation_bar.text">{{translate('home')}}</span></router-link>
            <span v-else>{{ translate('live') }}</span>
            &nbsp;&nbsp; 
        </div>
        <div 
            v-for="(link,index) in navigation"
            v-bind:key="index"
            class="py-2 pb-2 px-0 pe-1"
        >
            <span :style="skin.navigation_bar.text"><i class="fas fa-caret-right"></i></span>&nbsp;&nbsp; 
            <span :style="skin.navigation_bar.text">{{ link.name }}</span>
        </div>
  </div>
</template>

<script>
export default {
    name: "navigation_bar",
    props:["skin","translation","language","navigation"],
    data(){
        return {

        }
    },
    methods:{
        translate(text) {
            if(text){
                if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
                }
                else{
                var new_text_serched = text.replace(/ /g, "_");
                if (this.$props.translation[new_text_serched]) {
                    return this.$props.translation[new_text_serched][this.$props.language];
                }
                }
                return text;
            }
        },
        start(){
            let data = this.$store.getters['live/getLigasArray'];
            data.forEach(liga => {
                this.$root.$emit('uncheck_liga_live',liga.league);
            });
            let check_countries = document.getElementsByClassName('check_country_live');
            [...check_countries].forEach(item=>{
                if(item.checked){
                    item.checked = false;
                }
            });
            this.$store.dispatch('live/remove_ligas');
            this.$store.dispatch('setLobbyView','main');
        }
    }
}
</script>

<style>

</style>