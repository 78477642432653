<template>
    <div>
        <div v-if="skin.banners.main_banner_enabled">
            <main-banner
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
            ></main-banner>
        </div>
        <div v-if="!target_event_loaded" :style="screen_width<skin.break_point?'':'width:100%; height:90vh'" class="text-center py-5">
            <div class="spinner-border" :style="skin.loader" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <menu-sports
        v-if="render"
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        ></menu-sports>
        <main-events v-if="skin.live != null ? skin.live :true"
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        ></main-events>
        <main-search v-if="skin.search_component_position_mobile=='lobby' && screen_width<skin.break_point"
        v-bind:skin="skin"
        v-bind:language="language"
        v-bind:translation="translation"
        />
        <div v-if="skin.external_events" class="m-1 mb-2" :style="skin.prematch_lobby.frame" style="overflow:hidden">
            <main-external
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:menu_visible="true"
            >
            </main-external>
        </div>
         <div v-if="skin.qBet_config.teaser" class="m-1 mb-2" :style="skin.prematch_lobby.frame" style="overflow:hidden">
            <teaser
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
            >
            </teaser>
        </div>
        <div class="m-1 mb-2" :style="skin.prematch_lobby.frame" style="overflow:hidden">
            <div class="p-2" :style="skin.prematch_lobby.bar">{{translate("next")}}</div>
            <main-prematch
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:time="time"
                v-bind:translation="translation"
                v-bind:menu_visible="true"
                v-if="prematchTarget==1"
            ></main-prematch>
            <main-prematch2
                v-else
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:menu_visible="true"
            >
            </main-prematch2>
        </div>
        <div v-if="skin.banners.lobby_horizontal.enabled" :style="screen_width<skin.break_point?'position:relative;height:80px':'position:relative;height:150px'" style="overflow:hidden">
            <!-- Carrousel Horizontal -->
            <iframe :src="skin.banners.lobby_horizontal.url" frameborder="0"></iframe>
        </div>
        <div v-if="skin.live != null ? skin.live :true" class="m-1 mb-2" :style="skin.live_lobby.frame" style="overflow:hidden">
            <div class="p-2" :style="skin.live_lobby.bar">{{translate("live_now")}}</div>
            <main-live
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:menu_visible="true"
            ></main-live>
        </div>
    </div>
</template>

<script>
import menuSports from './main_data/menu_sports.vue';
import mainEvents from './main_data/main_events.vue';
import mainPrematch from './main_data/main_prematch.vue';
import mainPrematch2 from './main_data/main_prematch2.vue';
import mainExternal from './main_data/main_external.vue';
import teaser from './main_data/teaser.vue';
import mainLive from './main_data/main_live.vue';
import mainBanner from './main_banner/main_banner.vue';
import mainSearch from './main_data/main_search.vue';
export default {
    name:"lobby",
    components:{
        menuSports,
        mainEvents,
        mainPrematch,
        mainLive,
        mainPrematch2,
        mainExternal,
        teaser,
        mainBanner,
        mainSearch
    },
    props:["skin","translation","language","time"],
    data(){
        return {
            message: "",
            sports_loaded: false,
            screen_width:0,
            follow_id:"",
            debug:false,
            prematch_ligas_view:false,
            live_view:false
        }
    },
    computed:{
        target_event_loaded(){
            return this.$store.getters['prematch_2/getPrematchLiveData'];
        },
        prematchTarget(){
            return this.$store.getters['getPrematchTarget'];
        },
        render(){
            return this.$store.getters['getRenderSportsMenu'];
        }
    },
    methods:{
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        }
    },
    mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
    created(){
        this.sizeControl();
    },
}
</script>

<style>

</style>