<template>
  <div class="m-1" style="line-height: normal;overflow:hidden; position:relative;" :style="skin.qbet.body">
    <!-- Button trigger modal -->
    <button type="button" id="bookingModalBtn" class="btn btn-primary" data-bs-toggle="modal"
      data-bs-target="#bookingModal" style="display:none;">
    </button>
    <div class="p-2" :style="skin.qbet.header" style="position:relative;">
      <div class="d-flex nav">
        <div id="tab_coupon" :style="user.token != '' ? 'width:100%;' : 'width:100%;'" style="width:45%;cursor:pointer;"
          class="text-center nav-item" data-bs-toggle="pill" data-bs-target="#pills-ticket" role="tab"
          aria-controls="pills-ticket" aria-selected="true">
          {{ translate("coupon") }}
          <span class="p-1 px-2 pe-2" style="border-radius: 100%;border:1px solid #434343;">{{
            Object.keys(betsList).length }}</span>
          <span style="position:absolute;right:10px;cursor:pointer;" data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContentQbet" aria-controls="navbarToggleExternalContentQbet"
            aria-expanded="false" aria-label="Toggle navigation"><i class="fas fa-cog"></i></span>
        </div>
        <!-- <div v-if="user.token!=''" @click="get_tracking" style="width:45%;cursor:pointer;" class="text-center nav-item" data-bs-toggle="pill" data-bs-target="#pills-tracking" role="tab" aria-controls="pills-tracking" aria-selected="false">
            {{translate("bet_records")}}
        </div> -->
      </div>
    </div>
    <div v-if="user.user_data.data ? (user.user_data.data.currency == 'BETC' ? true : false) : false">
      <div v-if="Object.keys(betsList).length > 0" class="d-flex text-center">
        <select v-model="bet_currency" class="form-select form-select-sm" aria-label="Default select example">
          <option value="USDT" :selected="currency.fiat == 'USDT'">{{ translate("bet_usdt") }}</option>
          <option value="BETC" :selected="currency.fiat == 'BETC'">{{ translate("bet_betc") }}</option>
          <option :value="user.user_data.data.currency_fiat"
            :selected="currency.fiat == user.user_data.data.currency_fiat">
            {{ translate("bet_cop").replace('COP', user.user_data.data.currency_fiat) }}</option>
        </select>
      </div>
    </div>
    <div class="collapse" id="navbarToggleExternalContentQbet">
      <div class="row px-3">
        <div class="p-1 col-6" :style="skin.qbet.text" style="font-size:small;">
          <span :style="skin.qbet.bet_text2">{{ translate("type_of_bet") }}</span><br>
          <div class="form-check" v-if="skin.qBet_config.combinada">
            <input class="form-check-input" @change="set_apuesta_tipo('combinada')" type="radio" id="combinada"
              name="apuesta_tipo" value="combinada">
            <label class="form-check-label" for="combinada">
              {{ translate("combined") }}
            </label>
          </div>
          <div class="form-check" v-if="skin.qBet_config.simple">
            <input class="form-check-input" @change="set_apuesta_tipo('simple')" type="radio" id="simple"
              name="apuesta_tipo" value="simple">
            <label class="form-check-label" for="simple">
              Simple
            </label>
          </div>
          <div class="form-check" v-if="skin.qBet_config.sistema">
            <input class="form-check-input" @change="set_apuesta_tipo('sistema')" type="radio" id="sistema"
              name="apuesta_tipo" value="sistema">
            <label class="form-check-label" for="sistema">
              {{ translate("system_bet") }}
            </label>
          </div>
          <div class="form-check" v-if="skin.qBet_config.teaser">
            <input class="form-check-input" @change="set_apuesta_tipo('teaser')" type="radio" id="teaser"
              name="apuesta_tipo" value="teaser">
            <label class="form-check-label" for="teaser">
              Teaser
            </label>
          </div>
        </div>
        <div class="p-1 col-6" :style="skin.qbet.text" style="font-size:small;">
          <span :style="skin.qbet.bet_text2">{{ translate("type_of_installments") }}:</span><br>
          <div class="form-check" v-if="skin.qBet_config.decimal">
            <input class="form-check-input" type="radio" id="decimal" @change="set_cuota_tipo('decimal')"
              name="cuota_tipo" value="decimal">
            <label class="form-check-label" for="decimal">
              Decimal
            </label>
          </div>
          <div class="form-check" v-if="skin.qBet_config.americano">
            <input class="form-check-input" type="radio" id="americano" @change="set_cuota_tipo('americano')"
              name="cuota_tipo" value="americano">
            <label class="form-check-label" for="americano">
              {{ translate("american") }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content" id="pills-qbet">
      <div class="p-0 tab-pane fade active show" :style="skin.qbet.fondo" id="pills-ticket" role="tabpanel"
        aria-labelledby="pills-ticket">
        <div v-if="!bets_loaded" style="width:100%" class="text-center">
          <div class="spinner-border" :style="skin.loader" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div @click="terms" class="p-2" style="display: flex; justify-content: center;">
          <span style="text-decoration: underline;">{{ translate("terms_and_conditions") }}</span>
        </div>
        <div v-if="Object.keys(betsList).length < 1">
          <div class="p-4 text-center">
            <span :style="skin.qbet.text">{{ translate("no_bet_selected") }}</span>
          </div>
          <div v-if="skin.qbet.booking_enabled" class="input-group p-1">
            <input v-model="booking_code" class="form-control form-control-sm border" type="number"
              :placeholder="translate('enter_reservation_code')" style="font-size:small" />
            <button class="btn" :style="skin.qbet.booking_verify" type="button"
              @click="check_booking">{{ translate("verify") }}</button>
          </div>
        </div>
        <div
          v-if="user.user_data.data ? (user.user_data.data.currency == 'BETC' && bet_currency != 'BETC' ? true : false) : false">
          <div v-if="Object.keys(betsList).length > 0" class="alert alert-warning" role="alert"
            style="font-size: small;">
            {{ translate("bet_currency_warning").replace("USDT", bet_currency) }}
          </div>
        </div>
        <div v-if="alert.active == true" class="alert alert-dismissible fade"
          :class="alert.active ? 'show' + ' alert-' + alert.type : ''" role="alert">
          <strong v-html="alert.message" :style="alert.size != '' ? 'font-size:' + alert.size : ''"></strong>
          <button @click="alert_off" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
        </div>
        <div class="" :style="skin.qbet.fondo2">
          <div v-if="Object.keys(betsList).length > 0" class="text-start">
            <button @click="delete_all_odds" class="btn" :style="skin.qbet.text">{{ translate("delete_all") }}</button>
          </div>
          <transition-group name="fade" tag="div">
            <bet v-for="bet in betsList" v-bind:key="bet.event_id" v-bind:type="apuesta_tipo" v-bind:odd_data="bet"
              v-bind:Skin="skin" v-bind:language="language" v-bind:translation="translation"
              v-bind:bet_currency="bet_currency" @simple_bet="calculate_total_reward"
              @delete_simple_bet="delete_simple_bet"></bet>
          </transition-group>
        </div>
        <div v-if="Object.keys(betsList).length > 0" :style="skin.qbet.fondo3">
          <div v-if="apuesta_tipo == 'simple'">

          </div>
          <div v-else-if="apuesta_tipo == 'combinada'">
            <div v-if="skin.qBet_config.risk_win" class="p-2">
              <span :style="skin.qbet.text">{{ translate('total_rate') }}</span>
              <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{ total_rate
                }}</span><br><br>
              <div class="input-group">
                <input @input="calculateWin('risk', 'win', total_rate)" id="risk" type="number" placeholder="0"
                  class="form-control form-control-sm" aria-label="Recipient's username"
                  aria-describedby="basic-addon2">
                <span class="input-group-text" id="basic-addon2" :style="skin.qbet.risk_win_fondo"
                  style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{ translate("risk") }}</span>
              </div>
              <div class="input-group">
                <input @input="calculateRisk('win', 'risk', total_rate)" id="win" type="number" placeholder="0"
                  class="form-control form-control-sm" aria-label="Recipient's username"
                  aria-describedby="basic-addon3">
                <span class="input-group-text" id="basic-addon3" :style="skin.qbet.risk_win_fondo"
                  style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{ translate("gain") }}</span>
              </div>
            </div>
            <div v-else>
              <div class="p-1">
                <input v-model="total_bet" class="form-control form-control-sm border" type="text" inputmode="numeric"
                  pattern="\d*" :placeholder="translate('enter_bet')" style="font-size:small"
                  @keypress="onlyNumbers" />
              </div>
              <div class="p-1">
                <span :style="skin.qbet.text">{{ translate('total_rate') }}</span>
                <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{
                  rate_conversion(total_rate) }}</span><br>
                <span :style="skin.qbet.text">{{ translate('total_value') }}</span>
                <span class="float-end" :style="skin.qbet.text">{{ sanitized(total_bet == "" ? 0 :
                  total_bet, user.user_data.data ? user.user_data.data.currency : '') }}</span><br>
                <div v-if="bonus.value > 0" class="animated-border p-1" style="margin-left:-2px;margin-right:-2px;">
                  <div :style="skin.qBet_config.currency_bonus.style.background">
                    <span :style="skin.qBet_config.currency_bonus.style.text">{{ translate('bonus') }}</span>
                    <span id="bonus" class="float-end" :style="skin.qBet_config.currency_bonus.style.text">{{
                      '+' + bonus.value * 100 + '% ' + bonus.reward + ' ' + user.user_data.data.currency}}</span><br>
                  </div>
                </div>
                <div v-if="vat > 0">
                  <span :style="skin.qbet.text">{{ translate('A ganar:') }}</span>
                  <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{
                    sanitized(isNaN(total_reward) ? 0 : total_reward, user.user_data.data ? user.user_data.data.currency
                    : '') }}</span><br>
                  <span :style="skin.qbet.text">{{ translate('Impuesto SRI') + ' (' + (vat * 100).toFixed(0) + '%)'
                    }}</span>
                  <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{
                    sanitized(((total_reward - total_bet) * vat).toFixed(2), user.user_data.data ?
                    user.user_data.data.currency : '') }}</span><br>
                  <span :style="skin.qbet.text">{{ translate('overall_gain') }}</span>
                  <span class="float-end py-1" :style="skin.qbet.text">{{ sanitized(isNaN(total_reward) ? 0 :
                    total_reward - (total_reward - total_bet) * vat, user.user_data.data ? user.user_data.data.currency :
                    '') }}</span>
                </div>
                <div v-else>
                  <span :style="skin.qbet.text">{{ translate('overall_gain') }}</span>
                  <span class="float-end py-1" :style="skin.qbet.text">{{ sanitized(isNaN(total_reward) ? 0 :
                    total_reward, user.user_data.data ? user.user_data.data.currency : '') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="apuesta_tipo == 'sistema'" class="p-1">
            <table v-if="Object.keys(betsList).length > 1" class="table table-borderless" style="font-size:small">
              <thead>
                <tr class="text-center">
                  <th scope="col" class="p-1"></th>
                  <th scope="col" class="p-1"></th>
                  <th :style="skin.qbet.text" scope="col" class="p-1">{{ translate("risk") }}</th>
                  <th :style="skin.qbet.text" scope="col" class="p-1">{{ translate("gain") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, index) in calculate_combinations()" :key="index">
                  <td class="p-1 py-2"><input @change="disable_combination(index)" :data-index="index"
                      :id="index + '-check'" class="form-check-input mt-0 sistema_check" type="checkbox" value="" checked>
                  </td>
                  <td :style="skin.qbet.text" class="p-1 py-2">{{ index + 'Tx' + val.combinations.length }}</td>
                  <td class="p-1"><input @input="calculateWin(index + '-risk', index + '-win', val.rate)"
                      :data-qty="val.combinations.length" :id="index + '-risk'" type="number" placeholder="0"
                      class="form-control form-control-sm risk"></td>
                  <td class="p-1"><input @input="calculateRisk(index + '-win', index + '-risk', val.rate)"
                      :data-qty="val.combinations.length" :id="index + '-win'" type="number" placeholder="0"
                      class="form-control form-control-sm win"></td>
                </tr>
                <tr>
                  <td class="p-1"></td>
                  <td :style="skin.qbet.text" class="p-1">Total</td>
                  <td class="p-1"><input id="sistema-total-risk" type="number" placeholder="0"
                      :value="sistema_total_risk" class="form-control form-control-sm" disabled></td>
                  <td class="p-1"><input id="sistema-total-win" type="number" placeholder="0" :value="sistema_total_win"
                      class="form-control form-control-sm" disabled></td>
                </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-danger text-center">
              <strong>{{ translate("two_bets_min") }}</strong>
            </div>
          </div>
          <div v-else-if="apuesta_tipo == 'teaser'">
            <div v-if="skin.qBet_config.risk_win" class="p-2">
              <span :style="skin.qbet.text">{{ translate('total_rate') }}</span>
              <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{ total_rate
                }}</span><br><br>
              <div class="input-group">
                <input @input="calculateWin('risk', 'win', total_rate)" id="risk" type="number" placeholder="0"
                  class="form-control form-control-sm" aria-label="Recipient's username"
                  aria-describedby="basic-addon2">
                <span class="input-group-text" id="basic-addon2" :style="skin.qbet.risk_win_fondo"
                  style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{ translate("risk") }}</span>
              </div>
              <div class="input-group">
                <input @input="calculateRisk('win', 'risk', total_rate)" id="win" type="number" placeholder="0"
                  class="form-control form-control-sm" aria-label="Recipient's username"
                  aria-describedby="basic-addon3">
                <span class="input-group-text" id="basic-addon3" :style="skin.qbet.risk_win_fondo"
                  style="font-size:x-small;width:80px;display:flex;justify-content:center;">{{ translate("gain") }}</span>
              </div>
            </div>
            <div v-else>
              <div class="p-1">
                <input v-model="total_bet" class="form-control form-control-sm border" type="text" inputmode="numeric"
                  pattern="\d*" :placeholder="translate('enter_bet')" style="font-size:small"
                  @keypress="onlyNumbers" />
              </div>
              <div class="p-1">
                <span :style="skin.qbet.text">{{ translate('total_rate') }}</span>
                <span id="total_rate" :data-rate="total_rate" class="float-end" :style="skin.qbet.text">{{
                  rate_conversion(total_rate) }}</span><br>
                <span :style="skin.qbet.text">{{ translate('total_value') }}</span>
                <span class="float-end" :style="skin.qbet.text">{{ total_bet == "" ? 0 : total_bet }} {{
                  user.user_data.data ? user.user_data.data.currency : '' }}</span><br>
                <span :style="skin.qbet.text">{{ translate('overall_gain') }}</span>
                <span class="float-end py-1" :style="skin.qbet.text">{{ isNaN(total_reward) ? 0 : total_reward }} {{
                  user.user_data.data ? user.user_data.data.currency : '' }}</span>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div v-if="ticket_response_loading" style="width:100%" class="text-center" :style="skin.qbet.fondo3">
              <div class="spinner-border" :style="skin.loader" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <button ref="confirm-btn" @click="do_bet()" :style="skin.qbet.confirm_btn" class="p-2 text-center mb-2"
              style="width:100%;color:white">{{ translate('confirm_bet') }}</button>
            <button v-if="skin.qbet.booking_enabled" @click="create_booking" :style="skin.qbet.booking_btn"
              class="p-2 text-center mb-2" style="width:100%">{{ translate("reserve") }}</button>
          </div>
        </div>
      </div>
      <div class="p-0 tab-pane fade" :style="skin.qbet.fondo" id="pills-tracking" role="tabpanel"
        aria-labelledby="pills-tracking">
        <div v-if="bet_tracking.tickets" class="py-1">
          <tracking v-for="(ticket, index) in bet_tracking.tickets.data.bets" :key="ticket.id"
            v-bind:ticket_data="ticket" v-bind:Skin="skin" v-bind:language="language" v-bind:translation="translation"
            v-bind:index="index" v-bind:currentOdds="bet_tracking.currentOdds" v-bind:source="'qbet'" />
          <div class="my-2">
            <button @click="show_records()" :style="skin.prematch_lobby_main.more_events" class="p-2 text-center mb-2"
              style="width:100%;background:transparent;">VER TODO</button>
          </div>
        </div>
        <div v-else style="width:100%" class="text-center p-2" :style="skin.qbet.fondo3">
          <div class="spinner-border" :style="skin.loader" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ticket_response_loading" class="backLoad"></div>
  </div>
</template>
<script>
import bet from './bet.vue';
import tracking from './tracking.vue';
import router from '../../../router/index';
export default {
  name: "qbet",
  components: { bet, tracking },
  data() {
    return {
      bet_currency: "USDT",
      total_bet: "",
      total_rate: 0,
      total_reward: 0,
      win: 0,
      risk: 0,
      sistema_total_risk: 0,
      sistema_total_win: 0,
      apuesta_simple_data: {},
      bonus: { value: 0, reward: 0 },
      alert: {
        active: false,
        type: '',
        message: '',
        size: ''
      },
      bets_loaded: true,
      skin_select: false,
      text: '',
      ticket_response_loading: false,
      booking_code: '',
      bet_tracking: {}
    }
  },
  props: ['skin', 'language', 'translation'],
  computed: {
    betsList() {
      let bets = [];
      let markets = {};
      let data = this.$store.getters['getBets'];
      let isTeaser = false;
      let teaserVal = 0;
      let betType = this.$store.getters['getBetType'];
      let userData = this.$store.getters['getUserData'].user_data.data;
      let jbets_modified_odds = this.$store.getters['getJbetsModifieOdds'];
      let max_odd = parseFloat(userData ? userData.max_odds : 10000);
      let combinations = userData ? userData.combinaciones : [];
      let currency = userData ? userData.currency : '';
      let factorAjuste = this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach(bet => {
          if (data[bet]) {
            let deltaCombinada = data[bet].deltaCombinada;
            if (data[bet].islive) {
              if (data[bet].game == "Not available") {
                bets.push(
                  {
                    "live": true,
                    "sport": data[bet].alias,
                    "type": data[bet].referencia,
                    "game_id": data[bet].game_id,
                    "event_id": data[bet].event_id,
                    "market_id": data[bet].market_id,
                    "market_name": data[bet].extra_data.market,
                    "category": data[bet].extra_data.apuesta,
                    "selection": data[bet].seleccion,
                    "init_price": '1.00',
                    "alert": false,
                    "is_blocked": true,
                    "home": data[bet].equipoa,
                    "away": data[bet].equipob,
                    "actual_price": '1.00'
                  }
                )
              }
              else if (data[bet].game) {
                try {
                  let market_id = Object.keys(data[bet].game.game[data[bet].game_id].market)[0];
                  var last_event = data[bet].game.game[data[bet].game_id].last_event;
                  var odd_type = data[bet].game.game[data[bet].game_id].market[market_id].type;
                  let blocked_by_type = false;
                  let blocked = data[bet].blocked;
                  let price_init = (data[bet].price * deltaCombinada) < 1 ? '1.00' : (data[bet].price * deltaCombinada);
                  let price_actual = (data[bet].game.game[data[bet].game_id].market[market_id].event[data[bet].event_id].price * deltaCombinada * factorAjuste) < 1 ? '1.00' : (data[bet].game.game[data[bet].game_id].market[market_id].event[data[bet].event_id].price * deltaCombinada * factorAjuste);
                  let jbets_modified_odd = jbets_modified_odds.filter(odd_modified => odd_modified.event_id == data[bet].event_id);
                  if (jbets_modified_odd.length == 1) {
                    price_init = jbets_modified_odd[0].blocked ? 1.00 : price_init * jbets_modified_odd[0].factor < 1 ? 1.00 : price_init * jbets_modified_odd[0].factor;
                    price_actual = jbets_modified_odd[0].blocked ? 1.00 : price_actual * jbets_modified_odd[0].factor < 1 ? 1.00 : price_actual * jbets_modified_odd[0].factor;
                    blocked = jbets_modified_odd[0].blocked;
                  }
                  if (last_event) {
                    if ((last_event.type_id == "21" || last_event.type_id == "4" && odd_type == "TeamToScore") || data[bet].blocked) {
                      blocked_by_type = true;
                    }
                    else {
                      blocked_by_type = false;
                    }
                  }
                  if (parseInt(data[bet].game.game[data[bet].game_id].info.current_game_time) > this.$props.skin.live_all_odds.blocked_time) {
                    data[bet].blocked = true;
                  }
                  bets.push(
                    {
                      "live": true,
                      "sport": data[bet].alias,
                      "type": data[bet].referencia,
                      "game_id": data[bet].game_id,
                      "event_id": data[bet].event_id,
                      "market_id": data[bet].market_id,
                      "market_name": data[bet].extra_data.market,
                      "category": data[bet].extra_data.apuesta,
                      "selection": data[bet].seleccion,
                      "init_price": price_init.toFixed(2),
                      "alert": false,
                      "is_blocked": blocked_by_type && odd_type == "TeamToScore" ? blocked_by_type : blocked,
                      "home": data[bet].equipoa,
                      "away": data[bet].equipob,
                      "actual_price": price_actual.toFixed(2)
                    }
                  )
                } catch (error) {
                  console.log(error);
                }
              }
              else {
                bets.push(
                  {
                    "live": true,
                    "sport": data[bet].alias,
                    "type": data[bet].referencia,
                    "game_id": data[bet].game_id,
                    "event_id": data[bet].event_id,
                    "market_id": data[bet].market_id,
                    "market_name": data[bet].extra_data.market,
                    "category": data[bet].extra_data.apuesta,
                    "selection": data[bet].seleccion,
                    "init_price": data[bet].seleccion_ref.price.toFixed(2),
                    "alert": false,
                    "is_blocked": false,
                    "home": data[bet].equipoa,
                    "away": data[bet].equipob,
                    "actual_price": data[bet].seleccion_ref.price.toFixed(2)
                  }
                )
              }
            }
            else {
              try {
                let blocked = data[bet].blocked;
                let price = (data[bet].price * deltaCombinada) < 1 ? '1.00' : (data[bet].price * deltaCombinada);
                let jbets_modified_odd = jbets_modified_odds.filter(odd_modified => odd_modified.event_id == data[bet].event_id);
                if (jbets_modified_odd.length == 1) {
                  price = jbets_modified_odd[0].blocked ? 1 : price * jbets_modified_odd[0].factor < 1 ? 1 : price * jbets_modified_odd[0].factor;
                  blocked = jbets_modified_odd[0].blocked;
                }
                bets.push(
                  {
                    "live": false,
                    "sport": data[bet].alias,
                    "type": data[bet].referencia,
                    "odd_type": data[bet].seleccion_ref.type,
                    "game_id": data[bet].game_id,
                    "event_id": data[bet].event_id,
                    "market_id": data[bet].market_id,
                    "market_name": data[bet].extra_data.market,
                    "teaser": data[bet].hasOwnProperty('teaser') ? data[bet].teaser : null,
                    "category": data[bet].extra_data.apuesta,
                    "selection": data[bet].seleccion,
                    "init_price": parseFloat(price).toFixed(2),
                    "alert": false,
                    "is_blocked": blocked,
                    "home": data[bet].equipoa,
                    "away": data[bet].equipob,
                    "actual_price": parseFloat(price).toFixed(2),
                  }
                )
              } catch (error) {
                console.log(error);
              }
            }
          }
        });
        bets.forEach(element => {
          let alert_check = {};
          markets[element.market_name] = true;
          alert_check[element.market_id] = 0;
          alert_check[element.game_id] = 0;
          alert_check['combined'] = combinations[element.sport] ? combinations[element.sport][0].includes(element.type) && element.market_name == 'Pronostico' : false;
          alert_check['combined_total'] = 0;
          bets.forEach(item => {
            if (element.type == item.type && element.game_id == item.game_id) {
              alert_check[element.market_id]++;
            }
            if (element.type == item.type && element.game_id == item.game_id && alert_check['combined'] && element.market_name == 'Pronostico') {
              alert_check['combined_total']++;
            }
            if (element.game_id == item.game_id && this.apuesta_tipo == "combinada" || this.apuesta_tipo == "sistema") {
              alert_check[element.game_id]++;
            }
          });
          if (markets['Teaser']) {
            isTeaser = true;
            teaserVal = element.teaser.value;
            if (Object.keys(markets).length > 1 || betType != 'teaser') {
              element.alert = true;
            }
          }
          if (alert_check[element.market_id] > 1 && alert_check[element.game_id] > 1 && !alert_check['combined']) {
            element.alert = true;
          }
          else if (alert_check[element.game_id] > 1 && !alert_check['combined'] && element.market_name != 'Pronostico' && !isTeaser) {
            element.alert = true;
          }
          else if (alert_check[element.game_id] > 1 && alert_check['combined'] && element.market_name == 'Pronostico' && alert_check['combined_total'] > 1) {
            element.alert = true;
          }
          else if (!isTeaser && betType == 'teaser') {
            this.show_msj(this.translate('Tienes logros no compatibles con Teaser'), 'danger')
            element.alert = true;
          }
        });
        var total = 1;
        bets.forEach(element => {
          total *= parseFloat(element.actual_price);
        });
        total = total;
        if (isTeaser) {
          if (teaserVal == 4) { teaserVal = 6 };
          if (teaserVal == 4.5) { teaserVal = 6.5 };
          if (teaserVal == 5) { teaserVal = 7 };
          this.total_rate = this.$props.skin.qBet_config.teaser_rates[teaserVal + 'Points'][bets.length < 2 ? 2 : bets.length].toFixed(this.$props.skin.qBet_config.decimalsQty);
        }
        else {
          this.total_rate = total > max_odd ? max_odd.toFixed(this.$props.skin.qBet_config.decimalsQty) : total.toFixed(this.$props.skin.qBet_config.decimalsQty);
        }
        if (betType == 'combinada' || betType == 'teaser') {
          this.bonus.value = 0;
          this.bonus.reward = 0;
          this.calculate_total_reward()
          //this.calculateWin('risk','win',this.total_rate);
        }
        //BONUS
        let bonus = this.$props.skin.qBet_config.currency_bonus?.[currency];
        if ((bonus ? (bonus.available ? true : false) : false) && betType == 'combinada') {
          let qBetOddQty = bets.length;
          bonus.bonus.forEach(bono => {
            let rateCheck = true;
            bets.forEach(element => {
              if (element.actual_price < bono.min_rate || element.market_name == 'Pronostico') {
                rateCheck = false;
              }
              total *= parseFloat(element.actual_price);
            });
            if (qBetOddQty >= bono.odds_qty && (this.total_rate >= bono.rate_range.floor && this.total_rate <= bono.rate_range.ceil && rateCheck)) {
              this.bonus.value = bono.value;
              this.calculate_total_reward();
            }
          })
        }
      }
      this.bets_loaded = true;
      return bets;
    },
    vat() {
      return this.$store.getters['getConfig'].SkinConfig.main.vat ?? 0
    },
    can_bet() {
      let data = this.$store.getters['getCanBet'];
      return data;
    },
    max_win() {
      return parseFloat(this.$store.getters['getUserData'].user_data.data ? this.$store.getters['getUserData'].user_data.data.max_win_amount : 100000000);
    },
    apuesta_tipo() {
      return this.$store.getters['getBetType'];
    },
    rate_type() {
      return this.$store.getters['getRateType'];
    },
    user() {
      return this.$store.getters['getUserData'];
    },
    currency() {
      if (this.$store.getters['getCurrency'].fiat != '') {
        this.bet_currency = this.$store.getters['getCurrency'].fiat;
      }
      return this.$store.getters['getCurrency'];
    },
  },
  methods: {
    onlyNumbers(event) {
      const input = event.target.value;

      if (!/^\d$/.test(event.key) && event.key !== ".") {
        event.preventDefault();
      }

      // Evitar múltiples puntos
      if (event.key === "." && input.includes(".")) {
        event.preventDefault();
      }
    },
    terms() {
      let url = "";
      let lang = this.$props.language == 'es' ? 'spa' : (this.$props.language == 'en' ? 'eng' : (this.$props.language == 'pt' ? 'pt-br' : 'spa'));
      if (this.user.prefix == "external") {
        url = "https://docs.betsw3.xyz/terms/" + lang + "/?site=" + this.user.site.toUpperCase() + "&link=" + this.user.site;
      }
      else {
        let domain_formated = this.user.domain.split('//')[1];
        //let domain_formated = "admin.championred.bet";
        let domain_name1 = domain_formated.split('.')[1].toUpperCase();
        let domain_name2 = domain_formated.split('.')[1] + '.' + domain_formated.split('.')[2];
        url = "https://docs.betsw3.xyz/terms/" + lang + "/?site=" + domain_name1 + "&link=" + domain_name2;
      }

      window.open(url, '_blank').focus();
    },
    async get_tracking() {
      let dateFrom = new Date();
      dateFrom.setDate(dateFrom.getDate() - 10);
      let dateTo = new Date();
      dateTo.setDate(dateTo.getDate());
      let tracking_time = { from: Math.floor(dateFrom.getTime() / 1000), to: Math.floor(dateTo.getTime() / 1000) }
      let tracking = await this.$store.dispatch('getTracking', tracking_time);
      let pending_tickets = tracking.data.bets.filter(ticket => ticket.status == 1).reverse().slice(0, 5);
      let odd_ids = [];
      pending_tickets.forEach(pendingT => {
        pendingT.events.forEach(odd => {
          if (odd.status == 1) {
            odd_ids.push(odd.id);
          }
        })
      })
      odd_ids = [...new Set(odd_ids)];
      let currentOddData = await this.$store.dispatch('getOddData', odd_ids);
      tracking.data.bets = pending_tickets
      this.bet_tracking = { tickets: tracking, currentOdds: currentOddData };
      let interval = setInterval(function () {
        console.log('hidding tracking');
        document.getElementById('tab_coupon').click();
        clearInterval(interval);
      }, 180000)
    },
    save_bets(bets) {
      const parsed = JSON.stringify(bets);
      localStorage.setItem('bets', parsed);
    },
    search_text(text) {
      if (text != "") {
        this.$root.$emit("search", text);
        this.text = "";
      }
    },
    coupon_ok(bets, betId) {
      bets.forEach(element => {
        if (element.islive) {
          this.$store.dispatch('live/unsuscribe_odd', element.event_id);
        }
      });
      this.$store.dispatch('deleteBets');
      this.alert.message = this.translate('msg_coupon_ok');
      this.alert.type = 'success';
      this.alert.active = true;
      this.total_bet = "";
      let modal = document.getElementById('bookingModalBtn');
      modal.click();
      this.$root.$emit('newAlert', {
        label: '',
        message: this.translate('msg_coupon_ok') + ' ID: ' + betId,
        barCode: ''
      });
      setTimeout(this.alert_off, 5000);
      this.$store.dispatch('getUserData', {
        token: this.user.token,
        site_id: this.user.site_id,
        site: this.user.site,
        prefix: this.user.prefix
      });
      window.parent.postMessage(
        {
          type: 'bet_done',
          data: { code: betId }
        },
        '*'
      );
    },
    alert_off() {
      this.alert.active = false;
      this.alert.type = '';
      this.alert.message = '';
      this.alert.size = '';
    },
    translate(text) {
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    show_msj(msj, type) {
      this.alert.message = this.translate(msj);
      this.alert.type = type;
      this.alert.active = true;
      setTimeout(this.alert_off, 6000);
    },
    do_bet() {
      this.$refs["confirm-btn"].disabled = true;
      this.ticket_response_loading = true;
      var currency = this.user.user_data.data ? this.user.user_data.data.currency : '';
      if (this.$store.getters['getToken'] != null && this.$store.getters['getToken'] != "") {
        //if (this.$root.user_token=="") {
        var bets = [];
        var ok = true;
        var fail_reasons = {};
        var blocked_counter = 0;
        var icomp_counter = 0;
        var bet_counter = 0;
        var isTeaser = false;
        Object.keys(this.betsList).forEach(bet => {
          if (this.betsList[bet].is_blocked) {
            blocked_counter++;
            fail_reasons['blocked'] = blocked_counter;
          }
          if (this.betsList[bet].alert) {
            icomp_counter += 1;
            fail_reasons['incompatible'] = icomp_counter;
          }
          if (this.betsList[bet].teaser) {
            isTeaser = true;
          }
          bet_counter++;
        });
        if (blocked_counter > 0 || icomp_counter > 0) {
          ok = false;
        }
        if (isTeaser && bet_counter < 2) {
          fail_reasons['teaserMin'] = 2;
          ok = false;
        }
        if (ok) {
          let self = this;
          try {
            let geo = {};
            Object.keys(self.$store.getters['getBets']).forEach(bet => {
              if (self.$store.getters['getBets'][bet]) {
                self.$store.getters['getBets'][bet].extra_data.geo = geo;
                if (self.$store.getters['getBets'][bet].islive) {
                  let market = self.$store.getters['getBets'][bet].game.game[self.$store.getters['getBets'][bet].game_id].market;
                  let actual_price = (market[Object.keys(market)[0]].event[bet].price * self.$store.getters['getBets'][bet].deltaCombinada).toFixed(2);
                  self.$store.getters['getBets'][bet].price = actual_price;
                }
                bets.push(self.$store.getters['getBets'][bet]);
              }
            });
            if (self.apuesta_tipo == 'combinada') {
              if (this.total_bet != 0) {
                let ticketData = {
                  totalRate: self.total_rate,
                  totalStake: self.total_bet_sanitized(self.total_bet, currency),
                  totalStakeFiat: this.bet_currency != 'BETC' && currency == "BETC" ? self.total_bet : 0,
                  type: 2,
                  decimals: self.$props.skin.qBet_config.decimalsQty,
                  typeName: 'combinada',
                  totalWin: (self.total_rate * self.total_bet_sanitized(self.total_bet, currency)).toFixed(self.$props.skin.qBet_config.decimalsQty),
                  totalWinFiat: this.bet_currency != 'BETC' && currency == "BETC" ? (self.total_rate * self.total_bet).toFixed(self.$props.skin.qBet_config.decimalsQty) : 0,
                  extraData: null,
                  currency: currency == 'BETC' ? this.bet_currency : currency
                }
                self.send_ticket_data(bets, ticketData);
              }
              else {
                this.alert.message = this.translate('msg_no_bet');
                this.alert.type = 'danger';
                this.alert.active = true;
                setTimeout(this.alert_off, 3000);
                this.$refs["confirm-btn"].disabled = false;
                this.ticket_response_loading = false;
                return;
              }
            }
            if (self.apuesta_tipo == 'teaser') {
              if (this.total_bet != 0) {
                let ticketData = {
                  totalRate: self.total_rate,
                  totalStake: self.total_bet_sanitized(self.total_bet, currency),
                  decimals: self.$props.skin.qBet_config.decimalsQty,
                  type: 4,
                  typeName: 'teaser',
                  totalWin: (self.total_rate * self.total_bet).toFixed(self.$props.skin.qBet_config.decimalsQty),
                  extraData: null,
                  currency: currency == 'BETC' ? this.bet_currency : currency
                }
                self.send_ticket_data(bets, ticketData);
              }
              else {
                this.alert.message = this.translate('msg_no_bet');
                this.alert.type = 'danger';
                this.alert.active = true;
                setTimeout(this.alert_off, 3000);
                this.$refs["confirm-btn"].disabled = false;
                this.ticket_response_loading = false;
                return;
              }
            }
            else if (self.apuesta_tipo == 'simple') {
              let totalStake = 0;
              let totalStakeFiat = 0;
              bets.forEach(betItem => {
                totalStake += parseFloat(self.apuesta_simple_data[betItem.event_id].val);
                totalStakeFiat += parseFloat(self.apuesta_simple_data[betItem.event_id].valFiat ?? 0);
              });
              let ticketData = {
                totalRate: 0,
                totalStake: totalStake,
                totalStakeFiat: totalStakeFiat,
                decimals: self.$props.skin.qBet_config.decimalsQty,
                type: 1,
                typeName: 'simple',
                totalWin: 0,
                totalWinUsd: 0,
                extraData: self.apuesta_simple_data,
                currency: currency == 'BETC' ? this.bet_currency : currency
              }
              self.send_ticket_data(bets, ticketData);
            }
            else if (self.apuesta_tipo == 'sistema') {
              let totalStake = document.getElementById('sistema-total-risk').value;
              let totalWin = document.getElementById('sistema-total-win').value;
              let combinationChecks = document.getElementsByClassName('sistema_check');
              let sistemaData = {};
              [...combinationChecks].forEach(element => {
                if (element.checked) {
                  let ticketRisk = document.getElementById(element.dataset.index + '-risk').value;
                  sistemaData[element.dataset.index] = self.total_bet_sanitized(ticketRisk, currency);
                }
              });
              let ticketData = {
                totalRate: 0,
                totalStake: self.total_bet_sanitized(totalStake, currency),
                decimals: self.$props.skin.qBet_config.decimalsQty,
                type: 3,
                typeName: 'sistema',
                totalWin: totalWin,
                extraData: sistemaData,
                currency: currency == 'BETC' ? this.bet_currency : currency
              }
              self.send_ticket_data(bets, ticketData);
            }
          } catch (error) {
            console.log(error);
            self.show_msj('Ha ocurrido un error, intenta nuevamente', 'danger');
            self.ticket_response_loading = false;
            this.$refs["confirm-btn"].disabled = false;
          }
        }
        else {
          let alert = "";
          Object.keys(fail_reasons).forEach(reason => {
            if (reason == 'blocked') {
              alert += alert == "" ? this.translate('msg_odd_blocked') + " (" + fail_reasons[reason] + ")" : "<br>" + this.translate('msg_odd_blocked') + " (" + fail_reasons[reason] + ")";
            }
            if (reason == 'incompatible') {
              alert += alert == "" ? this.translate('msg_odd_incompatible') + " (" + fail_reasons[reason] + ")" : "<br>" + this.translate('msg_odd_incompatible') + " (" + fail_reasons[reason] + ")";
            }
            if (reason == 'teaserMin') {
              alert += alert == "" ? "Las apuestas de Teaser requieren minimo (" + fail_reasons[reason] + ") logros apostados" : "<br>" + "Las apuestas de Teaser requieren minimo (" + fail_reasons[reason] + ") logros apostados";
            }

          });
          this.alert.message = alert;
          this.alert.type = 'danger';
          this.alert.active = true;
          setTimeout(this.alert_off, 3000);
          this.$refs["confirm-btn"].disabled = false;
          this.ticket_response_loading = false;
        }
      }
      else {
        this.alert.message = this.translate('msg_no_user');
        this.alert.type = 'danger';
        this.alert.active = true;
        setTimeout(this.alert_off, 3000);
        this.ticket_response_loading = false;
        this.$refs["confirm-btn"].disabled = false;
      }
    },
    async check_booking() {
      if (this.booking_code != '') {
        let amount = await this.$store.dispatch('getBooking', this.booking_code);
        if (amount != 0) {
          this.total_bet = amount;
        }
      }
      else {
        this.alert.message = this.translate('valid_reservation_code');
        this.alert.type = 'danger';
        this.alert.active = true;
        setTimeout(this.alert_off, 5000);
      }
    },
    async create_booking() {
      this.ticket_response_loading = true;
      let code = await this.$store.dispatch('newBooking', this.total_bet);
      let codeData = {
        "action": "bookingBetsw3",
        "data": {
          "bookingId": code
        }
      }
      console.log(codeData);
      window.parent.postMessage(codeData, '*');
      console.log(codeData);
      window.parent.postMessage(codeData, '*');
      let modal = document.getElementById('bookingModalBtn');
      modal.click();
      this.$root.$emit('newAlert', {
        label: this.translate('sucess_reserva'),
        message: this.translate('created_reserva'),
        barCode: code
      });
      this.delete_all_odds();
      this.ticket_response_loading = false;
    },
    send_ticket_data(bets, ticketData) {
      let self = this;
      let factorAjuste = this.$store.getters['getConfig'].SkinConfig.main.deltalogro ? this.$store.getters['getConfig'].SkinConfig.main.deltalogro : 1;
      const bet_data = {
        "command": "do_bet",
        "source": "JorgeBets",
        "host_name": self.$store.getters['getHostName'],
        "type_name": ticketData.typeName,
        "params": {
          "bets": bets,
          "mainData": ticketData,
          "betslip": {
            "expOdds": ticketData.totalRate,//Cuota total
            "totalStake": ticketData.totalStake, //Igual al monto apostado
            "expWin": ticketData.totalWin,
            "totalStakeFiat": ticketData.totalStakeFiat,
            "expWinFiat": ticketData.totalWinFiat,
            "currencyFiat": ticketData.currency,
            "tasaFiat": self.user.user_data.data.tasa_fiat ?? 1
          },
          "bonus": self.bonus,
          "factor": factorAjuste,
          "amount": ticketData.totalStake, //catidad apostada
          "site_id": self.$store.getters['getSiteId'], //ID del sitio
          "proxy_site_id": self.$store.getters["getConfig"].main.site_id, //site id para el proxy
          "extra_data": {
            "type": ticketData.type,
            "source": "1",
            "mode": 1,
            "each_way": false,
            "platform": "desktop",
          }
        },
      }
      if (this.vat > 0) {
        bet_data.params.extra_data.vat = this.vat;
        bet_data.params.extra_data.totalVatAmount = ((ticketData.totalWin - ticketData.totalStake) * this.vat).toFixed(2);
      }
      let post_data = {
        bet_data: bet_data,
        token: self.$store.getters['getToken'] //user token
      };
      if (self.can_bet) {
        var ok_cupon = self.coupon_ok;
        var msj_show = self.show_msj;
        self.$http.post(
          self.$store.getters['getUserData']['domain'] + "/provider/skin/one/dobet?auth_token=" + self.$store.getters['getUserData']['token'] + '&site=' + self.$store.getters['getUserData']['site'], post_data
        ).then(function (response) {
          if (response.data.status == "success") {
            try {
              ok_cupon(bets, response.data.extra_data.data.details.betid);
              const printConfig = self.$store.getters['getConfig'].SkinConfig.main.print_ticket_mode;
              if (printConfig === 'external') {
                const printTicketData = {
                  "action": "printTicketBetsw3",
                  "data": {
                    "ticketId": response.data.extra_data.data.details.betid,
                    "withdrawalCode": response.data.extra_data.data.details.betcode ?? null,
                    "status": 1,
                    "username": self.user.user_data.data.name,
                    "platform": self.$store.getters['getConfig'].main.site_name,
                    "ticketDate": Math.floor(Date.now() / 1000),
                    "total": ticketData.totalStake,
                    "totalWin": ticketData.totalWin,
                    "totalRate": ticketData.totalRate,
                    "currency": ticketData.currency,
                    "bets": bets.map(bet => {
                      return {
                        "gameStart": bet.start_ts,
                        "league": bet.extra_data.liga,
                        "game": `${bet.equipoa} - ${bet.equipob ?? ''}`,
                        "event": bet.extra_data.apuesta,
                        "selection": bet.seleccion_ref.name,
                        "rate": bet.price,
                        "status": 1
                      }
                    }),
                  }
                }
                console.log(printTicketData);
                window.parent.postMessage(printTicketData, '*');
              } else if (response.data.print != "") {
                window.open(response.data.print, "mywindow", "menubar=1,resizable=1,width=800,height=800");
              }
            } catch (error) {
              console.error(error);
            }
          }
          else if (response.data.status == "error") {
            if (response.data.message[0]) {
              msj_show(response.data.message[0].error, 'danger');
              // msj_show('valor_invalido','danger'); 
            }
          }
          else {
            msj_show('Ha ocurrido un error, intenta nuevamente', 'danger');
          }
          self.ticket_response_loading = false;
          self.$refs["confirm-btn"].disabled = false;
        });
      }
      else {
        self.show_msj('Por el momento no aceptamos apuestas', 'danger');
        self.$refs["confirm-btn"].disabled = false;
        self.ticket_response_loading = false;
      }
    },
    delete_all_odds() {
      this.apuesta_simple_data = {};
      this.betsList.forEach(element => {
        this.$store.dispatch('live/unsuscribe_odd', element.event_id);
      });
      this.$store.dispatch('deleteBets');
    },
    set_apuesta_tipo(type) {
      this.total_reward = 0;
      this.apuesta_simple_data = {};
      this.sistema_total_risk = 0;
      this.sistema_total_win = 0
      this.total_bet = ""
      this.$store.dispatch("setBetType", type);
    },
    set_cuota_tipo(type) {
      this.$store.dispatch("setRateType", type);
    },
    calculate_total_reward(simple_data) {
      if (this.apuesta_tipo == 'combinada' || this.apuesta_tipo == 'teaser') {
        let total_win = this.total_bet * this.total_rate > this.max_win ? this.max_win : this.total_bet * this.total_rate;
        let net_win = total_win - this.total_bet <= 0 ? total_win : total_win - this.total_bet;
        if (this.bonus.value > 0) {
          let bonusReward = parseFloat(net_win * this.bonus.value);
          this.bonus.reward = bonusReward.toFixed(this.$props.skin.qBet_config.decimalsQty);
          this.total_reward = (total_win + bonusReward > this.max_win ? this.max_win : total_win + bonusReward).toFixed(this.$props.skin.qBet_config.decimalsQty);
        }
        else {
          this.total_reward = total_win.toFixed(this.$props.skin.qBet_config.decimalsQty);
        }
      }
      else if (this.apuesta_tipo == 'simple' && simple_data) {
        let total_win = 0;
        this.apuesta_simple_data[simple_data.id] = {
          val: simple_data.val,
          valFiat: simple_data.valFiat,
          rate: simple_data.rate,
          confirmed: false
        };
        Object.keys(this.apuesta_simple_data).forEach(apuesta => {
          total_win += parseFloat(this.apuesta_simple_data[apuesta].val) * parseFloat(this.apuesta_simple_data[apuesta].rate);
        })
        total_win = total_win > this.max_win ? this.max_win : total_win
        this.total_reward = total_win.toFixed(this.$props.skin.qBet_config.decimalsQty);
      }
    },
    delete_simple_bet(id) {
      delete this.apuesta_simple_data[id];
    },
    calculateWin(source_id, target_id, rate) {
      if (document.getElementById(source_id)) {
        let val = document.getElementById(source_id).value;
        let win = ((rate * val) - val).toFixed(this.$props.skin.qBet_config.decimalsQty);
        document.getElementById(target_id).value = val != '' ? win : val;
        this.total_bet = val;
        let win_inputs = document.getElementsByClassName('win');
        let totalWin = 0;
        [...win_inputs].forEach(element => {
          totalWin += parseFloat(element.value == "" ? 0 : element.value);
        });
        this.sistema_total_win = totalWin.toFixed(this.$props.skin.qBet_config.decimalsQty);
        let risk_inputs = document.getElementsByClassName('risk');
        let totalRisk = 0;
        [...risk_inputs].forEach(element => {
          totalRisk += parseFloat(element.value == "" ? 0 : element.value * element.dataset.qty);
        });
        this.sistema_total_risk = totalRisk;
      }
    },
    calculateRisk(source_id, target_id, rate) {
      if (document.getElementById(source_id)) {
        let val = document.getElementById(source_id).value;
        let risk = (val / (rate - 1)).toFixed(this.$props.skin.qBet_config.decimalsQty);
        this.total_bet = risk;
        document.getElementById(target_id).value = val != '' ? risk : val;
        let win_inputs = document.getElementsByClassName('win');
        let totalWin = 0;
        [...win_inputs].forEach(element => {
          totalWin += parseFloat(element.value == "" ? 0 : element.value);
        });
        this.sistema_total_win = totalWin;
        let risk_inputs = document.getElementsByClassName('risk');
        let totalRisk = 0;
        [...risk_inputs].forEach(element => {
          totalRisk += parseFloat(element.value == "" ? 0 : element.value * element.dataset.qty);
        });
        this.sistema_total_risk = totalRisk;
      }
    },
    calculate_combinations() {
      if (Object.keys(this.betsList).length > 1) {
        let data = [];
        Object.keys(this.betsList).forEach(bet => {
          data.push(this.betsList[bet]);
        })
        var combinations = {};
        var a3 = [];
        function combine(newArray, array, n) {
          if (n == 0) {
            a3.push(newArray);
          }
          else {
            for (let index = 0; index < array.length; index++) {
              let a2 = array.slice(0, index + 1);
              let diff = array.filter(x => !a2.includes(x))
              combine(newArray.concat([array[index]]), diff, n - 1)
            }
          }
        }
        for (let index = 2; index < data.length + 1; index++) {
          combine([], data, index);
          let rates = [];
          a3.forEach(ticket => {
            let rate = 1
            ticket.forEach(bet => {
              rate *= parseFloat(bet.actual_price);
            })
            rates.push(parseFloat(rate.toFixed(2)));
          })
          let total = rates.reduce((a, b) => a + b, 0);
          combinations[index] = { combinations: a3, rate: total.toFixed(2) };
          a3 = [];
        }
        return combinations;
      }
      return {};
    },
    disable_combination(index) {
      let check = document.getElementById(index + '-check').checked;
      if (!check) {
        document.getElementById(index + '-risk').disabled = true;
        document.getElementById(index + '-win').disabled = true;
        document.getElementById(index + '-risk').value = "";
        document.getElementById(index + '-win').value = "";
      }
      else {
        document.getElementById(index + '-risk').disabled = false;
        document.getElementById(index + '-win').disabled = false;
      }
      let win_inputs = document.getElementsByClassName('win');
      let totalWin = 0;
      [...win_inputs].forEach(element => {
        totalWin += parseFloat(element.value == "" ? 0 : element.value);
      });
      this.sistema_total_win = totalWin;
      let risk_inputs = document.getElementsByClassName('risk');
      let totalRisk = 0;
      [...risk_inputs].forEach(element => {
        totalRisk += parseFloat(element.value == "" ? 0 : element.value);
      });
      this.sistema_total_risk = totalRisk;
    },
    rate_conversion(rate) {
      if (this.rate_type == 'americano') {
        let value = parseFloat(rate);
        if (value >= 2) {
          let result = (value - 1) * 100;
          return '+' + (Math.round(result));
        }
        else {
          let result = (-100) / (value - 1);
          return Math.round(result);
        }
      }
      return parseFloat(rate).toFixed(2)
    },
    show_records() {
      this.$store.dispatch('setLobbyView', 'records');
      router.push({ name: 'Main_ecuabets', query: { token: this.user.token + this.user.site_id } })
    },
    sanitized(val, currency) {
      // Combinar la parte entera formateada con la parte decimal
      if (typeof val === 'string') {
        val = parseFloat(val);
      }
      const formattedValue = val.toLocaleString("en-US");
      if (this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false) {
        if (currency == 'BETC') {
          return formattedValue + ' ' + this.bet_currency;
        }
        return formattedValue + (currency != '' ? ' ' + currency : '');
      }
      return formattedValue + (currency != '' ? ' ' + currency : '');
    },
    total_bet_sanitized(val, currency) {
      if (this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false) {
        if (currency == 'BETC' && this.bet_currency == 'USDT') {
          let tasa = this.user.user_data.data.tasa ?? 1;
          return parseFloat(val / tasa).toFixed(4);
        }
        else if (currency == 'BETC' && this.bet_currency != 'BETC' && this.bet_currency != 'USDT') {
          let tasa_fiat = this.user.user_data.data.tasa_fiat ?? 1;
          let tasa_betc = this.user.user_data.data.tasa ?? 1;
          return parseFloat((val / tasa_fiat) / tasa_betc).toFixed(4);
        }
        return parseFloat(val).toFixed(this.$props.skin.qBet_config.decimalsQty);
      }
      return parseFloat(val).toFixed(this.$props.skin.qBet_config.decimalsQty);
    }
  },
  watch: {
    total_bet(newVal) {
      this.calculate_total_reward();
    },
    bet_currency(newVal) {
      if (this.user.user_data.data) {
        this.$store.dispatch('changeCurrencyFiat', { fiat: newVal, rate: newVal != 'BETC' ? (newVal == 'USDT' ? this.user.user_data.data.tasa : this.user.user_data.data.tasa_fiat) : 1 })
      }
      localStorage.setItem('bet_currency', newVal);
    }
  },
  mounted() {
    if (localStorage.getItem('bet_currency')) {
      this.bet_currency = localStorage.getItem('bet_currency');
    }
    this.$root.$on('bet_loading', () => {
      this.bets_loaded = false;
    })
    if (this.apuesta_tipo == 'simple') {
      document.getElementById('simple').checked = true;
    }
    else if (this.apuesta_tipo == 'combinada') {
      document.getElementById('combinada').checked = true;
    }
    else if (this.apuesta_tipo == 'sistema') {
      document.getElementById('sistema').checked = true;
    }
    else if (this.apuesta_tipo == 'teaser') {
      document.getElementById('teaser').checked = true;
    }
    if (this.rate_type == 'americano') {
      document.getElementById('americano').checked = true;
    }
    else if (this.rate_type == 'decimal') {
      document.getElementById('decimal').checked = true;
    }
  },
  created() {

  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0
}

.backLoad {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: #fff;
  opacity: 0.5;
}

.animated-border {
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%));
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
</style>
