<template>
  <div :id="'acordion'+ deporte.id" class="accordion mb-1 p-1" :style="Skin.fondo2">
    <div class="accordion-item" :style="Skin.fondo2+'border:none'">
      <div class="accordion-header">
        <button class="accordion-button collapsed p-0" :style="Skin.fondo2+Skin.text1" type="button" data-bs-toggle="collapse" :data-bs-target="'#d'+ deporte.id" :aria-expanded="deporte['name_en']!='Soccer'?false:true" :aria-controls="'d'+ deporte.id">
          <div style="display:flex;width:100%">
            <div style="width:10%" class="mx-1">
              <div class="icon_futbol" style="width:20px;height:20px" v-if="deporte.id==1 || deporte.id==90"></div>
              <div class="icon_hockey" style="width:20px;height:20px" v-if="deporte.id==17"></div>
              <div class="icon_basket" style="width:20px;height:20px" v-if="deporte.id==18"></div>
              <div class="icon_tenis" style="width:20px;height:20px" v-if="deporte.id==13"></div>
              <div class="icon_beisball" style="width:20px;height:20px" v-if="deporte.id==16"></div>
              <div class="icon_futsal" style="width:20px;height:20px" v-if="deporte.id==83"></div>
              <div class="icon_tenis_mesa" style="width:20px;height:20px" v-if="deporte.id==92"></div>
              <div class="icon_eGames" style="width:20px;height:20px" v-if="deporte.id==151"></div>
              <div class="icon_cricket" style="width:20px;height:20px" v-if="deporte.id==3"></div>
              <div class="icon_boxeo" style="width:20px;height:20px" v-if="deporte.id==9"></div>
              <div class="icon_snoker" style="width:20px;height:20px" v-if="deporte.id==14"></div>
              <div class="icon_balon_mano" style="width:20px;height:20px" v-if="deporte.id==78"></div>
              <div class="icon_volleyball" style="width:20px;height:20px" v-if="deporte.id==91"></div>
              <div class="icon_squash" style="width:20px;height:20px" v-if="deporte.id==107"></div>
              <div class="icon_waterpolo" style="width:20px;height:20px" v-if="deporte.id==110"></div>
              <div class="icon_rugby" style="width:20px;height:20px" v-if="deporte.id==8"></div>
              <div class="icon_dardos" style="width:20px;height:20px" v-if="deporte.id==15"></div>
              <div class="icon_rugby1" style="width:20px;height:20px" v-if="deporte.id==19"></div>
              <div class="icon_futAmericano" style="width:20px;height:20px" v-if="deporte.id==12"></div>
              <div class="icon_badminton" style="width:20px;height:20px" v-if="deporte.id==94"></div>
              <div class="icon_gaelic" style="width:20px;height:20px" v-if="deporte.id==75"></div>
            </div>
            <div style="width:80%"><span class="long_text">{{ deporte['name_'+language] }}</span></div>
            <div style="width:10%"><span style="float:right" :style="Skin.fondo2+Skin.text3">{{ qty_eventos }}</span></div>
          </div>
          &nbsp;&nbsp;&nbsp;
        </button>
      </div>
      <div :id="'d'+ deporte.id" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#acordion'+ deporte.id">
      <div class="accordion-body p-0" style="">
        <eventoPais
              v-for="pais in paises"
              v-bind:pais="pais"
              v-bind:deporte="deporte"
              v-bind:skin="Skin"
              v-bind:key="pais.id"
              >
        </eventoPais>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import eventoPais from '../prematch1/Evento_pais_prematch.vue';
export default{
  name:"eventoDeporte",
  components:{eventoPais},
  data(){
    return {
    }
  },
  props:['deporte','Skin','translation','language'],
  computed:{
    paises(){
      var list=[];
      let data = this.$props.deporte.countries;
      Object.keys(data).forEach(country => {
        var check = false;
        if (data[country].leagues.length > 0) {
          check = true; 
        }
        if(check){
          list.push(data[country]);
        }
      });
      return list;
    },
    qty_eventos(){
      let count = 0;
      let data = this.$props.deporte.countries;
      Object.keys(data).forEach(country => {
        Object.keys(data[country].leagues).forEach(league=>{
          count +=data[country].leagues[league].events.length;
        })
      });
      return count;
    }
  }
}
</script>
