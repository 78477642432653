<template>
  <div class="px-1 py-1 pt-1">
    <div class="text-center p-3">
      <span :class="skin.text1">{{ translate('schedule') }}</span>
      <hr class="my-1 border border-1 border-white"/>
      <div :style="skin.fondo3 + 'font-size:small'" :class="skin.text1" class="row">
        <div @click="select_time('1hh')" id="1hh" class="border border-1 border-white col col-2 p-0 m-0 time" style="cursor:pointer">1HH</div>
        <div @click="select_time('3hh')" id="3hh" class="border border-1 border-white col col-2 p-0 m-0 time" style="cursor:pointer">3HH</div>
        <div @click="select_time('hoy')" id="hoy" class="border border-1 border-white col col-2 p-0 m-0 time" style="cursor:pointer">{{ translate('today') }}</div>
        <div @click="select_time('hoy1')" id="hoy1" class="border border-1 border-white col col-3 p-0 m-0 time" style="cursor:pointer">{{ translate('tomorrow') }}</div>
        <div @click="select_time('todos')" id="todos" class="border border-1 border-white col col-3 text-dark bg-warning p-0 m-0 time" style="cursor:pointer">{{ translate('all') }}</div>
      </div>
    </div>
    <div>
      <eventoDeporte
          v-for="deporte in deportes"
          v-bind:deporte="deporte"
          v-bind:Skin="skin"
          v-bind:key="deporte.id"
          v-bind:language="language"
          v-bind:translation="translation"
          >
        </eventoDeporte>
    </div>
  </div>
</template>
<script>
import eventoDeporte from './Evento_deporte_prematch.vue';
export default{
  name:"timeLine",
  components:{eventoDeporte},
  data(){
    return {
      titulo:"PROGRAMACION EVENTOS",
      accordionBtnClass:"",
      accordionBtnExpanded:"",
      accordionBodyClass:"",
      screen_width:window.innerWidth,
      counter:0
    }
  },
  props:['skin','translation','language'],
  methods:{
    select_time:function(id){
      var timers = document.getElementsByClassName('time');
      var target = document.getElementById(id);
      [...timers].forEach(element => {
        element.classList.remove('bg-warning','text-dark');
      });
      target.classList.add('bg-warning','text-dark');
      this.change_time(id);
    },
    translate(text){
      console.log();
      return this.$props.translation[text][this.$props.language];
    },
    accordionControl(){
      this.screen_width=window.innerWidth;
      this.accordionBtnClass="";
        this.accordionBtnExpanded="true";
        this.accordionBodyClass="show";
    },
    change_time(new_time){
      var currentdate = new Date();
      var date_from='';
      var date_to ='';
      var from_UTC = '' ;
      var to_UTC = '';
      var time = ''; 
      if (new_time == '1hh') {
          date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear()+ " "
                  + (currentdate.getHours()) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear()+ " "
                  + (currentdate.getHours()+1) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          from_UTC = new Date(date_from).toISOString();
          to_UTC = new Date(date_to).toISOString();
          time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];
      }
      else if(new_time == '3hh'){
          date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear()+ " "
                  + (currentdate.getHours()) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear()+ " "
                  + (currentdate.getHours()+3) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          from_UTC = new Date(date_from).toISOString();
          to_UTC = new Date(date_to).toISOString();
          time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];
      }
      else if(new_time == 'hoy'){
          date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear()+ " "
                  + (currentdate.getHours()) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/" 
                  + currentdate.getFullYear()
                  + " 23:59:00";
          from_UTC = new Date(date_from).toISOString();
          to_UTC = new Date(date_to).toISOString();
          time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];       
      }
      else if(new_time == 'hoy1'){
          currentdate.setDate(currentdate.getDate() + 1);
          date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear() + " 00:00:00";
          date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear() + " 23:59:00";
          from_UTC = new Date(date_from).toISOString();
          to_UTC = new Date(date_to).toISOString();
          time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];        
      }
      else if(new_time == 'todos'){
          date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear() + " "
                  + (currentdate.getHours()) + ":"  
                  + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
                  + ("0"+ currentdate.getSeconds()).slice(-2);
          currentdate.setDate(currentdate.getDate() + 3);
          date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
                  + ("0" + currentdate.getDate()).slice(-2) + "/"
                  + currentdate.getFullYear() + " 23:59:00";
          from_UTC = new Date(date_from).toISOString();
          to_UTC = new Date(date_to).toISOString();
          time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];
      }
      this.$store.dispatch('prematch/getData',time);
    }
  },
  computed:{
    deportes(){
      var list=[];
      let data = this.$store.getters['prematch/getPrematchData'];
      Object.keys(data).forEach(element => {
        var check = false;
        data[element].countries.forEach(country => {
          if (country.leagues.length > 0) {
            check = true; 
          }
        });
        if(check){
          list.push(data[element]);
        }
      });
      return list;
    }
  },
  mounted(){
    window.addEventListener('resize', ()=>{this.accordionControl();});
  },
  created() {
    this.accordionControl();
  },
  destroyed() {
      window.removeEventListener('resize', ()=>{this.accordionControl();});
  }
}
</script>
<style>
  .accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
</style>
