<template>
  <div id="app" :style="skin.imagen_fondo"> 
  <!-- <div id="app" style="background-image: url('https://resources.jbets.online/backgrounds/fondo_2.jpg'); background-attachment: fixed; background-size: cover; height: 100%;">   -->

    <router-view v-slot="{Component}">
        <transition name="fade" mode="out-in">
          <component :is="Component"></component>
        </transition>
    </router-view>
    <!-- Booking Modal -->
    <div v-if="skin.qbet" class="modal fade" id="bookingModal" tabindex="-1" aria-labelledby="bookingModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="z-index:99999"> 
          <div class="modal-header" :style="skin.qbet.header">
            <h5 class="modal-title" id="bookingModalLabel">{{ alertLabel }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" id="bookingBody" :style="skin.qbet.fondo+skin.qbet.text || ''">
            <span v-html="alertMessage"></span>
            <div v-if="alertBarCode!=''">
              <div id="bookingBarCode">
                <vue-barcode v-bind:value="alertBarCode" v-bind:width="3">
                  Show this if the rendering fails.
                </vue-barcode>
              </div>
              <br>
              <button @click="printBooking()" :style="skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white"> {{ translations[currentLanguage] }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueBarcode from 'vue-barcode';
export default {
  name: 'App',
  components:{VueBarcode},
  data(){
    return {
      route:"",
      alertLabel: "",
      alertMessage: "",
      alertBarCode: "",
      translations: {
      es: "Imprimir Reserva",
      en: "Print Reservation",
      pt: "Imprimir Reserva" // Portugués de Brasil
    }
    }
  },
  created() {
    let self = this;
    setTimeout(function(){
      self.$store.dispatch("getSkin");
      self.$store.dispatch("getExternalEvents");
    },100)
    var currentdate = new Date(); 
    var date_from='';
    var date_to ='';
    var from_UTC = '' ;
    var to_UTC = ''; 
    date_from = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
            + ("0" + currentdate.getDate()).slice(-2) + "/"
            + currentdate.getFullYear() + " "
            + (currentdate.getHours()) + ":"  
            + ("0"+ currentdate.getMinutes()).slice(-2) + ":" 
            + ("0"+ currentdate.getSeconds()).slice(-2);
    currentdate.setDate(currentdate.getDate() + 3);
    date_to = ("0" +(currentdate.getMonth()+1)).slice(-2)  + "/" 
            + ("0" + currentdate.getDate()).slice(-2) + "/"
            + currentdate.getFullYear() + " 23:59:00";
    from_UTC = new Date(date_from).toISOString();
    to_UTC = new Date(date_to).toISOString();
    let time = from_UTC.replace('T',' ').split('.')[0]+'.'+to_UTC.replace('T',' ').split('.')[0];
    //this.$store.dispatch('prematch/getData',time);
  },
  mounted(){
    this.$root.$on('newAlert', (data) => {
        this.alertLabel = data.label;
        this.alertMessage = data.message;
        this.alertBarCode = data.barCode;
    });
  },
  computed:{
    currentLanguage() {
    return this.$store.getters.getLanguage || "es"; // Si no hay idioma, usa español por defecto
  },
    skin(){
      let data = this.$store.getters['getCurrentSkin'];
      if(data.qbet){
        if(Date.now() > parseInt(data.sport_view.max_sport_val)*1000){this.$store.dispatch('detach_data',{});}
      }
      return data;
    },
    accordion_btn(){
      return {
        "--image":url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='"+this.skin.arrow_color+"'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"),
      }
    },
    user(){
      return this.$store.getters['getUserData'];
    },
  },
  methods:{
    printBooking(){
      var contenidoDiv = document.getElementById('bookingBarCode').innerHTML;
      var ventanaImpresion = window.open('', "mywindow","menubar=1,resizable=1,width=800,height=800");
      ventanaImpresion.document.write('<html><head><title>Prit Booking</title></head><body>');
      ventanaImpresion.document.write('<style>@media print { body { width: 80mm; } }</style>');
      ventanaImpresion.document.write(contenidoDiv);
      ventanaImpresion.document.write('</body></html>');
      ventanaImpresion.document.close();
      ventanaImpresion.print();
    }
  }
}
</script>

<style>
  @import './assets/phoca-flags.css';
  @import './assets/phoca-styles.css';
  body{
    font-family: Roboto Condensed;
    overflow-x: hidden;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }


  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .accordion-button:after { 
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
</style>
