<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("request_withdrawal")}}</h5>
    </div>
    <div v-if="response.result=='Ok'" class="alert alert-success fade show" role="alert">
      {{ response.msg }}
    </div>
    <div v-if="response.result=='error'" class="alert alert-danger fade show" role="alert">
      {{ response.msg }}
    </div>
    <div class="row p-2">
        <form @submit.prevent="confirmRequest()" class="col-12 col-lg-6">
            <span>{{translate("balance_availble")}}</span>&nbsp;
            <span>{{new Intl.NumberFormat("de-DE").format(user.user_data.data.bonus_balance)+' '+user.user_data.data.currency}}</span>
            <div class="py-2">
                <label for="amount">{{translate("amount_withdraw")}}</label>
                <input v-model="amount" type="number" step="any" class="form-control" placeholder="0" id="amount" required>
                <div v-if="skin.my_account.request_payment_target.enabled">
                  <label for="type">{{translate("payment_type")}}</label>
                  <select class="form-control" name="type" v-model="payment_type" id="type" required>
                      <option v-for="(payment_type,idx) in skin.my_account.request_payment_target.types" :key="idx" :value="payment_type">{{translate(payment_type)}}</option>
                  </select>
                  <div v-if="payment_type=='PIX'">
                    <label for="account_type">{{translate("account_type")}}</label>
                    <select class="form-control" name="type" v-model="account_type" id="account_type" required>
                      <option value="email">Email</option>
                      <option value="phone">{{translate("phone")}}</option>
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                      <option value="random_key">{{translate("random_key")}}</option>
                    </select>
                    <label for="payment_description">{{translate("payment_description_pix")}} {{ account_type!=""?" - "+translate(account_type).toUpperCase():"" }}</label>
                    <input v-model="payment_description" type="text" class="form-control" :placeholder="account_type!=''?translate(account_type).toUpperCase():''" id="payment_description" required>
                    <label for="account_owner_name">{{translate("account_owner_name")}}</label>
                    <input v-model="account_owner_name" type="text" class="form-control" :placeholder="translate('account_owner_name')" id="account_owner_name" required>
                    <label for="account_owner_dni">{{translate("account_owner_cpf")}}</label>
                    <input v-model="account_owner_dni" type="text" class="form-control" :placeholder="translate('account_owner_cpf')" id="account_owner_dni" required>
                  </div>
                  <div v-else-if="payment_type=='bank_transfer'">
                    <div v-if="skin.my_account.request_payment_target[payment_type]">
                      <label>{{translate('bank')}}</label>
                      <select class="form-control" name="type" v-model="bank" id="bank_name" required>
                        <option v-for="(option, index) in skin.my_account.request_payment_target[payment_type].banks" :key="index" :value="option">{{ option }}</option>
                      </select>
                      <label>{{translate('account_type')}}</label>
                      <select class="form-control" name="type" v-model="account_type" id="bank_name" required>
                        <option v-for="(option, index) in skin.my_account.request_payment_target[payment_type].account_types" :key="index" :value="option">{{ option }}</option>
                      </select>
                    </div>
                    <label for="description">{{translate("payment_description")}}</label>
                    <input v-model="payment_description" type="text" class="form-control" :placeholder="translate('bank_account_data')" id="payment_description" required>
                    <label for="account_owner_name">{{translate("account_owner_name")}}</label>
                    <input v-model="account_owner_name" type="text" class="form-control" :placeholder="translate('account_owner_name')" id="account_owner_name" required>
                    <label for="account_owner_dni">{{translate("account_owner_dni")}}</label>
                    <input v-model="account_owner_dni" type="text" class="form-control" :placeholder="translate('account_owner_dni')" id="account_owner_dni" required>
                  </div>
                </div>
            </div>
            <div class="py-4">
                <button type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="request_inProcess">{{translate("confirm")}}</button>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:"request_payment",
    props:["skin","language","translation"],
    data(){
      return{
        request_inProcess:false,
        amount:0,
        payment_type:"",
        payment_description:"",
        response:{result:"",msg:""},
        bank:"",
        account_type:"",
        account_owner_name:"",
        account_owner_dni:""
      }
    },
    methods:{
      async confirmRequest(){
        this.request_inProcess = true
        let plataforma = this.$store.getters['getConfig'].main.site_name;
        let data = {"command":"retirar","params":{
          "valor":this.amount,
          "usuario":"",
          "plataforma":plataforma,
          "wallet":"",
          "tipo":this.payment_type,
          "celular":"",
          "email":this.user.user_data.data.email,
          "extra_data":{
            "account_owner_name":this.account_owner_name,
            "account_owner_dni":this.account_owner_dni,
            "account_type":this.account_type,
            "account_value":this.payment_description,
            "account_entity":this.bank
          }
        }}
        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.response.result="Ok";
          this.response.msg=response.data.msg;
          this.amount="";
          this.payment_type="";
          this.account_owner_name = "";
          this.account_owner_dni = "";
          this.account_type = "";
        }
        else if(response.data.result == 'error'){
          this.response.result="error";
          this.response.msg=response.data.msg;
          if(response.data.needValidation){
            this.$root.$emit('send_phone_code');
          }
        }
        this.request_inProcess = false;
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    },
    watch:{
      account_type(newVal){
        if(newVal=="email"){
          this.payment_description = this.user.user_data.data.email;
          this.account_owner_name = this.user.user_data.data.name
          this.account_owner_dni = this.user.user_data.data.doc_number;
          document.getElementById("payment_description").readOnly = true;
          document.getElementById("account_owner_name").readOnly = true;
          document.getElementById("account_owner_dni").readOnly = true;
        }
        else if(newVal=="phone"){
          this.payment_description = this.user.user_data.data.phone;
          this.account_owner_name = this.user.user_data.data.name
          this.account_owner_dni = this.user.user_data.data.doc_number;
          document.getElementById("payment_description").readOnly = true;
          document.getElementById("account_owner_name").readOnly = true;
          document.getElementById("account_owner_dni").readOnly = true;
        }
        else if(newVal=="cpf"){
          this.payment_description = this.user.user_data.data.doc_number;
          this.account_owner_name = this.user.user_data.data.name
          this.account_owner_dni = this.user.user_data.data.doc_number;
          document.getElementById("payment_description").readOnly = true;
          document.getElementById("account_owner_name").readOnly = true;
          document.getElementById("account_owner_dni").readOnly = true;
        }
        else{
          document.getElementById("payment_description").readOnly = false;
          document.getElementById("account_owner_name").readOnly = true;
          document.getElementById("account_owner_dni").readOnly = true;
          this.account_owner_name = this.user.user_data.data.name
          this.account_owner_dni = this.user.user_data.data.doc_number;
          this.payment_description = "";
        }
      }
    }
}
</script>

<style>

</style>