<template>
  <div class="d-flex p-0" :style="skin.fondo2+skin.borde_round2" style="white-space: nowrap;overflow-x:auto;overflow-y:hidden;font-size:small;width:100%">
        <div @click="start()" style="cursor:pointer;" :style="skin.fondo3" class="py-2 pb-2 px-3 pe-3">
            <span :style="skin.text3"><i class="fas fa-arrow-left"></i></span>
        </div>
        <div class="py-2 pb-2 px-3 pe-0">
            <span @click="start()" :style="skin.text1" style="cursor:pointer;">INICIO</span>&nbsp;&nbsp; 
        </div>
        <div 
            v-for="(link,index) in navigation"
            v-bind:key="index"
            class="py-2 pb-2 px-0 pe-1"
        >
            <span :style="skin.text1"><i class="fas fa-caret-right"></i></span>&nbsp;&nbsp; 
            <span :style="skin.text1">{{ link.name }}</span>
        </div>
  </div>
</template>

<script>
export default {
    name: "navigation_bar",
    props:["skin","translate","language","navigation"],
    data(){
        return {

        }
    },
    methods:{
        start(){
            let data = this.$store.getters['prematch/getLigasArray2'];
            Object.keys(data).forEach(liga => {
                this.$root.$emit('uncheck_liga',data[liga].liga.id);
            });
            let check_countries = document.getElementsByClassName('check_country_prematch');
            [...check_countries].forEach(item=>{
                if(item.checked){
                    item.checked = false;
                }
            });
            this.$store.dispatch('prematch/delete_all_ligas');
            this.$store.dispatch('setLobbyView','main');
        }
    }
}
</script>

<style>

</style>