<template>
  <div :id="'acordion-prematch2'+ deporte.id" class="accordion mb-1 p-1" :style="Skin.menu_prematch.sport_fondo">
    <div class="accordion-item" :style="Skin.menu_prematch.sport_fondo+'border:none'">
      <div class="accordion-header">
        <span :class="Skin.menu_prematch.sport_fondo"></span>
        <button class="accordion-button collapsed p-0" :style="Skin.menu_prematch.sport_fondo+Skin.menu_prematch.sport_text" type="button" data-bs-toggle="collapse" :data-bs-target="'#d-prematch2'+ deporte.id" :aria-expanded="(deporte['alias']=='Soccer'?'true':'false')" :aria-controls="'d-prematch2'+ deporte.id">
          <div style="display:flex;width:100%">
            <div style="width:10%" class="mx-1">
              <div class="icon_futbol" style="width:20px;height:20px" v-if="deporte.id==1"></div>
              <div class="icon_hockey" style="width:20px;height:20px" v-else-if="deporte.id==2"></div>
              <div class="icon_basket" style="width:20px;height:20px" v-else-if="deporte.id==3"></div>
              <div class="icon_tenis" style="width:20px;height:20px" v-else-if="deporte.id==4"></div>
              <div class="icon_beisball" style="width:20px;height:20px" v-else-if="deporte.id==11||deporte.id==104"></div>
              <div class="icon_futsal" style="width:20px;height:20px" v-else-if="deporte.id==26 || deporte.id==24"></div>
              <div class="icon_tenis_mesa" style="width:20px;height:20px" v-else-if="deporte.id==41"></div>
              <div class="icon_eGames" style="width:20px;height:20px" v-else-if="deporte.id==73||deporte.id==74||deporte.id==75||deporte.id==76||deporte.id==77||deporte.id==80||deporte.id==82||deporte.id==83||deporte.id==71||deporte.id==158||deporte.id==260||deporte.id==208||deporte.id==185"></div>
              <div class="icon_basket" style="width:20px;height:20px" v-else-if="deporte.id==205"></div>
              <div class="icon_badminton" style="width:20px;height:20px" v-else-if="deporte.id==9"></div>
              <div class="icon_balon_mano" style="width:20px;height:20px" v-else-if="deporte.id==29"></div>
              <div class="icon_rugby" style="width:20px;height:20px" v-else-if="deporte.id==37"></div>
              <div class="icon_snoker" style="width:20px;height:20px" v-else-if="deporte.id==39"></div>
              <div class="icon_dardos" style="width:20px;height:20px" v-else-if="deporte.id==22"></div>
              <div class="icon_volleyball" style="width:20px;height:20px" v-else-if="deporte.id==14"></div>
              <div class="icon_cricket" style="width:20px;height:20px" v-else-if="deporte.id==19"></div>
              <div class="icon_waterpolo" style="width:20px;height:20px" v-else-if="deporte.id==42"></div>
              <div class="icon_golf" style="width:20px;height:20px" v-else-if="deporte.id==27"></div>
              <div class="icon_rugby1" style="width:20px;height:20px" v-else-if="deporte.id==36"></div>
              <div class="icon_volleyball" style="width:20px;height:20px" v-else-if="deporte.id==5"></div> 
              <div class="icon_futAmericano" style="width:20px;height:20px" v-else-if="deporte.id==6"></div> 
              <div class="icon_futbol_australiano" style="width:20px;height:20px" v-else-if="deporte.id==8"></div>
              <div class="icon_boxeo" style="width:20px;height:20px" v-else-if="deporte.id==17"></div>
              <div class="icon_ciclismo" style="width:20px;height:20px" v-else-if="deporte.id==21"></div>
              <div class="icon_race" style="width:20px;height:20px" v-else-if="deporte.id==25 || deporte.id==33 || deporte.id==32 || deporte.id==101"></div>
              <div class="icon_mma" style="width:20px;height:20px" v-else-if="deporte.id==44"></div>
              <div class="icon_hurling" style="width:20px;height:20px" v-else-if="deporte.id==70"></div>
              <div class="icon_squash" style="width:20px;height:20px" v-else-if="deporte.id==88"></div>
              <div class="icon_specials" style="width:20px;height:20px" v-else-if="deporte.id==90"></div>
              <div class="icon_specials" style="width:20px;height:20px" v-else></div>
            </div>
            <div style="width:60%"><span class="long_text">{{ language ? deporte['name'] : deporte['alias'] }}</span></div>
            <div style="width:20%"><span style="float:right;" class="me-2"></span></div>
            <div style="width:10%"><span style="float:right" :style="Skin.menu_prematch.sport_count">{{ qty_eventos }}</span></div>
          </div>
        </button>
      </div>
      <div :id="'d-prematch2'+ deporte.id" class="accordion-collapse collapse"  aria-labelledby="headingOne" :data-bs-parent="'#acordion-prematch2'+ deporte.id">
        <div class="accordion-body p-0" style="">
          <evento-pais-prematch
                v-for="(pais) in regiones"
                v-bind:region="pais"
                v-bind:deporte="{id:deporte.id,name:deporte.name}"
                v-bind:skin="Skin"
                v-bind:key="pais.id"
                >
          </evento-pais-prematch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventoPaisPrematch from './Evento_pais_prematch2.vue';
export default{
  name:"eventoDeportePrematch",
  components:{eventoPaisPrematch},
  data(){
    return {
      
    }
  },
  props:['deporte','Skin','translation','language'],
  computed:{
    regiones(){
      let regions = [];
      let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegionsPrematch;
      if(this.$props.deporte.region){
        Object.keys(this.$props.deporte.region).forEach(region=>{
          if(Object.keys(this.$props.deporte.region[region]).length>0){
            if(!regions_banned.includes(this.$props.deporte.region[region].alias)){
              regions.push(this.$props.deporte.region[region]);
            }
          }
        });
      }
      regions.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
      return regions
    },
    qty_eventos(){
      let count = 0;
      let competitions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideCompetitionsPrematch; 
      let regions_banned = this.$store.getters['getConfig'].SkinConfig.main.hideRegionsPrematch;
      if(this.$props.deporte.region){
        Object.keys(this.$props.deporte.region).forEach(key => {
          if(this.$props.deporte.region[key] && this.$props.deporte.region[key].competition && !regions_banned.includes(this.$props.deporte.region[key].alias)){
            Object.keys(this.$props.deporte.region[key].competition).forEach(key2 => {
              let banned1_check = false;
              if(this.$props.deporte.region[key].competition[key2] && this.$props.deporte.region[key].competition[key2].game){
                competitions_banned.forEach(word => {
                  if(this.$props.deporte.region[key].competition[key2].name?.includes(word)??false){
                    banned1_check = true;
                  }
                });
              }
              if(this.$props.deporte.region[key].competition[key2] && this.$props.deporte.region[key].competition[key2].game && !banned1_check){
                Object.keys(this.$props.deporte.region[key].competition[key2].game).forEach(key3 => {
                  if(this.$props.deporte.region[key].competition[key2].game[key3] && this.$props.deporte.region[key].competition[key2].game[key3].info){
                    let check_favorite = false;
                    this.$store.getters['prematch_2/getFavorites'].forEach(item => {
                      if(item.id == key3){
                        check_favorite = true;
                      }
                    });
                    if(!check_favorite){this.$set(this.$props.deporte.region[key].competition[key2].game[key3],"favorite",false);}
                    count++;
                  }
                })
              }
            })
          }
        })
      }
      return count;
    },
    favorites(){
      return this.$store.getters['prematch_2/getFavorites'].length;
    }
  },
}
</script>
<style scoped>
  .long_text {
      display: -webkit-box;
      max-width: 150px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
</style>
