<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{ user_data.nombre }}</h5>
    </div>
    <records
    v-bind:skin="skin"
    v-bind:language="language"
    v-bind:translation="translation"
    v-bind:user_id="user_data"
    />
  </div>
</template>

<script>
import records from '../../main_lobby/main_records/main_records.vue';
export default {
    name:"user_records",
    props:["skin","language","translation","user_data"],
    components:{records},
    data(){
      return{
      }
    },
}
</script>

<style>

</style>