<template>
    <div class="m-1">
        <div v-if="skin.banners.main_banner_source?(skin.banners.main_banner_source=='jbets'?true:false):true">
              <carousel  
              :paginationEnabled="false"
              :paginationPosition="'bottom-overlay'"
              :paginationActiveColor="'#FAD320'"
              :autoplay="true"
              :autoplayTimeout="4000"
              :navigationEnabled="false"
              :loop="true"
              :autoplayHoverPause="true"
              :perPageCustom="[[300, 1],[451, 1],[1024, 1]]">
                  <slide v-for="(slide,idx) in skin.banners.main_banner.slides" v-bind:key="idx" style="cursor:pointer;">
                    <img :src="slide.url" alt="" style="width:100%;" @click="select_event(slide)">
                  </slide>
              </carousel>
        </div>
        <div v-else :style="screen_width<skin.break_point?'position:relative;'+skin.banners.main_banner_style_mobile:'position:relative;'+skin.banners.main_banner_style_desktop" style="overflow:hidden">
            <!-- Carrousel Horizontal -->
            <iframe :src="skin.banners.main_banner_source" frameborder="0" :style="screen_width<skin.break_point?main_banner_style_mobile:skin.banners.main_banner_style_desktop" scrolling="no" id="myIframe" style="overflow: hidden;"></iframe>
        </div>
    </div>
  </template>
  
  <script>
  import router from '../../../../router/index';
  import { Carousel, Slide } from 'vue-carousel';
  
  export default {
    name:"main_events",
    props:['skin','translation','language'],
    components:{
        Carousel,
        Slide
    },
    data(){
        return{
        screen_width:0,
        }
    },
    methods:{
    async select_event(game){
        try {
            let event_data = null;
            let isLive = false;
            let event_data_prematch = await this.$store.dispatch('prematch_2/getPrematchEvent',{game_id:parseInt(game.game_id),sport_id:game.sport_id});
            if(event_data_prematch){
                event_data_prematch.sport = game.sport_id;
                event_data_prematch.liga = game.competition_name;
                event_data_prematch.sport_alias = game.sport_name;
                event_data=event_data_prematch;
            }
            else{
            let event_data_live = await this.$store.dispatch('live/getLiveEvent',{game_id:parseInt(game.game_id),sport_id:game.sport_id}); 
            if(event_data_live){event_data=event_data_live;isLive=true;}
            }
            if(event_data){
                    if(!isLive){
                        if(router.history.current.name === 'Live_ecuabets' || router.history.current.name === 'LiveMainEcuabets'){
                            router.push({ name: 'Main_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                event_data.sport = game.sport_id;
                                self.$store.dispatch('setLobbyView','prematch2_evento');
                                self.$root.$emit('show_prematch2_event',event_data);
                                self.$store.dispatch('prematch_2/unsuscribe_all_events');
                                self.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        else {
                            this.$store.dispatch('setLobbyView','prematch2_evento');
                            this.$root.$emit('show_prematch2_event',event_data);
                            this.$store.dispatch('prematch_2/unsuscribe_all_events');
                            this.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                        }
                    }
                    else{
                        if(router.history.current.name !== 'Live_ecuabets' && router.history.current.name !== 'LiveMainEcuabets'){
                            router.push({ name: 'Live_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                if(Object.keys(self.$store.getters['live/getLiveEvent']).length > 0){
                                    self.$store.dispatch('live/unsuscribe_event');
                                }
                                self.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        if(this.$store.getters['live/getTargetEventId'] !=  game.game_id){
                            if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                                this.$store.dispatch('live/unsuscribe_event');
                            }
                            this.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                        }
                    }
                    if(document.getElementById("myAccountCloseBtn")){
                        document.getElementById("myAccountCloseBtn").click();
                    }
                }
        } catch (error) {
            console.log(error);
        }
    },
    sizeControl(){
        this.screen_width=window.innerWidth;
    },
    },
    computed:{
    user(){
        return this.$store.getters['getUserData'];
    },
    },
    mounted(){
    window.addEventListener('resize', ()=>{this.sizeControl();});
    },
    created(){
        this.sizeControl();
    },
  }
  </script>
  
  <style>
  
  </style>