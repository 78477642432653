<template>
    <div class="m-1 mb-2" :style="skin.main_leagues.body" style="overflow:hidden">
        <div class="p-2" :style="skin.main_leagues.header">{{translate("main_leagues")}}</div>
        <div
            v-for="liga in ligas.leagues"
            v-bind:key="liga.id"
            class="p-2"
            style="cursor:pointer"
            @click="select_liga(liga)"
        >
            <span class="rounded-circle me-2" :class="'phoca-flag ' + country_flag(liga.country_alias)" style="height:20px;width:20px"></span>
            <span>{{ liga.name }}</span>
        </div>
    </div>
</template>

<script>
const flags = require('../../../../assets/countries.json');
export default {
    name:"main_ligas",
    props:["skin","translation","language"],
    data(){
        return{
            ligas_id:[2988,2985,566,1861,543,548,1834,538,545,541,1792
            ],
            ligas_countries:["Brazil","Colombia"],
            sport: 1
        }
    },
    methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        select_liga(data){
            this.$store.dispatch('setLobbyView','prematch2_ligas');
            this.check=!this.check;
            let payload = {
                league:data.id,
                country:data.country_id,
                league_name:data.name,
                country_name:data.country,
                sport_id:1,
                sport_name:this.translate(data.sport_name)
            };
            // console.log(data,payload)
            this.$store.dispatch('prematch_2/add_remove_liga',payload);
        },
        country_flag(pais) {
          let flag = ""
          flags.countries.forEach(country => {
              if(country.name_es == pais || country.name_en == pais){
                  flag = country.code.toLowerCase()
              }
          });
          return flag;
      }, 
    },
    computed:{
        ligas(){
            var list={
                leagues:[],
                sports:{}
            };
            let sports = this.$store.getters['prematch_2/getSports'];
            if(this.$props.skin.main_leagues.target_leagues){
                this.ligas_id = this.$props.skin.main_leagues.target_leagues;
            }
            Object.keys(sports).forEach(sport=>{
                let data = sports[sport];
                if(data ? (data.region ? true : false) : false){
                    Object.keys(data.region).forEach(region=>{
                        this.ligas_id.forEach(liga_id=>{
                            if(data.region[region].competition ? true : false ){
                                if(liga_id in data.region[region].competition){
                                    data.region[region].competition[liga_id].country = data.region[region].name;
                                    data.region[region].competition[liga_id].country_alias = data.region[region].alias;
                                    data.region[region].competition[liga_id].country_name = data.region[region].name;
                                    data.region[region].competition[liga_id].sport_id = data.id;
                                    data.region[region].competition[liga_id].sport_name = data.alias;
                                    list.leagues.push(data.region[region].competition[liga_id]);
                                }
                                if(liga_id in data.region[region].competition){
                                    list.sports[data.id] = {id:data.id};
                                }
                            }
                        }); 
                    });
                }
            })
            return list;
        }
    }
}
</script>

<style>

</style>