import { render, staticRenderFns } from "./tenis.vue?vue&type=template&id=2f9bd706&scoped=true"
import script from "./tenis.vue?vue&type=script&lang=js"
export * from "./tenis.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9bd706",
  null
  
)

export default component.exports