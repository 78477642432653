import _get from 'lodash.get';
import Vue from 'vue';
import axios from 'axios';
//const live_data = require('../assets/liveData.json');
//const live_event = require('../assets/liveEvent.json');
export default {
    namespaced: true,
    state: {
        liveData:{},
        liveEvent:{},
        searchTeamResult:{},
        searchCompetitionResult:{},
        loadedLiveData:false,
        loadedEventData:false,
        follow_id:"",
        liveFavorites:[],
        competitions_rid: "",
        live_event_rid: "",
        live_bets_rids:[],
        live_bets_subids:[],
        ligas_array:[],
        competitions_subid:"",
        live_event_subid:"",
        target_event_id:"",
        search_team_rid:"",
        search_competition_rid:"",
    },
    mutations:{
        add_remove_liga(state,payload){
            let exists = false;
            state.ligas_array.forEach(item=>{
                if(payload.league == item.league){
                    state.ligas_array.splice(state.ligas_array.indexOf(item),1);
                    exists = true;
                }
            });
            if(!exists){
                state.ligas_array.push(payload);
            } 
        },
        remove_ligas(state){
            state.ligas_array = [];
        },
        setLiveData(state, payload){
            state.liveData = payload;
            if(Object.keys(state.liveEvent).length == 0){
                this.commit('live/setTargetEvent');
            }
        },
        setTargetEvent(state,payload){
            payload = payload ? payload : 1;
            let data = state.liveData.data.data.sport[payload]?.region;
            if(!data){data=state.liveData.data.data.sport[3].region;}
            if(!data){data=state.liveData.data.data.sport[2].region;}
            let competitions_banned = this.getters.getConfig.SkinConfig.main.hideCompetitions; 
            let regions_banned = this.getters.getConfig.SkinConfig.main.hideRegions;
            let words_banned = this.getters.getConfig.SkinConfig.main.hideWords;
            let regions = [];
            let ligas = [];
            let games = [];

            Object.keys(data).forEach(pais => {
                let region_check = false
                if(Object.keys(data[pais]).length>0){
                    if(regions_banned.includes(data[pais].alias)){
                        region_check = true; 
                    }
                    if(!region_check){regions.push(data[pais]);}
                }
            });
            regions = regions.filter(item => item != null);
            regions.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            regions.forEach(region =>{
                if(region.competition){
                    Object.keys(region.competition).forEach(liga=>{
                        if(region.competition[liga] ? (region.competition[liga].name ? true : false) : false){
                            let competition_banned =false;
                            competitions_banned.forEach(word => {
                                if(region.competition[liga].name.includes(word)){
                                    competition_banned = true;
                                }
                            });
                            if(!competition_banned){ligas.push(region.competition[liga]);}
                        }
                    });
                }
            });
            ligas.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            ligas.forEach(liga=>{
                Object.keys(liga.game).forEach(game =>{
                    let game_banned = false;
                    if(liga.game[game]){
                        words_banned.forEach(word => {
                            if(liga.game[game].team1_name && liga.game[game].team2_name){
                                if((liga.game[game].team1_name.includes(word) || liga.game[game].team2_name.includes(word)) && word!=''){
                                    game_banned = true;
                                }
                            }
                        });
                        if(!game_banned){
                            games.push(liga.game[game]);
                        }
                    }
                })
            });
            games.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            if(games.length > 0){
                let target_game = games[0].id;
                this.commit('live/suscribe_event', target_game);
            }
            else{
                //this.commit('live/setTargetEvent',3);
            }
        },
        updateLiveData(state, payload){
            var objetivo = state.liveData;
            let updates = [];
            var missed_keys = [];
            let target = payload;
            function changes(obj){
                Object.keys(obj).forEach(value => {
                    if(obj[value] instanceof Object){
                        for(var prop in obj[value]){
                            if(prop == "id"){
                                let change = [];
                                change[obj[value][prop]] = obj[value];
                                let path = getPath(payload,obj[value][prop],obj[value]);
                                change["path"]= path;
                                updates.push(change);
                                break;
                            }
                            if(obj[value][prop] instanceof Object){
                                changes(obj[value]);
                                break;
                            }
                            else{
                                let change = [];
                                change[prop] = obj[value][prop];
                                let path = getPath(payload,prop,obj[value][prop]);
                                change["path"]= path;
                                updates.push(change);
                                obj[value][prop] = null;
                            }
                        }
                    }
                    else{
                        let change = [];
                        change[value] = obj[value];
                        let path = getPath(payload,value,obj[value]);
                        change["path"]= path;
                        updates.push(change);
                        obj[value] = null;
                    }
                });
            }
            function getPath(ob,key, value) {
                const path = [];
                const keyExists = (obj) => {
                    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                        return false;
                    } else if (obj.hasOwnProperty(key) && obj[key] === value) {
                        return true;
                    } else if (Array.isArray(obj)) {
                        let parentKey = path.length ? path.pop() : "";
        
                        for (let i = 0; i < obj.length; i++) {
                            path.push(`${parentKey}[${i}]`);
                            const result = keyExists(obj[i], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    } else {
                        for (const k in obj) {
                            path.push(k);
                            const result = keyExists(obj[k], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    }
                    return false;
                };
                keyExists(ob);
                return path.join(".");
            }
            function check_path(path_array){
                let path_str = path_array.join(".");
                let result = false;
                function check(path){
                    for (let i = 0; i < path.length; i++) {
                        if(!_get(objetivo,path_str)){
                            let missed_key = path_array.pop();
                            missed_keys.unshift(missed_key);
                            result = check_path(path_array);
                            if(result){
                                break;
                            }
                        }
                        if(_get(objetivo,path_str)){
                            return path_str;
                        }
                    }
                    return result;
                }
                return check(path_array);
            }
            changes(target.data[state.competitions_subid].sport);
            updates.forEach(update => {
                missed_keys = [];
                let path_array = update.path.split(".");
                if(!(path_array.includes('data'))){
                    path_array.unshift('data');
                }
                path_array = path_array.map(n => n === state.competitions_subid || n === state.live_event_subid ? n = 'data': n);
                if(typeof update !== 'undefined'){
                    let key = Object.keys(update)[0];
                    let checked_path = check_path(path_array) ? check_path(path_array) : path_array.join(".");
                    if(update[key] instanceof Object){
                        try {
                            if(missed_keys.length > 0){
                                for (let index = 0; index < missed_keys.length; index++) {
                                    const missed_key = missed_keys[index];
                                    if(index == missed_keys.length - 1){
                                        Vue.set(_get(objetivo,checked_path),missed_key,Object.fromEntries([[key,update[key]]])); 
                                    }
                                    else{
                                        Vue.set(_get(objetivo,checked_path),missed_key,{}); 
                                    }
                                    checked_path += "."+missed_key;
                                }
                            }
                            else{
                                Vue.set(_get(objetivo,checked_path),key,update[key]); 
                            }
                        } catch (error) {
                            console.error(error,update,checked_path,"["+new Date().toLocaleString('en-DE')+"]");
                        }
                    }
                    else{
                        if(update[key] != null ){
                            try {
                                if(missed_keys.length > 0){
                                    for (let index = 0; index < missed_keys.length; index++) {
                                        const missed_key = missed_keys[index];
                                        if(index == missed_keys.length - 1){
                                            if(_get(objetivo,checked_path)){
                                                _get(objetivo,checked_path)[missed_key] =Object.fromEntries([[key,update[key]]]); 
                                            }
                                        }
                                        else{
                                            if(_get(objetivo,checked_path)){
                                                _get(objetivo,checked_path)[missed_key] = Object.fromEntries([[missed_key,{}]]);
                                            }
                                        }
                                        checked_path += "."+missed_key;
                                    }
                                }
                                else{
                                    _get(objetivo,checked_path)[key] = update[key];
                                }
                            } catch (error) {
                                console.error(error,update,checked_path,"["+new Date().toLocaleString('en-DE')+"]");
                            }
                            if(key == "current_game_state" && update[key] == "finished"){
                                path_array.pop();
                                let event_id = path_array.pop();
                                let del_path = path_array.join(".");
                                _get(objetivo,del_path)[event_id] = null;
                                if(state.follow_id != "" && update.path.includes(state.follow_id)){
                                    console.log("evento eliminado:", update,event_id);
                                } 
                            }
                        }
                        else{
                            if(!isNaN(key)){
                                try {
                                    if(missed_keys.length > 0){
                                        for (let index = 0; index < missed_keys.length; index++) {
                                            const missed_key = missed_keys[index];
                                            if(index == missed_keys.length - 1){
                                                _get(objetivo,checked_path)[missed_key] =Object.fromEntries([[key,{}]]);
                                            }
                                            else{
                                                _get(objetivo,checked_path)[missed_key] =Object.fromEntries([[missed_key,{}]]);
                                            }
                                            checked_path += "."+missed_key;
                                        }
                                    }
                                    else{
                                        _get(objetivo,checked_path)[key] = {};
                                    }
                                } catch (error) {
                                    console.error(error,update,checked_path,"["+new Date().toLocaleString('en-DE')+"]");
                                }
                            }
                        }
                    }
                    if(state.follow_id != "" && update.path.includes(state.follow_id)){
                        let today = new Date();
                        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        console.log(update, time);
                    }
                    missed_keys = [];
                }
            });
        },
        updateEventData(state, payload){
            var objetivo = state.liveEvent;
            let updates = [];
            var missed_keys = [];
            let target = payload;
            function changes(obj){
                Object.keys(obj).forEach(value => {
                    if(obj[value] instanceof Object){
                        for(var prop in obj[value]){
                            if(prop == "id"){
                                let change = [];
                                change[obj[value][prop]] = obj[value];
                                let path = getPath(payload,obj[value][prop],obj[value]);
                                change["path"]= path;
                                updates.push(change);
                                break;
                            }
                            if(obj[value][prop] instanceof Object){
                                changes(obj[value]);
                                break;
                            }
                            else{
                                let change = [];
                                change[prop] = obj[value][prop];
                                let path = getPath(payload,prop,obj[value][prop]);
                                change["path"]= path;
                                updates.push(change);
                                obj[value][prop] = null;
                            }
                        }
                    }
                    else{
                        let change = [];
                        change[value] = obj[value];
                        let path = getPath(payload,value,obj[value]);
                        change["path"]= path;
                        updates.push(change);
                        obj[value] = null;
                    }
                });
            }
            function getPath(ob,key, value) {
                const path = [];
                const keyExists = (obj) => {
                    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                        return false;
                    } else if (obj.hasOwnProperty(key) && obj[key] === value) {
                        return true;
                    } else if (Array.isArray(obj)) {
                        let parentKey = path.length ? path.pop() : "";
        
                        for (let i = 0; i < obj.length; i++) {
                            path.push(`${parentKey}[${i}]`);
                            const result = keyExists(obj[i], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    } else {
                        for (const k in obj) {
                            path.push(k);
                            const result = keyExists(obj[k], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    }
                    return false;
                };
                keyExists(ob);
                return path.join(".");
            }
            function check_path(path_array){
                let path_str = path_array.join(".");
                let result = false;
                function check(path){
                    for (let i = 0; i < path.length; i++) {
                        if(!_get(objetivo,path_str)){
                            let missed_key = path_array.pop();
                            missed_keys.push(missed_key);
                            result = check_path(path_array);
                            if(result){
                                break;
                            }
                        }
                        if(_get(objetivo,path_str)){
                            return path_str;
                        }
                    }
                    return result;
                }
                return check(path_array);
            }
            changes(target.data[objetivo.data?.subid].sport);
            updates.forEach(update => {
                let path_array = update.path.split(".");
                if(!(path_array.includes('data'))){
                    path_array.unshift('data');
                }
                path_array = path_array.map(n => n == state.competitions_subid || n == state.live_event_subid ? n = 'data': n);
                if(typeof update !== 'undefined'){
                    let key = Object.keys(update)[0];
                    let checked_path = check_path(path_array) ? check_path(path_array) : path_array.join(".");
                    if(update[key] instanceof Object){
                        Vue.set(_get(objetivo,checked_path),Object.keys(update)[0],update[key]); 
                    }
                    else{
                        if(update[key] != null ){
                            try {
                                if(missed_keys.length == 1){
                                    _get(objetivo,checked_path)[missed_keys[0]] =Object.fromEntries([[key,update[key]]]);
                                }
                                else{
                                    _get(objetivo,checked_path)[key] = update[key];
                                }
                                missed_keys = [];
                            } catch (error) {
                                console.error(error,update,checked_path,"["+new Date().toLocaleString('en-DE')+"]");
                            }
                            if(Object.keys(update)[0] == "current_game_state" && update[Object.keys(update)[0]] == "finished"){
                                path_array.pop();
                                let event_id = path_array.pop();
                                let del_path = path_array.join(".");
                                _get(objetivo,del_path)[event_id] = null;
                                if(state.follow_id != "" && update.path.includes(state.follow_id)){
                                    let today = new Date();
                                    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                                    console.log("evento eliminado:", update,event_id, time);
                                }
                            }
                        }
                        else{
                            if(!isNaN(Object.keys(update)[0])){
                                try {
                                    if(missed_keys.length == 1){
                                        _get(objetivo,checked_path)[missed_keys[0]] =Object.fromEntries([[key,update[key]]]);
                                    }
                                    else{
                                        _get(objetivo,checked_path)[key.toString()] = update[key];
                                    }
                                    missed_keys = [];
                                } catch (error) {
                                    console.log('error',update,checked_path);
                                }  
                            }
                        }
                    }
                    if(state.follow_id != "" && update.path.includes(state.follow_id)){
                        let today = new Date();
                        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        console.log(update, time);
                    }
                }
            });
        },
        updateBetsData(state, payload){
            let game_id = Object.keys(payload.data.game)[0];
            let event_id = "";
            let self = this;
            state.live_bets_subids.forEach(item => {
                if(item.subid == payload.subid){
                    event_id = item.event_id;
                }
            });
            if(!this.getters.getBets[event_id]){return;}
            var objetivo = this.getters.getBets[event_id].game;
            let updates = [];
            var missed_keys = [];
            let target = payload.data;
            function changes(obj){
                Object.keys(obj).forEach(value => {
                    if(obj[value] instanceof Object){
                        for(var prop in obj[value]){
                            if(prop == "id"){
                                let change = [];
                                change[obj[value][prop]] = obj[value];
                                let path = getPath(payload.data,obj[value][prop],obj[value]);
                                change["path"]= path;
                                updates.push(change);
                                break;
                            }
                            if(obj[value][prop] instanceof Object){
                                changes(obj[value]);
                                break;
                            }
                            else{
                                let change = [];
                                change[prop] = obj[value][prop];
                                let path = getPath(payload.data,prop,obj[value][prop]);
                                change["path"]= path;
                                updates.push(change);
                                obj[value][prop] = null;
                                
                            }
                        }
                    }
                    else{
                        let change = [];
                        change[value] = obj[value];
                        let path = getPath(payload.data,value,obj[value]);
                        change["path"]= path;
                        updates.push(change);
                        obj[value] = null;
                    }
                });
            }
            function getPath(ob,key, value) {
                const path = [];
                const keyExists = (obj) => {
                    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                        return false;
                    } else if (obj.hasOwnProperty(key) && obj[key] === value) {
                        return true;
                    } else if (Array.isArray(obj)) {
                        let parentKey = path.length ? path.pop() : "";
        
                        for (let i = 0; i < obj.length; i++) {
                            path.push(`${parentKey}[${i}]`);
                            const result = keyExists(obj[i], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    } else {
                        for (const k in obj) {
                            path.push(k);
                            const result = keyExists(obj[k], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    }
                    return false;
                };
                keyExists(ob);
                return path.join(".");
            }
            function check_path(path_array){
                let path_str = path_array.join(".");
                let result = false;
                function check(path){
                    for (let i = 0; i < path.length; i++) {
                        if(!_get(objetivo,path_str)){
                            let missed_key = path_array.pop();
                            missed_keys.push(missed_key);
                            result = check_path(path_array);
                            if(result){
                                break;
                            }
                        }
                        if(_get(objetivo,path_str)){
                            return path_str;
                        }
                    }
                    return result;
                }
                return check(path_array);
            }
            function check_block(key,value,sport,event_id){
                switch (sport) {
                    case 'Soccer':
                        let reason = self.getters.getBets[event_id].blocked_reason;
                        if(key == "current_game_time" && parseInt(value) > self.getters.getCurrentSkin.live_all_odds.blocked_time){
                            reason = "game time";
                            return true;
                        }
                        if(key == "markets_count" && parseInt(value) == 0){
                            reason = reason=="game time"?"game time":"zero markets";
                            return true;
                        }
                        if(key == "markets_count" && parseInt(value) > 0){
                            if(reason == "game time"){
                                return true;
                            }
                            reason = "";
                            return false;
                        }
                        break;
                
                    default:
                        break;
                }
                return false;
            }
            changes(target);
            updates.forEach(update => {
                let path_array = update.path.split(".");
                if(typeof update !== 'undefined'){
                    //console.log(update,payload.subid);
                    let key = Object.keys(update)[0];
                    let checked_path = check_path(path_array) ? check_path(path_array) : update.path;
                    if(update[key] instanceof Object){
                        if(update.path=="game"){
                            this.getters.getBets[event_id].blocked = false;
                            this.getters.getBets[event_id].blocked_reason = "";
                        }
                        Vue.set(_get(objetivo,checked_path),Object.keys(update)[0],update[key]); 
                    }
                    else{
                        if(update.path=="game" && update[key] == null){
                            this.getters.getBets[event_id].blocked = true;
                            this.getters.getBets[event_id].blocked_reason = "game null";
                        }
                        if(update[key] != null){
                            if(key == "current_game_time" || key == "markets_count"){
                                this.getters.getBets[event_id].blocked = check_block(Object.keys(update)[0],update[Object.keys(update)[0]],this.getters.getBets[event_id].alias,event_id);
                            }
                            if(update[key] != null ){
                                try {
                                    if(missed_keys.length == 1){
                                        _get(objetivo,checked_path)[missed_keys[0]] =Object.fromEntries([[key,update[key]]]);
                                    }
                                    else{
                                        _get(objetivo,checked_path)[key] = update[key];
                                    }
                                    missed_keys = [];
                                } catch (error) {
                                    console.error(error,update,checked_path,"["+new Date().toLocaleString('en-DE')+"]");
                                }
                                if(game_id == state.target_event_id){
                                    let obj = state.liveEvent;
                                    let event_path = getPath(obj,'id',parseInt(game_id));
                                    let path_array_update = event_path.split(".");
                                    path_array_update.pop();
                                    path_array_update.pop();
                                    let new_path = path_array_update.join('.') +'.'+update.path;
                                    if(_get(state.liveEvent,new_path)){
                                        _get(state.liveEvent,new_path)[Object.keys(update)[0]] = update[Object.keys(update)[0]];
                                    }
                                    if(_get(state.liveData,new_path)){
                                        _get(state.liveData,new_path)[Object.keys(update)[0]] = update[Object.keys(update)[0]];
                                    }
                                }
                                else{
                                    let obj = state.liveData;
                                    let event_path = getPath(obj,'id',parseInt(game_id));
                                    let path_array_update = event_path.split(".");
                                    path_array_update.pop();
                                    path_array_update.pop();
                                    let new_path = path_array_update.join('.') +'.'+update.path;
                                    if(_get(state.liveData,new_path)){
                                        _get(state.liveData,new_path)[Object.keys(update)[0]] = update[Object.keys(update)[0]];
                                    }
                                }
                            }
                            else{
                                if(!isNaN(key)){
                                    try {
                                        if(missed_keys.length == 1){
                                            _get(objetivo,checked_path)[missed_keys[0]] =Object.fromEntries([[key,update[key]]]);
                                        }
                                        else{
                                            _get(objetivo,checked_path)[key.toString()] = update[key];
                                        }
                                        missed_keys = [];
                                    } catch (error) {
                                        console.log('error',update,checked_path);
                                    }   
                                }
                            }
                        }
                        
                    }
                    if(state.follow_id != "" && update.path.includes(state.follow_id)){
                        let today = new Date();
                        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        console.log(update, time);
                    }
                }
            });
        },
        setFollowId(state, payload){
            state.follow_id = payload;
        },
        loadedLiveData(state, payload){
            state.loadedLiveData = payload;
        },
        addFavorite(state, payload){
            payload.favorite = true;
            state.liveFavorites.push(payload);
        },
        removeFavorite(state, payload){
            payload.favorite = false;
            let idx = state.liveFavorites.indexOf(payload);
            if(idx > -1){
                state.liveFavorites.splice(idx, 1);
            }
        },
        setLiveEvent(state, payload){
            state.liveEvent = payload;
        },
        request_competitions(state){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.competitions_rid = rid;
            this.getters.getSocket.send(JSON.stringify(
                {
                    "command": "get",
                    "params": {
                        "source": "betting",
                        "what": {
                            "sport": [
                                "id",
                                "name",
                                "alias"
                            ],
                            "region": [
                                "id",
                                "name",
                                "alias",
                                "order"
                            ],
                            "competition": [
                                "id",
                                "name",
                                "order"
                            ],
                            "game": [
                                [
                                    "id",
                                    "start_ts",
                                    "team1_id",
                                    "team2_id",
                                    "team1_name",
                                    "team2_name",
                                    "team1_external_id",
                                    "team2_external_id",
                                    "type",
                                    "info",
                                    "events_count",
                                    "markets_count",
                                    "extra",
                                    "is_blocked",
                                    "game_number",
                                    "exclude_ids",
                                    "is_stat_available",
                                    "is_live",
                                    "game_external_id"
                                ]
                            ],
                            "event": [
                                "id",
                                "price",
                                "type",
                                "name",
                                "base",
                                "baseInitial",
                                "marketType"
                            ],
                            "market": [
                                "type",
                                "express_id",
                                "name"
                            ]
                        },
                        "where": {
                            "market": {
                                "@or": [
                                    {
                                        "type": {
                                            "@in": [
                                                "P1XP2",
                                                "P1P2",
                                                "1X12X2",
                                                "BothTeamsToScore"
                                            ]
                                        }
                                    },
                                    {
                                        "@and": [
                                            {
                                                "type": {
                                                    "@in": [
                                                        "OverUnder"
                                                    ]
                                                }
                                            },
                                            {
                                                "base": {
                                                    "@in": [
                                                        2.5
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            "game": {
                                "type": 1
                            }
                        },
                        "subscribe": true
                    },
                    "rid": rid
                }
            ));
            this.getters.getSocket.send(JSON.stringify(
                {"command":"get","params":{"source":"betting","what":{"game":"@count"},"where":{"game":{"type":{"@in":[0,2]}}},"subscribe":true},"rid":"16415055537204"}
            ));
        },
        suscribe_event(state, payload){
            state.loadedEventData = false;
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.live_event_rid = rid;
            state.target_event_id = payload;
            this.getters.getSocket.send(JSON.stringify(
                { 
                    "command": "get", 
                    "params": 
                    { "source": "betting", 
                    "what": { 
                        "sport": ["id", "name", "alias"], 
                        "competition": ["id", "name"], 
                        "region": ["id", "alias"], 
                        "game": [], 
                        "market": [], 
                        "event": [] }, 
                        "where": { 
                            "game": { "id": payload } 
                        }, 
                        "subscribe": true 
                    }, 
                    "rid": rid 
                }
            ));
        },
        unsuscribe_event(state){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            this.getters.getSocket.send(JSON.stringify(
                {"command":"unsubscribe","params":{"subid":state.live_event_subid},"rid":rid}
            ));
        },
        search_team(state, payload){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.search_team_rid = rid;
            this.getters.getSocket.send(JSON.stringify(
                {
                    "command":"get",
                    "params":{
                        "source":"betting",
                        "what":{
                            "competition":[],
                            "region":[],
                            "game": [
                                [
                                    "id",
                                    "start_ts",
                                    "team1_id",
                                    "team2_id",
                                    "team1_name",
                                    "team2_name",
                                    "team1_external_id",
                                    "team2_external_id",
                                    "type",
                                    "info",
                                    "events_count",
                                    "markets_count",
                                    "extra",
                                    "is_blocked",
                                    "game_number",
                                    "exclude_ids",
                                    "is_stat_available",
                                    "is_live",
                                    "game_external_id"
                                ]
                            ],
                            "sport":["id","name","alias"]
                        },
                        "where":{
                            "game":{
                                "@limit":25,
                                "@or":[{"team1_name":{"@like":{"spa":payload}}},
                                {"team2_name":{"@like":{"spa":payload}}}
                            ]
                        }
                    }
                },
                "rid":rid}
            ));
        },
        search_competition(state, payload){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.search_competition_rid = rid;
            this.getters.getSocket.send(JSON.stringify(
                {
                    "command":"get",
                    "params":{
                        "source":"betting",
                        "what":{
                            "competition":[],
                            "region":[],
                            "game": [
                                [
                                    "id",
                                    "start_ts",
                                    "team1_id",
                                    "team2_id",
                                    "team1_name",
                                    "team2_name",
                                    "team1_external_id",
                                    "team2_external_id",
                                    "type",
                                    "info",
                                    "events_count",
                                    "markets_count",
                                    "extra",
                                    "is_blocked",
                                    "game_number",
                                    "exclude_ids",
                                    "is_stat_available",
                                    "is_live",
                                    "game_external_id"
                                ]
                            ],
                            "sport":["id","name","alias"]
                        },
                        "where":{
                            "competition":{
                                "name":{
                                    "@like":{
                                        "spa":payload
                                    }
                                }
                            },
                            "game":{
                                "@limit":25
                            }
                        }
                    },
                    "rid":rid
                }
            ));
        },
        clear_search(state){
            state.searchCompetitionResult  = {};
            state.searchTeamResult = {};
        },
        suscribe_odd(state, payload){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.live_bets_rids.push({rid:rid,event_id:payload.event_id});
            let check_odd = false;
            Object.keys(this.getters.getBets).forEach(bet=>{
                if(bet == payload.event_id && this.getters.getBets[bet]){check_odd=true;}
            })
            if(!check_odd){
                this.commit('setBets',payload);
            }
            if("mode" in payload){
                if(payload.mode == "socket"){
                    this.getters.getSocket.send(JSON.stringify(
                        {"command":"get","params":{"source":"betting","what":{"game":["id","is_blocked","last_event","team1_name","team2_name","team1_reg_name","team2_reg_name","info","match_length","text_info"],"market":["base","type","name","home_score","away_score"],"event":["id","price","type","type_1","name","base","base1","base2"]},"where":{"game":{"id":{"@in":[payload.game_id]}},"event":{"id":{"@in":[payload.event_id]}}},"subscribe":true},"rid":rid}
                    ));
                }
            }
            else{
                this.getters.getSocket.send(JSON.stringify(
                    {"command":"get","params":{"source":"betting","what":{"game":["id","is_blocked","last_event","team1_name","team2_name","team1_reg_name","team2_reg_name","info","match_length","text_info"],"market":["base","type","name","home_score","away_score"],"event":["id","price","type","type_1","name","base","base1","base2"]},"where":{"game":{"id":{"@in":[payload.game_id]}},"event":{"id":{"@in":[payload.event_id]}}},"subscribe":true},"rid":rid}
                ));
            }
        },
        unsuscribe_odd(state, payload){
            let rid = "16" + Math.floor(100000000000 + Math.random() * 900000000000).toString();
            state.live_bets_subids.forEach(item => {
                if(item.event_id == payload){
                    this.getters.getSocket.send(JSON.stringify(
                        {"command":"unsubscribe","params":{"subid":item.subid},"rid":rid}
                    ));
                    let index = state.live_bets_subids.indexOf(item);
                    state.live_bets_subids.splice(index,1);
                }
            });
            this.commit('deleteBet',payload,{root:true})
        },
    },
    actions:{
        async setLiveData(state, payload){
            await state.commit("setLiveData", payload)
        },
        async add_remove_liga(state, payload){
            await state.commit("add_remove_liga", payload)
        },
        async remove_ligas(state){
            await state.commit("remove_ligas")
        },
        async updateLiveData(state, payload){
            await state.commit("updateLiveData", payload)
        },
        async setFollowId(state, payload){
            await state.commit("setFollowId", payload)
        },
        async addFavorite(state, payload){
            await state.commit("addFavorite", payload)
        },
        async removeFavorite(state, payload){
            await state.commit("removeFavorite", payload)
        },
        async loadedLiveData(state, payload){
            await state.commit("loadedLiveData", payload)
        },
        async suscribe_event(state, payload){
            await state.commit("suscribe_event", payload)
        },
        async unsuscribe_event(state){
            await state.commit("unsuscribe_event")
        },
        async search_competition(state, payload){
            await state.commit("search_competition", payload)
        },
        async search_team(state, payload){
            await state.commit("search_team", payload)
        },
        async suscribe_odd(state, payload){
            await state.commit("suscribe_odd", payload)
        },
        async unsuscribe_odd(state, payload){
            await state.commit("unsuscribe_odd", payload)
        },
        async setTargetEvent(state,payload){
            await state.commit("setTargetEvent", payload)
        },
        async clearSearch(state){
            await state.commit("clear_search");
        },
        async getLiveEvent(state,payload){
            function getPath(ob,key, value) {
                const path = [];
                const keyExists = (obj) => {
                    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                        return false;
                    } else if (obj.hasOwnProperty(key) && obj[key] === value) {
                        return true;
                    } else if (Array.isArray(obj)) {
                        let parentKey = path.length ? path.pop() : "";
        
                        for (let i = 0; i < obj.length; i++) {
                            path.push(`${parentKey}[${i}]`);
                            const result = keyExists(obj[i], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    } else {
                        for (const k in obj) {
                            path.push(k);
                            const result = keyExists(obj[k], key);
                            if (result) {
                            return result;
                            }
                            path.pop();
                        }
                    }
                    return false;
                };
                keyExists(ob);
                return path.join(".");
            }
            let event_path = getPath(state.state.liveData.data.data.sport[payload.sport_id],"id",payload.game_id);
            let event_data = _get(state.state.liveData.data.data.sport[payload.sport_id],event_path);
            return event_data;
        },
    },
    getters:{
        getLiveData: state => state.liveData,
        getLigasArray: state => state.ligas_array,
        getLiveEvent: state => state.liveEvent,
        getFavorites: state => state.liveFavorites,
        getLoadedLiveData: state => state.loadedLiveData,
        getTargetEventId: state => state.target_event_id,
        getConfig: (state,getters,rootState,rootGetters) => rootGetters['getConfig'],
        getSearchTeam: state => state.searchTeamResult,
        getSearchCompetition: state => state.searchCompetitionResult,
        getLoadedEventData: state => state.loadedEventData,
        getSkin: (state,getters,rootState,rootGetters) => rootGetters['getCurrentSkin'],
        getSports: (state,getters,rootState,rootGetters) => {
            let sports = {};
            if(state.loadedLiveData){
                let sports_banned = rootGetters['getConfig'].SkinConfig.main.hideSports;
                Object.keys(state.liveData.data.data.sport).forEach(sport => {
                    if(state.liveData.data.data.sport[sport] && Object.keys(state.liveData.data.data.sport[sport]).length>0){
                        if(!sports_banned.includes(state.liveData.data.data.sport[sport].alias)){
                            sports[sport] = state.liveData.data.data.sport[sport];
                        }
                    }
                });
            }
           return sports;
        },
        getSocket: (state,getters,rootState,rootGetters) => rootGetters['getSocket'],
    }
}