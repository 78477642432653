<template>
<div>
    <div v-if="menu_visible" :style="skin.fondo3">
       <ul class="nav p-1">
            <li v-for="deporte in deportes.main"
                v-bind:key="deporte.id"
                :id="deporte.id"
                style="cursor:pointer"
                class="nav-item nav-pre" :class="deporte.id == 1 ? 'nav_active': ''"
                @click="select_sport(deporte.id)">
                <span class="m-2">{{ deporte['name_'+language] }}</span>
            </li>
            <li class="nav-item dropdown">
                <span class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">{{translate("others")}}</span>
                <ul class="dropdown-menu">
                    <li v-for="deporte in deportes.other"
                        v-bind:key="deporte.id"
                        @click="select_sport(deporte.id)"
                        :id="deporte.id"
                        style="cursor:pointer"
                        class="nav-item nav-pre">
                        <span class="m-2">{{ deporte['name_'+language] }}</span>
                    </li>
                </ul>
            </li>
        </ul> 
    </div>
    <div>
        <div v-if="selected_sport==1">
            <div class="px-3">
                <Futbol
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Futbol>
            </div>
        </div>
        <div v-if="selected_sport==3 || selected_sport==9 || selected_sport==14 || selected_sport==15">
            <div class="px-3">
                <Cricket
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Cricket>
            </div>
        </div>
        <div v-if="selected_sport==8">
            <div class="px-3">
                <Rugby
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Rugby>
            </div>
        </div>
        <div v-if="selected_sport==12 || selected_sport==16
        || selected_sport==17 || selected_sport==18
        || selected_sport==91">
            <div class="px-3">
                <Cricket
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Cricket>
            </div>
        </div>
        <div v-if="selected_sport==13">
            <div class="px-3">
                <Tenis
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Tenis>
            </div>
        </div>
        <div v-if="selected_sport==78 || selected_sport==110 || selected_sport==83">
            <div class="px-3">
                <Balonmano
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Balonmano>
            </div>
        </div>
        <div v-if="selected_sport==92 || selected_sport==94 || selected_sport==151">
            <div class="px-3">
                <Cricket
                    :Skin="skin"
                    :translation="translation"
                    :language="language"
                ></Cricket>
            </div>
        </div>
        <encuentro v-for="(liga,index) in ligas_array"
          v-bind:liga="liga"
          v-bind:Skin="skin"
          v-bind:key="index"
          v-bind:sport="selected_sport"
          v-bind:time="time"
          v-bind:language="language"
          v-bind:translation="translation"
          v-bind:search="'NA'"
        />
        <div class="text-center p-3">
            <span @click="show_all" :style="skin.borde_round5" style="cursor:pointer" class="p-2">{{translate("see_all_events")}} </span>
        </div>
    </div>
</div>
</template>

<script>
import encuentro from '../main_events_prematch/Encuentro.vue';
import Futbol from '../sport_tables/futbol.vue';
import Cricket from '../sport_tables/cricket.vue';
import Rugby from '../sport_tables/rugby.vue';
import Tenis from '../sport_tables/tenis.vue';
import Balonmano from '../sport_tables/balonmano.vue';
export default {
    name:"main_prematch",
    props:['time','skin','translation','language','menu_visible'],
    components:{
        encuentro,
        Futbol,
        Cricket,
        Rugby,
        Tenis,
        Balonmano
    },
    data(){
        return{
            selected_sport:1,
        }
    },
    computed:{
        deportes(){
            var main = [1,18,16,13,17,12];
            var list={
                main:[],
                other:[]
            };
            let data = this.$store.getters['prematch/getPrematchData'];
            Object.keys(data).forEach(element => {
                var check = false;
                data[element].countries.forEach(country => {
                    if (country.leagues.length > 0) {
                        check = true; 
                    }
                });
                if(check){
                    if(main.includes(data[element].id)){
                        list.main.push(data[element]);
                    }
                    else{
                        list.other.push(data[element]);
                    }
                }
            });
            return list;
        },
        ligas_array(){
            let data = this.$store.getters['prematch/getLigasArray'];
            return data;
        },
    },
    methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        select_sport(id){
            let items = document.getElementsByClassName("nav-pre");
            [...items].forEach(item=>{
                item.classList.remove('nav_active');
            });
            if(document.getElementById(id)){
                document.getElementById(id).classList.add('nav_active');
            }
            this.selected_sport = id;
            this.$store.dispatch('prematch/load_prematch_events',id);
        },
        show_all(){
            this.$root.$emit('show_prematch_ligas');
        }
    },
    watch:{
        time:function(){
            this.$store.dispatch('prematch/load_prematch_events',this.selected_sport);
        },
    }
}
</script>

<style scoped>
  .nav_active{
      color:#ffd029;
      border-bottom: solid 3px #ffd029;
  }
</style>