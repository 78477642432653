<template>
  <div>
    <div class="p-2" :style="skin.my_account.menu_item_border">
      <h5>{{translate("Recharge")}}</h5>
    </div>
    <div v-if="response.result=='Ok'" class="alert alert-success  fade show" role="alert">
      {{ response.msg }}
    </div>
    <div v-if="response.result=='error'" class="alert alert-danger  fade show" role="alert">
      {{ response.msg }}
    </div>
    <div class="row p-2">
        <form @submit.prevent="confirmRecharge()" class="col-12 col-lg-6 col-sm col-md">
          <div class="py-2">
            <label for="amount">{{translate("deposit_amount")}}</label>
            <input v-model="amount" type="number" step="any" class="form-control" placeholder="0" id="amount" required>
          </div>
          <div class="py-2">
            <label for="userId">{{translate("user_or_id")}}</label>
            <input v-model="userId" type="text" class="form-control" :placeholder="translate('email_id')" id="userId" required>
          </div>
          <div class="py-2">
            <label for="notes">{{translate("notes")}}</label>
            <input v-model="notes" type="text" class="form-control" id="notes" :placeholder="translate('notes')">
          </div>
          <div class="py-4">
            <button type="submit" :style="skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="recharge_inProcess">{{translate("confirm")}}</button>
          </div>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:"recharge",
    props:["skin","language","translation"],
    data(){
      return{
        recharge_inProcess:false,
        amount:0,
        userId:"",
        notes:"",
        response:{result:"",msg:""}
      }
    },
    methods:{
      async confirmRecharge(){
        this.recharge_inProcess = true
        let data = {
          "command":"recargar",
          "params":{
            "valor":this.amount,
            "usuario":this.userId,
            "notas":this.notes
          }
        }
        const response = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        if(response.data.result == 'Ok'){
          this.response.result="Ok";
          this.response.msg=response.data.msg;
          this.amount="";
          this.userId="";
          this.notes="";
          if(this.$props.skin.my_account.print_recharge_ticket){
            window.open(this.user.domain + response.data.print,"mywindow","menubar=1,resizable=1,width=800,height=800");
          }
        }
        else if(response.data.result == 'error'){
          this.response.result="error";
          this.response.msg=response.data.msg;
        }
        this.recharge_inProcess = false;
      },
      translate(text) {
        if(text){
          if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
          }
          else{
            var new_text_serched = text.replace(/ /g, "_");
            if (this.$props.translation[new_text_serched]) {
              return this.$props.translation[new_text_serched][this.$props.language];
            }
          }
          return text;
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters['getUserData'];
      },
    }
}
</script>

<style>

</style>