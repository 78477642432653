<template>
  <!-- Botón para abrir el modal -->
  <div>
    <div class="modal-head">
      <button @click="closeModal">&times;</button>
    </div>
    <div class="modal-contenido">
      <div class="modal-cuerpo">
      <!-- game data
      <textarea name="" id="">{{ dataModalstate }}</textarea>
      events stats
      <textarea name="" id="">{{ dataStatsEvents }}</textarea> -->
      <iframe :src="currentIframeStats" style="height: 68vh;width: 100%;" scroll="no" frameborder="0"></iframe>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameStats',
  data() {
    return {
      currentIframeStats: "",
    };
  },
  methods: {
    closeModal() {
      this.currentIframeStats = "";
      this.$store.dispatch("dataStatsModalAct", null);
    },
    updateIframeUrl() {
      if (this.dataModalstate && this.dataModalstate.id && this.dataStatsEvents) {
        const foundEvent = this.dataStatsEvents.find(
          event => event.betcons_game_id == this.dataModalstate.id
        );

        if (foundEvent && foundEvent.iframe_url) {
          this.currentIframeStats = foundEvent.iframe_url;
        } else {
          this.currentIframeStats = ""; // O alguna URL por defecto
        }
      }
    },
  },
  computed: {
    dataModalstate() {
      return this.$store.getters['getDataStatsModal'];
    },
    dataStatsEvents() {
      return this.$store.getters['getGamesStats'];
    },
  },
  watch: {
    dataModalstate: {
      handler: 'updateIframeUrl',
      immediate: true,
      deep: true,
    },
    dataStatsEvents: {
      handler: 'updateIframeUrl',
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-head {
  background-color: black;
  display: flex;
  justify-content: flex-end;
}

.modal-head button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-contenido {
  background-color: black;
  width: 90vw;
  height: 70vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-foot {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.modal-foot button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 700px) {
  .modal-contenido {
    width: 90vw;
  }
}
</style>