<template>
  <div class="px-2 mb-1 mt-1" :style="Skin.fondo3+'cursor:pointer'" v-on:click="check_liga()">
  <span v-if="check" :id="liga" :class="Skin.text1" ><i class="fas fa-check"></i>&nbsp;</span>
  <span :class="Skin.text1" :style="Skin.color1">{{ liga.name  }}</span>
  </div>
</template>
<script>
export default{
  name:"eventoLiga",
  data(){
    return {
      titulo:"MENU",
      check:false,
    }
  },
  props:['liga','pais','deporte','Skin'],
  methods:{
    check_liga(){
      let ligas_array = this.$store.getters['prematch/getLigasArray2'];
      this.$store.dispatch('setLobbyView','prematch_ligas');
      this.$root.$emit('show_prematch_ligas',1);
      if(this.check){
        this.check=false;
        let payload = {"type":2,"datos":{liga:this.$props.liga}}
        this.$store.dispatch('prematch/delete_liga', payload);
      }
      else{
        this.check=true;
        let datos = {
          pais:{name_es:this.$props.pais.name_es,name_en:this.$props.pais.name_en},
          liga:this.$props.liga,
          deporte:{name_es:this.$props.deporte.name_es,name_en:this.$props.deporte.name_en,id:this.$props.deporte.id},
          pais_code:this.$props.pais.code
        };
        let payload = {"type":2,"datos":datos}
        this.$store.dispatch('prematch/add_liga', payload);
      }
      this.$root.$emit('scroll_to_events'); 
    },
  },
  mounted(){
    this.$root.$on('uncheck_liga',(liga) => {
      if(this.$props.liga.id == liga){
        this.check=false;
      }
    });
  }
}
</script>
