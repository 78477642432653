<template>
<div>
    <div :style="Skin.sport_table.fondo" class="row text-center">
        <div class="col col-lg-4 col-sm-5 py-1" style="width:33%">
        </div>
        <div class="col col-lg-4 col-sm-6 py-1" style="width:33%">
            <span :style="Skin.sport_table.text">{{translate("handicap_points")}}</span>
        </div>
        <div class="col col-3 py-1 d-none d-lg-block" style="width:33%">
            <span :style="Skin.sport_table.text">{{translate("total_points")}}</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'teaser',
  props: ["Skin","translation","language"],
  data(){
    return {
        
    }
  },
  methods:{
    translate(text) {
        if (this.$props.translation[text]) {
            return this.$props.translation[text][this.$props.language];
        }
        return text;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
