<template> 
<div class="m-1">
    <div class="mb-1">
        <navigation-bar
            v-bind:skin="Skin"
            v-bind:translation="translation"
            v-bind:language="language"
            v-bind:navigation="gameData.sport_alias ? [
            { name: gameData.sport_alias.toUpperCase(),link:''},
            { name:translate('competitions'),link:'' },
            { name: gameData.country.toUpperCase(),link:''},
            {name: gameData.liga.toUpperCase(),link:''},] : []"
        ></navigation-bar>
    </div>
    <div v-if="gameData" :style="Skin.prematch_event.fondo_data">
        <div :style="Skin.prematch_event.fondo_teams">
            <div :style="Skin.prematch_event.fondo_not_available" class="text-center">
                <span class="rounded-circle me-1" :class="'phoca-flag '+ country_flag(gameData.country)" style="max-width:20px;height:20px"></span>
                <span style="font-size:medium">{{ gameData.liga }}</span><br>
                <span>{{ format_date(gameData.start_ts) }}</span>
            </div>
            <div class="text-center">
                <span style="font-size:small"></span>
            </div>
            <div class="d-flex p-2" v-if="gameData.team2_name">
                <div class="d-flex" style="width:50%">
                    <shirt v-bind:color="'#'+(gameData.info.shirt1_color == '000000' ? 'ccc' : gameData.info.shirt1_color)"/>
                    <span class="mx-1">{{ gameData.team1_name }}</span>
                </div>
                <div style="width:50%">
                    <span class="float-end"><shirt2 v-bind:color="'#'+(gameData.info.shirt2_color == '000000' ? 'f00' : gameData.info.shirt2_color)"/></span>
                    <span class="float-end me-1">{{ gameData.team2_name }}</span>
                </div>
            </div>
        </div>
        <div>
            <allOdds
                v-if="Object.keys(market).length>0"
                v-bind:Skin="Skin"
                v-bind:language="language"
                v-bind:translation="translation"
                v-bind:game="market"
                v-bind:league="gameData.liga"
            >
            </allOdds>
            <div v-else :style="screen_width<450?'':'width:100%; height:90vh'" class="text-center py-5">
                <div class="spinner-border" :style="Skin.loader" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else :style="Skin.prematch_event.fondo_not_available" style="height:93vh" class="text-center py-5">
        <p>El evento ha finalizado o no se encuentra disponible en este momento.</p>
    </div>
</div>

</template>

<script>
const flags = require('../../../assets/countries.json');
import navigationBar from './navigation/navigation_bar.vue';
import allOdds from '../main_lobby/main_events_prematch2/all_odds.vue';
import shirt from './shirt.vue';
import shirt2 from './shirt2.vue';
export default {
    name: 'prematch2_encuentro',
    components:{
        allOdds,
        navigationBar,
        shirt,
        shirt2,
    },
    props: ['Skin','translation','language','gameData'],
    data(){
            return {
                screen_width:0,
            }
        },
    methods:{
        sizeControl(){
            this.screen_width=window.innerWidth;
        },
        country_flag(country_name) {
          let flag = ""
          flags.countries.forEach(country => {
              if(country.name_es == country_name || country.name_en == country_name){
                flag=country.code.toLowerCase();
              }
          });
          return flag;
        },
        format_date: function (ts) {
            let date = new Date(ts * 1000);
            var formated =
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + date.getDate()).slice(-2) +
                " " +
                date.getHours() +
                ":" +
                ("0" + date.getMinutes()).slice(-2) +
                ":" +
                ("0" + date.getSeconds()).slice(-2);
            return formated;
        },
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
    }, 
    computed:{
        market(){
            if(!("mode" in this.$props.gameData)){
                let data = this.$store.getters['prematch_2/getSuscribeEventsMarket'];
                if(data[this.$props.gameData.id] != null){
                    let game = data[this.$props.gameData.id].data.data.sport[this.$props.gameData.sport].region[this.$props.gameData.country_id].competition[this.$props.gameData.liga_id].game[this.$props.gameData.id];
                    return game;
                }
            }
            else{
                return this.$props.gameData;
            }
            return {};
        }
    },
    created() {
        this.sizeControl();
    },
    mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.long_text {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
